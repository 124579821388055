import React from "react";
import ReactDOM from "react-dom";
//import {    Redirect, Route } from 'react-router';
import { Switch, BrowserRouter, Route, Link } from "react-router-dom";

import Login from "./Login";
import Register from "./Register";
import Dashboard from "./components/Dashboard";
import "./Login.css";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Login} />
      <Route exact path="/register" component={Register} />
      <Route path="/dashboard" component={Dashboard} />
      {/* <Route component={NotFound}/> */}
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
