import React from "react";
import { Line } from "react-chartjs-2";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const HistoryDataLog = ({ historyData }) => {
  // Prepara los datos para Chart.js
  const data = {
    labels: historyData.map((entry) => entry.date), // Corregido a 'entry.date'
    datasets: [
      {
        label: "Batería",
        data: historyData.map((entry) => entry.battery),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderWidth: 2,
        tension: 0.3,
        pointRadius: 3,
      },
      {
        label: "Suministro de Energía",
        data: historyData.map((entry) => entry.power_supply), // Asumiendo que es un valor numérico
        borderColor: "rgb(54, 162, 235)",
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderWidth: 2,
        tension: 0.3,
        pointRadius: 3,
      },
      {
        label: "Solar",
        data: historyData.map((entry) => entry.solar), // Asumiendo que es un valor numérico
        borderColor: "rgb(75, 192, 192)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderWidth: 2,
        tension: 0.3,
        pointRadius: 3,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        mode: "index",
        intersect: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Fecha y Hora",
        },
        ticks: {
          maxRotation: 45,
          minRotation: 45,
        },
      },
      y: {
        title: {
          display: true,
          text: "Valor",
        },
        beginAtZero: true,
      },
    },
    elements: {
      line: {
        tension: 0.3,
        borderWidth: 2,
        fill: false,
      },
      point: {
        radius: 3,
        hitRadius: 10,
        hoverRadius: 5,
      },
    },
  };

  return <Line data={data} options={options} />;
};

export default HistoryDataLog;
