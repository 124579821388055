import React, { Component } from "react";
import swal from "sweetalert";
import {
  Button,
  TextField,
  Link,
  Paper,
  Container,
  Typography,
  Box,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { Person, Lock, Visibility, VisibilityOff } from "@material-ui/icons";
import "./Login.css";
import Particles from "react-particles-js";

const axios = require("axios");
const bcrypt = require("bcryptjs");
var salt = bcrypt.genSaltSync(10);

export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      showPassword: false,
      isLoading: false,
    };
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  togglePasswordVisibility = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  login = () => {
    this.setState({ isLoading: true });

    const pwd = bcrypt.hashSync(this.state.password, salt);

    axios
      .post("https://api.jababox.com/login", {
        username: this.state.username,
        password: pwd,
      })
      .then((res) => {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("user_id", res.data.id);
        this.props.history.push("/dashboard");
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        if (
          err.response &&
          err.response.data &&
          err.response.data.errorMessage
        ) {
          swal({
            text: err.response.data.errorMessage,
            icon: "error",
            type: "error",
          });
        }
      });
  };

  render() {
    const { username, password, showPassword, isLoading } = this.state;
    const isFormValid = username.trim() !== "" && password !== "";

    return (
      <div className="login-container">
        <div className="particles-container">
          <Particles
            params={{
              particles: {
                number: {
                  value: 80,
                  density: {
                    enable: true,
                    value_area: 800,
                  },
                },
                color: {
                  value: "#ffffff",
                },
                shape: {
                  type: "circle",
                  stroke: {
                    width: 0,
                    color: "#000000",
                  },
                },
                opacity: {
                  value: 0.5,
                  random: true,
                },
                size: {
                  value: 3,
                  random: true,
                },
                line_linked: {
                  enable: true,
                  distance: 150,
                  color: "#ffffff",
                  opacity: 0.4,
                  width: 1,
                },
                move: {
                  enable: true,
                  speed: 2,
                  direction: "none",
                  random: true,
                  straight: false,
                  out_mode: "out",
                  bounce: false,
                },
              },
              interactivity: {
                detect_on: "canvas",
                events: {
                  onhover: {
                    enable: true,
                    mode: "grab",
                  },
                  onclick: {
                    enable: true,
                    mode: "push",
                  },
                  resize: true,
                },
              },
            }}
          />
        </div>

        <Container maxWidth="sm" className="content-container">
          <Paper elevation={10} className="login-paper">
            {/* Logo de la empresa */}
            <div className="company-logo-container">
              <img
                src={require("./assets/images/logo.png")}
                alt="JABABOX Logo"
                className="company-logo"
              />
            </div>

            <Box className="logo-container" mb={3}>
              <svg
                width="120"
                height="120"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#ffffff"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="logo"
              >
                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                <circle cx="12" cy="7" r="4"></circle>
              </svg>
              <Typography variant="h4" className="title">
                Acceso al portal
              </Typography>
            </Box>

            <Box className="form-container">
              <TextField
                id="username"
                type="text"
                autoComplete="off"
                name="username"
                value={username}
                onChange={this.onChange}
                label="Nombre de usuario"
                variant="outlined"
                fullWidth
                margin="normal"
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Person color="primary" />
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                id="password"
                type={showPassword ? "text" : "password"}
                autoComplete="off"
                name="password"
                value={password}
                onChange={this.onChange}
                label="Contraseña"
                variant="outlined"
                fullWidth
                margin="normal"
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock color="primary" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.togglePasswordVisibility}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Box
                mt={3}
                mb={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Button
                  className="login-button"
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  disabled={!isFormValid || isLoading}
                  onClick={this.login}
                >
                  {isLoading ? "Cargando..." : "Acceso"}
                </Button>
              </Box>

              <Box textAlign="center" mt={2}>
                <Link href="/register" className="register-link">
                  ¿No tienes cuenta? Regístrate aquí
                </Link>
              </Box>
            </Box>
          </Paper>

          <Box className="footer" mt={3}>
            <Typography variant="body2">
              © 2023 JABADASH powered by JABABOX. Todos los derechos reservados.
            </Typography>
          </Box>
        </Container>
      </div>
    );
  }
}
