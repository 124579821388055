import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  TextField,
  Grid,
  Card,
  CardContent,
  CardActions,
  IconButton,
  makeStyles,
  Box,
  Tooltip,
  Zoom,
  Fade,
  CircularProgress,
  Chip,
  Divider,
  useTheme,
  Avatar,
} from "@material-ui/core";
import {
  TrafficSharp as TrafficIcon,
  Timer as TimerIcon,
  AssignmentTurnedIn as CheckIcon,
  PeopleAlt as CounterIcon,
  RecordVoiceOver as VoiceIcon,
  EditOutlined as EditIcon,
  OpenInNewOutlined as OpenIcon,
  Save as SaveIcon,
  Cancel as CancelIcon,
  Star as StarIcon,
  CheckCircle as CheckCircleIcon,
  Link as LinkIcon,
  ArrowForward as ArrowForwardIcon,
} from "@material-ui/icons";
import { getUserServices, updateUserService } from "../../services/api";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 700,
    maxWidth: "90vw",
    borderRadius: 16,
    overflow: "hidden",
    boxShadow: "0 12px 24px rgba(0, 0, 0, 0.15)",
  },
  titleContainer: {
    background: `linear-gradient(135deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`,
    color: "white",
    padding: theme.spacing(3, 4),
    position: "relative",
    overflow: "hidden",
  },
  title: {
    textAlign: "center",
    position: "relative",
    "& .MuiTypography-h5": {
      fontWeight: 700,
      letterSpacing: 1,
    },
  },
  titleSubtext: {
    marginTop: theme.spacing(0.5),
    opacity: 0.9,
    textAlign: "center",
    fontWeight: 400,
  },
  titleDecorator: {
    position: "absolute",
    color: "rgba(255, 255, 255, 0.25)",
    fontSize: 40,
  },
  starBackground: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    opacity: 0.07,
    pointerEvents: "none",
    zIndex: 0,
    backgroundImage: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M30 18.77l2.9 8.92h9.28l-7.5 5.45 2.85 8.8-7.53-5.47-7.53 5.47 2.85-8.8-7.5-5.45h9.28z' fill='%23ffffff' fill-opacity='0.4'/%3E%3C/svg%3E")`,
  },
  dialogContent: {
    padding: theme.spacing(4),
    position: "relative",
    backgroundColor: "#f9fafc",
  },
  cardGrid: {
    marginTop: theme.spacing(1),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    borderRadius: 12,
    transition: "all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)",
    overflow: "hidden",
    border: "1px solid rgba(0, 0, 0, 0.08)",
    backgroundColor: "white",
    "&:hover": {
      transform: "translateY(-8px)",
      boxShadow: "0 14px 28px rgba(0,0,0,0.1), 0 10px 10px rgba(0,0,0,0.05)",
    },
  },
  cardHeader: {
    padding: theme.spacing(2, 2, 1, 2),
  },
  cardTitle: {
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1.5),
    marginBottom: theme.spacing(0.5),
  },
  cardContent: {
    flexGrow: 1,
    padding: theme.spacing(1, 2, 2),
  },
  cardIcon: {
    borderRadius: "50%",
    padding: theme.spacing(1.5),
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0 4px 10px rgba(0,0,0,0.15)",
    width: 48,
    height: 48,
    transition: "transform 0.3s ease",
    "& svg": {
      fontSize: 26,
    },
    "&:hover": {
      transform: "scale(1.05) rotate(5deg)",
    },
  },
  iconTrack: {
    background: "linear-gradient(135deg, #3498db 0%, #2980b9 100%)",
  },
  iconTime: {
    background: "linear-gradient(135deg, #f39c12 0%, #e67e22 100%)",
  },
  iconCheck: {
    background: "linear-gradient(135deg, #2ecc71 0%, #27ae60 100%)",
  },
  iconCounter: {
    background: "linear-gradient(135deg, #9b59b6 0%, #8e44ad 100%)",
  },
  iconVoice: {
    background: "linear-gradient(135deg, #e74c3c 0%, #c0392b 100%)",
  },
  urlField: {
    marginTop: theme.spacing(2),
    width: "100%",
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(1),
  },
  editButtons: {
    display: "flex",
    gap: theme.spacing(1),
  },
  statusChip: {
    borderRadius: 12,
    fontWeight: 500,
    margin: theme.spacing(1, 0),
    boxShadow: "0 2px 4px rgba(0,0,0,0.06)",
  },
  statusEnabled: {
    backgroundColor: "#e6f7ee",
    color: "#27ae60",
    "& .MuiChip-icon": {
      color: "#27ae60",
    },
  },
  statusDisabled: {
    backgroundColor: "#f0f2f5",
    color: "#7f8c8d",
  },
  dialogActions: {
    padding: theme.spacing(2, 3),
    borderTop: "1px solid rgba(0, 0, 0, 0.08)",
    backgroundColor: "white",
  },
  urlContainer: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#f0f2f5",
    borderRadius: 8,
    padding: theme.spacing(1, 1.5),
    marginTop: theme.spacing(1),
    "& svg": {
      marginRight: theme.spacing(1),
      fontSize: 18,
      color: theme.palette.text.secondary,
    },
  },
  urlText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    flexGrow: 1,
    fontSize: "0.875rem",
  },
  descriptionContainer: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(0.5),
    fontSize: "0.875rem",
  },
  actionButton: {
    borderRadius: 20,
    padding: theme.spacing(0.75, 2),
    fontWeight: 500,
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
    transition: "all 0.2s ease",
    textTransform: "none",
    "&:hover": {
      boxShadow: "0 4px 8px rgba(0,0,0,0.15)",
      transform: "translateY(-2px)",
    },
  },
  saveButton: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  closeButton: {
    fontSize: "0.9rem",
    textTransform: "none",
    marginRight: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  loadingContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(6),
  },
  loadingText: {
    marginTop: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  errorContainer: {
    backgroundColor: "#fee",
    border: "1px solid #ffcdd2",
    borderRadius: 8,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  serviceActions: {
    padding: theme.spacing(0, 2, 2),
    display: "flex",
    justifyContent: "space-between",
  },
  actionIconButton: {
    backgroundColor: "#f0f2f5",
    color: theme.palette.text.secondary,
    padding: 8,
    transition: "all 0.2s ease",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
  },
  openButton: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  detailsRow: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(0.5),
  },
  detailsLabel: {
    fontWeight: 500,
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
    fontSize: "0.875rem",
  },
  "@keyframes float": {
    "0%": { transform: "translateY(0px) rotate(0deg)" },
    "50%": { transform: "translateY(-10px) rotate(5deg)" },
    "100%": { transform: "translateY(0px) rotate(0deg)" },
  },
  "@keyframes pulse": {
    "0%": { opacity: 0.6, transform: "scale(0.9)" },
    "50%": { opacity: 1, transform: "scale(1.1)" },
    "100%": { opacity: 0.6, transform: "scale(0.9)" },
  },
}));

const serviceIcons = {
  jabatrack: {
    icon: TrafficIcon,
    title: "JABATRACK",
    description: "Sistema de control de tráfico ANPR",
    className: "iconTrack",
  },
  timelapse: {
    icon: TimerIcon,
    title: "TIMELAPSE",
    description: "Sistema de timelapse para obras",
    className: "iconTime",
  },
  jabacheck: {
    icon: CheckIcon,
    title: "JABACHECK",
    description: "Servicio control de fichaje para obras",
    className: "iconCheck",
  },
  jabacounter: {
    icon: CounterIcon,
    title: "JABACOUNTER",
    description: "Portal de conteo y clasificación de personas y vehículos",
    className: "iconCounter",
  },
  jabavoice: {
    icon: VoiceIcon,
    title: "JABAVOICE",
    description: "Portal de gestión y control de megafonías",
    className: "iconVoice",
  },
};

const ServicesDialog = ({ open, onClose }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [services, setServices] = useState({});
  const [editingService, setEditingService] = useState(null);
  const [tempUrl, setTempUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch services when dialog opens
  useEffect(() => {
    if (open) {
      fetchServices();
    }
  }, [open]);

  const fetchServices = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await getUserServices();

      if (response.success) {
        setServices(response.data);
      } else {
        setError(response.message || "Error al cargar los servicios");
      }
    } catch (err) {
      setError("Error al conectar con el servidor");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleEditClick = (serviceKey) => {
    setEditingService(serviceKey);
    setTempUrl(services[serviceKey]?.url || "");
  };

  const handleCancel = () => {
    setEditingService(null);
  };

  const handleSave = async (serviceKey) => {
    try {
      const response = await updateUserService(serviceKey, {
        url: tempUrl,
        enabled: true,
      });

      if (response.success) {
        // Update local state with the new service data
        setServices({
          ...services,
          [serviceKey]: {
            ...services[serviceKey],
            url: tempUrl,
            enabled: true,
          },
        });
        setEditingService(null);
      } else {
        setError(response.message || "Error al guardar el servicio");
      }
    } catch (err) {
      setError("Error al conectar con el servidor");
      console.error(err);
    }
  };

  const handleOpenService = (url) => {
    if (url) {
      // Check if URL already has a protocol prefix
      const hasProtocol =
        url.startsWith("http://") || url.startsWith("https://");
      // Add https:// protocol if needed
      const formattedUrl = hasProtocol ? url : `https://${url}`;
      window.open(formattedUrl, "_blank");
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="services-dialog-title"
      classes={{ paper: classes.root }}
      TransitionComponent={Zoom}
      transitionDuration={350}
      fullWidth
      maxWidth="md"
    >
      <div className={classes.titleContainer}>
        <div className={classes.starBackground} />
        <DialogTitle
          id="services-dialog-title"
          className={classes.title}
          disableTypography
        >
          <Typography variant="h5">Servicios Premium JABABOX</Typography>
          <Typography variant="body2" className={classes.titleSubtext}>
            Accede a nuestros servicios especializados para obtener el máximo
            rendimiento
          </Typography>
        </DialogTitle>
      </div>

      <DialogContent className={classes.dialogContent}>
        {error && (
          <Fade in={!!error}>
            <Box className={classes.errorContainer}>
              <Typography color="error" variant="body2" gutterBottom>
                {error}
              </Typography>
            </Box>
          </Fade>
        )}

        {loading ? (
          <Box className={classes.loadingContainer}>
            <CircularProgress color="primary" />
            <Typography variant="body1" className={classes.loadingText}>
              Cargando servicios disponibles...
            </Typography>
          </Box>
        ) : (
          <Fade in={!loading} timeout={500}>
            <Grid container spacing={3} className={classes.cardGrid}>
              {Object.keys(serviceIcons).map((serviceKey, index) => {
                const service = services[serviceKey] || {
                  enabled: false,
                  url: "",
                };
                const {
                  icon: Icon,
                  title,
                  description,
                  className,
                } = serviceIcons[serviceKey];
                const isEditing = editingService === serviceKey;

                return (
                  <Grid item xs={12} sm={6} key={serviceKey}>
                    <Zoom
                      in={true}
                      style={{ transitionDelay: `${index * 75}ms` }}
                    >
                      <Card className={classes.card}>
                        <Box className={classes.cardHeader}>
                          <Box className={classes.cardTitle}>
                            <Avatar
                              className={`${classes.cardIcon} ${classes[className]}`}
                            >
                              <Icon />
                            </Avatar>
                            <Typography variant="h6">{title}</Typography>
                          </Box>
                        </Box>

                        <CardContent className={classes.cardContent}>
                          <Box className={classes.descriptionContainer}>
                            {description}
                          </Box>

                          <Box mt={1.5}>
                            <Chip
                              icon={
                                service.enabled && service.url ? (
                                  <CheckCircleIcon />
                                ) : null
                              }
                              label={
                                service.enabled && service.url
                                  ? "Servicio Activado"
                                  : "No Configurado"
                              }
                              className={`${classes.statusChip} ${
                                service.enabled && service.url
                                  ? classes.statusEnabled
                                  : classes.statusDisabled
                              }`}
                              size="small"
                            />
                          </Box>

                          {isEditing ? (
                            <TextField
                              label="URL del servicio"
                              variant="outlined"
                              size="small"
                              value={tempUrl}
                              onChange={(e) => setTempUrl(e.target.value)}
                              placeholder="Introduce la URL del servicio"
                              className={classes.urlField}
                              InputProps={{
                                startAdornment: <LinkIcon color="action" />,
                              }}
                              autoFocus
                            />
                          ) : service.url ? (
                            <Box className={classes.urlContainer}>
                              <LinkIcon />
                              <Typography
                                className={classes.urlText}
                                title={service.url}
                              >
                                {service.url}
                              </Typography>
                            </Box>
                          ) : null}
                        </CardContent>

                        <CardActions className={classes.serviceActions}>
                          {isEditing ? (
                            <Box className={classes.editButtons}>
                              <Button
                                startIcon={<SaveIcon />}
                                className={`${classes.actionButton} ${classes.saveButton}`}
                                onClick={() => handleSave(serviceKey)}
                                variant="contained"
                                size="small"
                              >
                                Guardar
                              </Button>
                              <Button
                                startIcon={<CancelIcon />}
                                color="default"
                                size="small"
                                onClick={handleCancel}
                                className={classes.actionButton}
                              >
                                Cancelar
                              </Button>
                            </Box>
                          ) : (
                            <Box
                              display="flex"
                              width="100%"
                              justifyContent="space-between"
                            >
                              <Button
                                size="small"
                                color="primary"
                                startIcon={<EditIcon />}
                                onClick={() => handleEditClick(serviceKey)}
                                className={classes.actionButton}
                              >
                                {service.url ? "Editar" : "Configurar"}
                              </Button>

                              {service.url && (
                                <Button
                                  size="small"
                                  variant="contained"
                                  color="primary"
                                  endIcon={<ArrowForwardIcon />}
                                  onClick={() => handleOpenService(service.url)}
                                  className={`${classes.actionButton} ${classes.openButton}`}
                                >
                                  Abrir Servicio
                                </Button>
                              )}
                            </Box>
                          )}
                        </CardActions>
                      </Card>
                    </Zoom>
                  </Grid>
                );
              })}
            </Grid>
          </Fade>
        )}
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <Button
          onClick={onClose}
          color="primary"
          className={classes.closeButton}
        >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ServicesDialog;
