import React from "react";
import PropTypes from "prop-types";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-defaulticon-compatibility";
import { Box, Typography } from "@material-ui/core";
import { FiMapPin } from "react-icons/fi";

const customIcon = new L.Icon({
  iconUrl: "/icons/jabaonline3.png",
  iconSize: [32, 32],
  iconAnchor: [16, 32],
  popupAnchor: [0, -32],
});

const isValidCoordinate = (coord) => {
  if (coord === undefined || coord === null) return false;

  // Si es string, intentar convertir a número
  const numValue = typeof coord === "string" ? parseFloat(coord) : coord;

  // Verificar que sea un número válido
  return !isNaN(numValue) && isFinite(numValue);
};

const MapComponent = ({ lat, lng, name }) => {
  // Para depuración
  console.log("Coordenadas recibidas:", {
    lat,
    lng,
    latType: typeof lat,
    lngType: typeof lng,
  });

  // Normalizar las coordenadas
  const latitude = typeof lat === "string" ? parseFloat(lat) : lat;
  const longitude = typeof lng === "string" ? parseFloat(lng) : lng;

  // Verificar coordenadas
  const validLat = isValidCoordinate(latitude);
  const validLng = isValidCoordinate(longitude);

  if (!validLat || !validLng) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100%"
        width="100%"
        bgcolor="#f5f7fa"
        borderRadius="8px"
        padding="20px"
        textAlign="center"
      >
        <FiMapPin size={40} color="#9ca3af" style={{ marginBottom: "16px" }} />
        <Typography
          variant="body1"
          style={{ color: "#6b7280", marginBottom: "8px" }}
        >
          No se pudo cargar el mapa
        </Typography>
        <Typography variant="body2" style={{ color: "#9ca3af" }}>
          Las coordenadas no están disponibles o son inválidas
        </Typography>
        {lat && lng && (
          <Typography
            variant="caption"
            style={{ color: "#9ca3af", marginTop: "8px" }}
          >
            Coords: {lat}, {lng} (formato no válido)
          </Typography>
        )}
      </Box>
    );
  }

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <MapContainer
        style={{ height: "100%", width: "100%" }}
        center={[latitude, longitude]}
        zoom={16}
        scrollWheelZoom={false}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://cloud.jababox.com">OpenStreetMap</a> Jababox'
        />
        <Marker position={[latitude, longitude]} icon={customIcon}>
          <Popup>{name || "Ubicación del dispositivo"}</Popup>
        </Marker>
      </MapContainer>
    </div>
  );
};

MapComponent.propTypes = {
  lat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lng: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
};

export default MapComponent;
