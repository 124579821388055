import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import HistoryDataLog from "../../HistoryDataLog";

const GraphDialog = ({ open, historyData, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="graph-dialog-title"
      aria-describedby="graph-dialog-description"
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle id="graph-dialog-title">Gráfico Histórico</DialogTitle>
      <DialogContent>
        <HistoryDataLog historyData={historyData} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

GraphDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  historyData: PropTypes.array,
  onClose: PropTypes.func.isRequired,
};

GraphDialog.defaultProps = {
  historyData: [],
};

export default GraphDialog;
