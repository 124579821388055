import React from "react";
import PropTypes from "prop-types";
import QrReader from "react-qr-reader";
import { IconButton } from "@material-ui/core";
import { BsQrCodeScan } from "react-icons/bs";

const QRScannerComponent = ({ isOpen, onScan, onToggle }) => {
  const handleScan = (data) => {
    if (data) {
      onScan(data);
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  return (
    <div>
      <IconButton onClick={onToggle}>
        <BsQrCodeScan />
      </IconButton>

      {isOpen && (
        <QrReader
          onScan={handleScan}
          onError={handleError}
          style={{ width: "250px" }}
          facingMode={"environment"} // Usar la cámara trasera
          delay={500}
        />
      )}
    </div>
  );
};

QRScannerComponent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onScan: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default QRScannerComponent;
