import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  IconButton,
  Divider,
  Grid,
  Paper,
} from "@material-ui/core";
import { Close, HelpOutline, Info } from "@material-ui/icons";
import { FiMonitor, FiPower, FiRefreshCw, FiMapPin } from "react-icons/fi";

const HelpDialog = ({ open, onClose }) => {
  const features = [
    {
      title: "Monitorización en tiempo real",
      description:
        "Consulta el estado de tus dispositivos en tiempo real, incluyendo batería, fuente de alimentación y comunicación.",
      icon: (
        <FiMonitor
          style={{ fontSize: "24px", color: "#6a67ce", marginBottom: "8px" }}
        />
      ),
    },
    {
      title: "Control remoto",
      description:
        "Controla tus dispositivos de forma remota, enciende/apaga relés y configura parámetros.",
      icon: (
        <FiPower
          style={{ fontSize: "24px", color: "#6a67ce", marginBottom: "8px" }}
        />
      ),
    },
    {
      title: "Actualización continua",
      description:
        "Los datos se refrescan automáticamente para mantener la información actualizada en todo momento.",
      icon: (
        <FiRefreshCw
          style={{ fontSize: "24px", color: "#6a67ce", marginBottom: "8px" }}
        />
      ),
    },
    {
      title: "Geolocalización",
      description:
        "Visualiza la ubicación exacta de tus dispositivos y cómo llegar a ellos.",
      icon: (
        <FiMapPin
          style={{ fontSize: "24px", color: "#6a67ce", marginBottom: "8px" }}
        />
      ),
    },
  ];

  const steps = [
    {
      number: 1,
      title: "Añadir un nuevo sistema",
      description:
        'Haz clic en el botón "AÑADIR SISTEMA" en la barra superior y completa la información requerida.',
    },
    {
      number: 2,
      title: "Configurar conexión",
      description:
        "Asegúrate de que el sistema esté encendido y conectado a la red para que pueda comunicarse con la plataforma.",
    },
    {
      number: 3,
      title: "Monitorizar el sistema",
      description:
        "Una vez conectado, podrás ver el estado del sistema en la tabla principal y acceder a detalles haciendo clic en él.",
    },
    {
      number: 4,
      title: "Control remoto",
      description:
        "Dentro de los detalles del sistema, podrás controlar relés, consultar gráficas y ajustar configuraciones.",
    },
  ];

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box display="flex" alignItems="center">
          <HelpOutline style={{ marginRight: "8px", color: "#6a67ce" }} />
          <Typography variant="h6">Ayuda y guía de uso</Typography>
        </Box>
        <IconButton onClick={onClose} size="small">
          <Close />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          Esta plataforma te permite monitorizar y controlar todos tus sistemas
          JABABOX desde un solo lugar. Aquí podrás ver el estado de tus
          dispositivos, su ubicación, controlar relés y mucho más.
        </Typography>

        {/* Características principales */}
        <Typography
          variant="h6"
          style={{ marginTop: "16px", marginBottom: "12px", color: "#333" }}
        >
          Características principales
        </Typography>
        <Grid container spacing={2}>
          {features.map((feature, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <Paper
                style={{
                  padding: "16px",
                  height: "100%",
                  backgroundColor: "#f8f9fa",
                  border: "1px solid #e0e0e0",
                  borderRadius: "8px",
                }}
                elevation={0}
              >
                {feature.icon}
                <Typography
                  variant="subtitle1"
                  style={{ fontWeight: 500, marginBottom: "4px" }}
                >
                  {feature.title}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {feature.description}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>

        {/* Pasos para usar */}
        <Typography
          variant="h6"
          style={{ marginTop: "24px", marginBottom: "12px", color: "#333" }}
        >
          Cómo usar la plataforma
        </Typography>
        <Box>
          {steps.map((step, index) => (
            <Box
              key={index}
              display="flex"
              style={{
                marginBottom: "12px",
                backgroundColor: index % 2 === 0 ? "#f0f2ff" : "#fff",
                padding: "12px",
                borderRadius: "8px",
                border: "1px solid #e0e7ff",
              }}
            >
              <Box
                style={{
                  width: "32px",
                  height: "32px",
                  borderRadius: "50%",
                  backgroundColor: "#6a67ce",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: "16px",
                  flexShrink: 0,
                }}
              >
                {step.number}
              </Box>
              <Box>
                <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
                  {step.title}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {step.description}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>

        <Box
          style={{
            display: "flex",
            padding: "16px",
            backgroundColor: "#fff9e6",
            borderRadius: "8px",
            border: "1px solid #ffe7a0",
            marginTop: "16px",
            alignItems: "flex-start",
          }}
        >
          <Info
            style={{ color: "#ffc107", marginRight: "16px", marginTop: "2px" }}
          />
          <Typography variant="body2">
            <strong>Necesitas ayuda adicional?</strong> Para más información
            sobre el funcionamiento de la plataforma o problemas técnicos,
            contacta con nuestro equipo de soporte en{" "}
            <a href="mailto:soporte@jababox.com">soporte@jababox.com</a> o llama
            al +34 900 123 456 en horario de 9:00 a 18:00.
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default HelpDialog;
