import React from "react";
import PropTypes from "prop-types";
import { Typography, Box } from "@material-ui/core";
import {
  FaSun,
  FaCloudSun,
  FaCloud,
  FaCloudMoon,
  FaMoon,
} from "react-icons/fa";
import { colors, typography } from "../../globalStyles";

const SolarStatus = ({ value, size = 50, showText = false }) => {
  // Convertir el valor a un número
  const numericValue =
    typeof value === "string"
      ? value === "true"
        ? 100
        : value === "false"
        ? 0
        : parseInt(value, 10) || 0
      : value || 0;

  // Asegurarnos de que el valor está entre 0 y 100
  const safeValue = Math.max(0, Math.min(100, numericValue));

  // Determinar el color basado en el nivel solar
  const getColor = () => {
    if (safeValue >= 75) return "#f59e0b"; // Amarillo brillante
    if (safeValue >= 50) return "#fbbf24"; // Amarillo
    if (safeValue >= 25) return "#d97706"; // Naranja
    if (safeValue >= 10) return "#9ca3af"; // Gris
    return "#6b7280"; // Gris oscuro
  };

  // Determinar el icono a mostrar según el nivel
  const getSolarIcon = () => {
    if (safeValue >= 75) {
      return <FaSun size={size} color={getColor()} />;
    } else if (safeValue >= 50) {
      return <FaCloudSun size={size} color={getColor()} />;
    } else if (safeValue >= 25) {
      return <FaCloud size={size} color={getColor()} />;
    } else if (safeValue >= 10) {
      return <FaCloudMoon size={size} color={getColor()} />;
    } else {
      return <FaMoon size={size} color={getColor()} />;
    }
  };

  // Determinar el texto a mostrar
  const getStatusText = () => {
    if (safeValue >= 75) return "Sol directo";
    if (safeValue >= 50) return "Sol parcial";
    if (safeValue >= 25) return "Nublado";
    if (safeValue >= 10) return "Poca luz";
    return "Sin sol";
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      {getSolarIcon()}

      {showText && (
        <Typography
          variant="body2"
          style={{
            marginTop: "5px",
            color: getColor(),
            fontWeight: typography.fontWeights.medium,
          }}
        >
          {safeValue}%
        </Typography>
      )}
    </Box>
  );
};

SolarStatus.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.number,
  showText: PropTypes.bool,
};

export default SolarStatus;
