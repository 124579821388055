import React from "react";
import PropTypes from "prop-types";
import {
  TableRow,
  TableCell,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip as MuiTooltip,
  CircularProgress,
  Typography,
  Box,
  Grid,
  Divider,
  TextField,
  Paper,
  Chip,
} from "@material-ui/core";
import {
  FcFullBattery,
  FcHighBattery,
  FcMiddleBattery,
  FcLowBattery,
  FcEmptyBattery,
} from "react-icons/fc";
import {
  BsLightningFill,
  BsSunFill,
  BsFillCloudMoonFill,
  BsLightbulbOff,
  BsRouter,
} from "react-icons/bs";
import { FcIdea } from "react-icons/fc";
import { CiTempHigh } from "react-icons/ci";
import { GrCloudComputer } from "react-icons/gr";
import { MdRouter, MdOutlineDevicesOther } from "react-icons/md";
import Cached from "@material-ui/icons/Cached";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import PowerSettingsNewOutlined from "@material-ui/icons/PowerSettingsNewOutlined";
import SettingsPowerOutlined from "@material-ui/icons/SettingsPowerOutlined";
import { getRandomValue } from "../../utils/helpers";
import Swal from "sweetalert2";
import {
  FiWifi,
  FiWifiOff,
  FiGlobe,
  FiSmartphone,
  FiRefreshCw,
  FiInfo,
} from "react-icons/fi";
import { FaServer, FaEthernet, FaNetworkWired } from "react-icons/fa";
import * as api from "../../services/api";

// Importamos el componente StatusIndicator
import StatusIndicatorTrue from "../../StatusIndicatorTrue";
import StatusIndicatorFalse from "../../StatusIndicatorFalse";

// Añado estilos para los botones de acciones
const actionButtonStyles = {
  base: {
    borderRadius: "6px",
    padding: "4px 10px",
    margin: "3px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "12px",
    fontWeight: "500",
    cursor: "pointer",
    transition: "all 0.2s",
    boxShadow: "0 1px 3px rgba(0,0,0,0.05)",
    border: "none",
    minWidth: "36px",
    textTransform: "none",
  },
  reiniciar: {
    color: "#FF9800",
    backgroundColor: "rgba(255, 152, 0, 0.1)",
    border: "1px solid rgba(255, 152, 0, 0.2)",
  },
  encender: {
    color: "#4CAF50",
    backgroundColor: "rgba(76, 175, 80, 0.1)",
    border: "1px solid rgba(76, 175, 80, 0.2)",
  },
  apagar: {
    color: "#F44336",
    backgroundColor: "rgba(244, 67, 54, 0.1)",
    border: "1px solid rgba(244, 67, 54, 0.2)",
  },
  editar: {
    color: "#2196F3",
    backgroundColor: "rgba(33, 150, 243, 0.1)",
    border: "1px solid rgba(33, 150, 243, 0.2)",
  },
  borrar: {
    color: "#F44336",
    backgroundColor: "rgba(244, 67, 54, 0.1)",
    border: "1px solid rgba(244, 67, 54, 0.2)",
  },
  hoverEffect: {
    transform: "translateY(-2px)",
    boxShadow: "0 3px 6px rgba(0,0,0,0.1)",
  },
  tooltip: {
    position: "absolute",
    top: "100%",
    left: "50%",
    transform: "translateX(-50%)",
    backgroundColor: "rgba(97, 97, 97, 0.9)",
    color: "#fff",
    padding: "4px 8px",
    borderRadius: "4px",
    fontSize: "11px",
    fontWeight: "400",
    whiteSpace: "nowrap",
    zIndex: 1000,
    boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
    marginTop: "5px",
    pointerEvents: "none",
    opacity: 0,
    transition: "opacity 0.2s",
  },
  tooltipVisible: {
    opacity: 1,
  },
};

const ActionButtonInfo = ({ icon: Icon, label, color, last_update }) => (
  <div
    style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
  >
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: "2px",
        color,
      }}
    >
      <Icon style={{ marginRight: "4px" }} size={14} />
      <span style={{ fontSize: "12px", fontWeight: "500" }}>{label}</span>
    </div>
    <div style={{ fontSize: "11px", color: "#757575" }}>
      {last_update || "No disponible"}
    </div>
  </div>
);

const ProductTableRow = ({
  product,
  onCellClick,
  onConsoleClick,
  onGrafClick,
  onReboot,
  onPowerOn,
  onPowerOff,
  onEdit,
  onDelete,
}) => {
  // Añadimos un log detallado al inicio del componente
  console.log("Producto detallado:", {
    name: product.name,
    battery: product.battery,
    status: product.status,
    statusType: product.status ? typeof product.status : "undefined",
    statusString: product.status ? typeof product.status.status : "undefined",
    statusValue: product.status ? product.status.status : "undefined",
    completo: JSON.stringify(product),
  });

  // Función para determinar si el dispositivo está offline
  const isOffline = () => {
    console.log(`Verificando ${product.name}:`, {
      status: product.status,
      statusType: typeof product.status,
      statusContent: JSON.stringify(product.status),
      batteryStatus: product.battery,
    });

    // Si es un objeto con propiedad estado
    if (
      product.status &&
      typeof product.status === "object" &&
      "estado" in product.status
    ) {
      const estadoValue = product.status.estado;
      if (
        estadoValue === "true" ||
        estadoValue === true ||
        estadoValue === "1" ||
        estadoValue === 1
      ) {
        return false; // El dispositivo está online
      }
      if (
        estadoValue === "false" ||
        estadoValue === false ||
        estadoValue === "0" ||
        estadoValue === 0
      ) {
        return true; // El dispositivo está offline
      }
    }

    // Si es un objeto con propiedad status
    if (
      product.status &&
      typeof product.status === "object" &&
      "status" in product.status
    ) {
      const statusValue = product.status.status;
      if (
        statusValue === "true" ||
        statusValue === true ||
        statusValue === "1" ||
        statusValue === 1
      ) {
        return false; // El dispositivo está online
      }
      if (
        statusValue === "false" ||
        statusValue === false ||
        statusValue === "0" ||
        statusValue === 0
      ) {
        return true; // El dispositivo está offline
      }
    }

    // Si es un valor directo
    if (
      product.status === "true" ||
      product.status === true ||
      product.status === "1" ||
      product.status === 1
    ) {
      return false; // El dispositivo está online
    }
    if (
      product.status === "false" ||
      product.status === false ||
      product.status === "0" ||
      product.status === 0
    ) {
      return true; // El dispositivo está offline
    }

    // Si es un string que tiene "online"
    if (typeof product.status === "string") {
      const lowerStatus = product.status.toLowerCase();
      if (lowerStatus.includes("online") || lowerStatus.includes("en línea")) {
        return false; // El dispositivo está online
      }
    }

    // Si es un string que tiene "Desconocido" o "desconocido"
    if (
      typeof product.status === "string" &&
      (product.status.includes("Desconocido") ||
        product.status.toLowerCase().includes("desconocido"))
    ) {
      return true;
    }

    // Si la batería indica Offline
    if (product.battery === "Offline%" || product.battery === "false") {
      return true;
    }

    // Si no podemos determinar, asumimos offline por seguridad
    return true;
  };

  // Estado hover para los botones
  const [hoverStates, setHoverStates] = React.useState({
    reiniciar: false,
    encender: false,
    apagar: false,
    editar: false,
    borrar: false,
    mas: false,
  });

  // Estado para mostrar más acciones
  const [showMoreActions, setShowMoreActions] = React.useState(false);

  // Estado para el diálogo de confirmación de reinicio
  const [openRebootDialog, setOpenRebootDialog] = React.useState(false);

  // Estado para el diálogo de router
  const [openRouterDialog, setOpenRouterDialog] = React.useState(false);
  const [routerData, setRouterData] = React.useState(null);
  const [isLoadingRouter, setIsLoadingRouter] = React.useState(false);
  const [routerError, setRouterError] = React.useState(null);

  // Función para manejar hover
  const handleHover = (button, isHover) => {
    setHoverStates((prev) => ({
      ...prev,
      [button]: isHover,
    }));
  };

  // Funciones para controlar el diálogo de confirmación
  const handleOpenRebootDialog = () => {
    setOpenRebootDialog(true);
  };

  const handleCloseRebootDialog = () => {
    setOpenRebootDialog(false);
  };

  const handleConfirmReboot = () => {
    onReboot(product.sn);
    setOpenRebootDialog(false);
  };

  // Funciones para gestionar el diálogo de router
  const handleOpenRouterDialog = () => {
    setOpenRouterDialog(true);
    if (product && product.router && product.router.ip) {
      handleGetRouterData();
    }
  };

  const handleCloseRouterDialog = () => {
    setOpenRouterDialog(false);
  };

  // Función para obtener datos del router
  const handleGetRouterData = async () => {
    setIsLoadingRouter(true);
    setRouterError(null);

    try {
      const routerConfig = {
        host: product.router.ip,
        username: product.router.username || "admin",
        password: product.router.password || "admin1604",
      };

      console.log("Conectando al router con:", routerConfig);
      const response = await api.getRouterCudyData(routerConfig);

      if (response && response.success) {
        setRouterData(response.data || response);
        console.log("Datos del router guardados:", response.data || response);
      } else {
        console.error("Respuesta sin éxito:", response);
        setRouterError(
          (response && response.errorMessage) ||
            "Error al obtener datos del router"
        );
      }
    } catch (error) {
      console.error("Error al conectar con el router:", error);
      setRouterError(
        "Error de conexión con el router: " +
          (error.message || "Error desconocido")
      );
    } finally {
      setIsLoadingRouter(false);
    }
  };

  // Formatear la hora de una manera más legible
  const formatTime = (timeString) => {
    if (!timeString) return null;

    // Si ya tiene el formato deseado, lo devolvemos tal cual
    if (timeString.includes(":")) return timeString;

    try {
      const date = new Date(timeString);
      return date.toLocaleTimeString("es-ES", {
        hour: "2-digit",
        minute: "2-digit",
        day: "2-digit",
        month: "2-digit",
      });
    } catch (e) {
      return timeString;
    }
  };

  return (
    <>
      <TableRow
        style={{ transition: "background-color 0.3s", height: "auto" }}
        hover
      >
        <TableCell
          onClick={() => onCellClick(product)}
          style={{ padding: "6px 10px" }}
        >
          <img
            src={`https://api.jababox.com/${
              product?.fileName ||
              (isOffline() ? "jabaoffline3.png" : "jabaonline3.png")
            }`}
            width="60"
            height="60"
            alt={product.name}
            style={{ borderRadius: "8px", objectFit: "cover" }}
          />
        </TableCell>

        <TableCell
          onClick={() => onConsoleClick(product)}
          align="center"
          style={{ padding: "6px 10px" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "4px",
            }}
          >
            {isOffline() ? <StatusIndicatorFalse /> : <StatusIndicatorTrue />}
            <div
              style={{
                fontSize: "12px",
                fontWeight: "500",
                color: isOffline() ? "#F44336" : "#4CAF50",
                marginTop: "2px",
              }}
            >
              {isOffline() ? "Desconectado" : "En línea"}
            </div>
          </div>
        </TableCell>

        <TableCell
          onClick={() => onCellClick(product)}
          align="center"
          component="th"
          scope="row"
          style={{ padding: "6px 10px" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span
              style={{ fontWeight: 600, color: "#424242", marginBottom: "4px" }}
            >
              {product.name}
            </span>
            <span
              style={{
                fontSize: "12px",
                color: "#757575",
                backgroundColor: "rgba(0,0,0,0.05)",
                padding: "2px 6px",
                borderRadius: "4px",
              }}
            >
              {product.sn}
            </span>
          </div>
        </TableCell>

        <TableCell
          onClick={() => onCellClick(product)}
          align="center"
          style={{ padding: "6px 10px" }}
        >
          {product.battery &&
          typeof product.battery === "string" &&
          product.battery.includes("Offline") ? (
            <div style={{ color: "#F44336", fontWeight: 500 }}>Offline</div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div style={{ marginBottom: "8px" }}>
                {typeof product.battery === "number" ||
                (typeof product.battery === "string" &&
                  !isNaN(parseFloat(product.battery))) ? (
                  parseFloat(product.battery) >= 90 ? (
                    <FcFullBattery size={30} />
                  ) : parseFloat(product.battery) >= 70 ? (
                    <FcHighBattery size={30} />
                  ) : parseFloat(product.battery) >= 50 ? (
                    <FcMiddleBattery size={30} />
                  ) : parseFloat(product.battery) >= 15 ? (
                    <FcLowBattery size={30} />
                  ) : (
                    <FcEmptyBattery size={30} />
                  )
                ) : (
                  <BsLightbulbOff size={30} style={{ color: "#9E9E9E" }} />
                )}
              </div>
              <div
                style={{
                  fontWeight: "500",
                  color:
                    parseFloat(product.battery) > 20 ? "#4CAF50" : "#FF9800",
                }}
              >
                {product.battery || "No disponible"}
                {product.battery && !isNaN(parseFloat(product.battery)) && "%"}
              </div>
            </div>
          )}
        </TableCell>

        <TableCell
          onClick={() => onCellClick(product)}
          align="center"
          style={{ padding: "6px 10px" }}
        >
          <ActionButtonInfo
            icon={product.solar === "true" ? BsSunFill : BsLightbulbOff}
            label={product.solar === "true" ? "Activo" : "Inactivo"}
            color={product.solar === "true" ? "#FF9800" : "#9E9E9E"}
            last_update={formatTime(product.last_solar)}
          />
        </TableCell>

        <TableCell
          onClick={() => onCellClick(product)}
          align="center"
          style={{ padding: "6px 10px" }}
        >
          <ActionButtonInfo
            icon={
              product.power_supply === "true" ? BsLightningFill : BsLightbulbOff
            }
            label={product.power_supply === "true" ? "Activo" : "Inactivo"}
            color={product.power_supply === "true" ? "#2196F3" : "#9E9E9E"}
            last_update={formatTime(product.last_power_supply)}
          />
        </TableCell>

        <TableCell
          onClick={() => onCellClick(product)}
          align="center"
          style={{ padding: "6px 10px" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
              justifyContent: "center",
              padding: "10px 0",
            }}
          >
            <CiTempHigh
              size={28}
              style={{
                color: parseFloat(product.temp) > 35 ? "#F44336" : "#4CAF50",
                marginBottom: "4px",
              }}
            />
            <span
              style={{
                fontWeight: "500",
                color: parseFloat(product.temp) > 35 ? "#F44336" : "#4CAF50",
              }}
            >
              {product.temp ? `${product.temp}°C` : "N/A"}
            </span>
          </div>
        </TableCell>

        <TableCell align="center" style={{ padding: "6px 10px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "12px",
            }}
          >
            {product.router && product.router.id && (
              <MuiTooltip title="Router vinculado" arrow placement="top">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={handleOpenRouterDialog}
                >
                  <MdRouter
                    size={20}
                    color="#2196F3"
                    style={{ marginBottom: "2px" }}
                  />
                  <span style={{ fontSize: "10px", color: "#757575" }}>
                    Router
                  </span>
                </div>
              </MuiTooltip>
            )}

            {product.is_license && (
              <MuiTooltip title="Licencia activa" arrow placement="top">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <GrCloudComputer
                    size={20}
                    style={{ marginBottom: "2px", opacity: 0.8 }}
                  />
                  <span style={{ fontSize: "10px", color: "#757575" }}>
                    Licencia
                  </span>
                </div>
              </MuiTooltip>
            )}

            {/* Puedes agregar más iconos de funciones según sea necesario */}
            {!product.router?.id && !product.is_license && (
              <span style={{ fontSize: "12px", color: "#757575" }}>-</span>
            )}
          </div>
        </TableCell>

        <TableCell
          align="center"
          style={{ padding: "8px 4px", verticalAlign: "middle" }}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              gap: "4px",
            }}
          >
            <div style={{ position: "relative" }}>
              <Button
                onMouseEnter={() => handleHover("reiniciar", true)}
                onMouseLeave={() => handleHover("reiniciar", false)}
                style={{
                  ...actionButtonStyles.base,
                  ...actionButtonStyles.reiniciar,
                  ...(hoverStates.reiniciar
                    ? actionButtonStyles.hoverEffect
                    : {}),
                }}
                onClick={handleOpenRebootDialog}
              >
                <Cached style={{ fontSize: "16px" }} />
                <div
                  style={{
                    ...actionButtonStyles.tooltip,
                    ...(hoverStates.reiniciar
                      ? actionButtonStyles.tooltipVisible
                      : {}),
                  }}
                >
                  Reiniciar
                </div>
              </Button>
            </div>

            {showMoreActions && (
              <>
                <div style={{ position: "relative" }}>
                  <Button
                    onMouseEnter={() => handleHover("encender", true)}
                    onMouseLeave={() => handleHover("encender", false)}
                    style={{
                      ...actionButtonStyles.base,
                      ...actionButtonStyles.encender,
                      ...(hoverStates.encender
                        ? actionButtonStyles.hoverEffect
                        : {}),
                    }}
                    onClick={() => onPowerOn(product.sn)}
                  >
                    <PowerSettingsNewOutlined style={{ fontSize: "16px" }} />
                    <div
                      style={{
                        ...actionButtonStyles.tooltip,
                        ...(hoverStates.encender
                          ? actionButtonStyles.tooltipVisible
                          : {}),
                      }}
                    >
                      Encender
                    </div>
                  </Button>
                </div>

                <div style={{ position: "relative" }}>
                  <Button
                    onMouseEnter={() => handleHover("apagar", true)}
                    onMouseLeave={() => handleHover("apagar", false)}
                    style={{
                      ...actionButtonStyles.base,
                      ...actionButtonStyles.apagar,
                      ...(hoverStates.apagar
                        ? actionButtonStyles.hoverEffect
                        : {}),
                    }}
                    onClick={() => onPowerOff(product.sn)}
                  >
                    <SettingsPowerOutlined style={{ fontSize: "16px" }} />
                    <div
                      style={{
                        ...actionButtonStyles.tooltip,
                        ...(hoverStates.apagar
                          ? actionButtonStyles.tooltipVisible
                          : {}),
                      }}
                    >
                      Apagar
                    </div>
                  </Button>
                </div>
              </>
            )}

            <div style={{ position: "relative" }}>
              <Button
                onMouseEnter={() => handleHover("editar", true)}
                onMouseLeave={() => handleHover("editar", false)}
                style={{
                  ...actionButtonStyles.base,
                  ...actionButtonStyles.editar,
                  ...(hoverStates.editar ? actionButtonStyles.hoverEffect : {}),
                }}
                onClick={() => onEdit(product)}
              >
                <Edit style={{ fontSize: "16px" }} />
                <div
                  style={{
                    ...actionButtonStyles.tooltip,
                    ...(hoverStates.editar
                      ? actionButtonStyles.tooltipVisible
                      : {}),
                  }}
                >
                  Editar
                </div>
              </Button>
            </div>

            <div style={{ position: "relative" }}>
              <Button
                onMouseEnter={() => handleHover("borrar", true)}
                onMouseLeave={() => handleHover("borrar", false)}
                style={{
                  ...actionButtonStyles.base,
                  ...actionButtonStyles.borrar,
                  ...(hoverStates.borrar ? actionButtonStyles.hoverEffect : {}),
                }}
                onClick={() => onDelete(product._id, product.sn)}
              >
                <Delete style={{ fontSize: "16px" }} />
                <div
                  style={{
                    ...actionButtonStyles.tooltip,
                    ...(hoverStates.borrar
                      ? actionButtonStyles.tooltipVisible
                      : {}),
                  }}
                >
                  Borrar
                </div>
              </Button>
            </div>

            <div style={{ position: "relative" }}>
              <Button
                onMouseEnter={() => handleHover("mas", true)}
                onMouseLeave={() => handleHover("mas", false)}
                style={{
                  ...actionButtonStyles.base,
                  color: "#757575",
                  backgroundColor: "rgba(117, 117, 117, 0.1)",
                  border: "1px solid rgba(117, 117, 117, 0.2)",
                  fontSize: "11px",
                  padding: "4px 8px",
                  ...(hoverStates.mas ? actionButtonStyles.hoverEffect : {}),
                }}
                onClick={() => setShowMoreActions(!showMoreActions)}
              >
                {showMoreActions ? "−" : "+"}
                <div
                  style={{
                    ...actionButtonStyles.tooltip,
                    ...(hoverStates.mas
                      ? actionButtonStyles.tooltipVisible
                      : {}),
                  }}
                >
                  {showMoreActions ? "Menos acciones" : "Más acciones"}
                </div>
              </Button>
            </div>
          </div>
        </TableCell>
      </TableRow>

      {/* Diálogo de confirmación para reiniciar */}
      <Dialog
        open={openRebootDialog}
        onClose={handleCloseRebootDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"¿Confirmar reinicio del dispositivo?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Está a punto de reiniciar el dispositivo{" "}
            <strong>{product.name}</strong> ({product.sn}). Esta acción
            interrumpirá temporalmente el servicio del dispositivo. ¿Está seguro
            de que desea continuar?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRebootDialog} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={handleConfirmReboot}
            color="primary"
            variant="contained"
            autoFocus
            style={{
              backgroundColor: "#FF9800",
              color: "white",
              "&:hover": {
                backgroundColor: "#F57C00",
              },
            }}
          >
            Sí, Reiniciar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Diálogo para la información del router */}
      <Dialog
        open={openRouterDialog}
        onClose={handleCloseRouterDialog}
        aria-labelledby="router-dialog-title"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="router-dialog-title">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h6">
              Información del Router - {product.name}
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<FiRefreshCw />}
              onClick={handleGetRouterData}
              disabled={isLoadingRouter}
            >
              Actualizar
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          {isLoadingRouter ? (
            <Box display="flex" justifyContent="center" my={4}>
              <CircularProgress />
              <Typography variant="body2" style={{ marginLeft: 16 }}>
                Conectando con el router...
              </Typography>
            </Box>
          ) : routerError ? (
            <Box p={2} bgcolor="#FFF4E5" borderRadius={1} mb={2}>
              <Typography color="error" variant="body2">
                <FiInfo
                  size={16}
                  style={{ verticalAlign: "middle", marginRight: 8 }}
                />
                {routerError}
              </Typography>
            </Box>
          ) : (
            routerData && (
              <div>
                {/* Cabecera con información básica del router */}
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  p={2}
                  bgcolor="#f5f5f5"
                  borderRadius={1}
                  mb={2}
                >
                  <div>
                    <Typography variant="h6" style={{ fontWeight: 600 }}>
                      Router {routerData.router.model || "Cudy"}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      IP: {routerData.router.ip}
                    </Typography>
                  </div>
                  <Box
                    display="flex"
                    alignItems="center"
                    bgcolor={
                      routerData.internet.connected ? "#E8F5E9" : "#FFEBEE"
                    }
                    color={
                      routerData.internet.connected ? "#2E7D32" : "#C62828"
                    }
                    p="6px 12px"
                    borderRadius={16}
                  >
                    {routerData.internet.connected ? (
                      <FiWifi size={18} style={{ marginRight: 6 }} />
                    ) : (
                      <FiWifiOff size={18} style={{ marginRight: 6 }} />
                    )}
                    <Typography variant="body2" style={{ fontWeight: 500 }}>
                      {routerData.internet.connected
                        ? "Conectado"
                        : "Desconectado"}
                    </Typography>
                  </Box>
                </Box>

                {/* Sección dividida en dos columnas: Internet y Módem */}
                <Grid container spacing={2} style={{ marginBottom: 24 }}>
                  {/* Columna de Internet */}
                  <Grid item xs={12} sm={6}>
                    <Paper
                      elevation={0}
                      variant="outlined"
                      style={{ padding: 16, height: "100%" }}
                    >
                      <Typography
                        variant="subtitle1"
                        style={{
                          fontWeight: 600,
                          marginBottom: 16,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <FiGlobe size={18} style={{ marginRight: 8 }} />
                        Conexión a Internet
                      </Typography>

                      <Box mb={1}>
                        <Grid container spacing={1}>
                          <Grid item xs={5}>
                            <Typography variant="body2" color="textSecondary">
                              Estado:
                            </Typography>
                          </Grid>
                          <Grid item xs={7}>
                            <Typography
                              variant="body2"
                              style={{
                                color: routerData.internet.connected
                                  ? "#2E7D32"
                                  : "#C62828",
                                fontWeight: 500,
                              }}
                            >
                              {routerData.internet.status}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid container spacing={1}>
                          <Grid item xs={5}>
                            <Typography variant="body2" color="textSecondary">
                              Interfaz:
                            </Typography>
                          </Grid>
                          <Grid item xs={7}>
                            <Typography
                              variant="body2"
                              style={{ fontWeight: 500 }}
                            >
                              {routerData.internet.interface}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Paper>
                  </Grid>

                  {/* Columna del Módem */}
                  <Grid item xs={12} sm={6}>
                    <Paper
                      elevation={0}
                      variant="outlined"
                      style={{ padding: 16, height: "100%" }}
                    >
                      <Typography
                        variant="subtitle1"
                        style={{
                          fontWeight: 600,
                          marginBottom: 16,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <FiSmartphone size={18} style={{ marginRight: 8 }} />
                        Información del Módem
                      </Typography>

                      <Box>
                        {routerData.modem && (
                          <Grid container spacing={1}>
                            <Grid item xs={5}>
                              <Typography variant="body2" color="textSecondary">
                                Operador:
                              </Typography>
                            </Grid>
                            <Grid item xs={7}>
                              <Typography
                                variant="body2"
                                style={{ fontWeight: 500 }}
                              >
                                {routerData.modem.operator !== "N/A"
                                  ? routerData.modem.operator
                                  : "No disponible"}
                              </Typography>
                            </Grid>

                            <Grid item xs={5}>
                              <Typography variant="body2" color="textSecondary">
                                Red:
                              </Typography>
                            </Grid>
                            <Grid item xs={7}>
                              <Typography
                                variant="body2"
                                style={{ fontWeight: 500 }}
                              >
                                {routerData.modem.networkType ||
                                  "No disponible"}
                              </Typography>
                            </Grid>

                            <Grid item xs={5}>
                              <Typography variant="body2" color="textSecondary">
                                Señal:
                              </Typography>
                            </Grid>
                            <Grid item xs={7}>
                              <Typography
                                variant="body2"
                                style={{ fontWeight: 500 }}
                              >
                                {routerData.modem.signal !== "N/A"
                                  ? routerData.modem.signal
                                  : "No disponible"}
                              </Typography>
                            </Grid>

                            <Grid item xs={5}>
                              <Typography variant="body2" color="textSecondary">
                                IMEI:
                              </Typography>
                            </Grid>
                            <Grid item xs={7}>
                              <Typography
                                variant="body2"
                                style={{ fontWeight: 500, fontSize: 11 }}
                              >
                                {routerData.modem.imei || "No disponible"}
                              </Typography>
                            </Grid>
                          </Grid>
                        )}
                      </Box>
                    </Paper>
                  </Grid>
                </Grid>

                {/* Sección de dispositivos conectados */}
                {routerData.devices && routerData.devices.length > 0 && (
                  <div>
                    <Typography
                      variant="subtitle1"
                      style={{
                        fontWeight: 600,
                        marginBottom: 16,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <FaNetworkWired size={18} style={{ marginRight: 8 }} />
                      Dispositivos conectados ({routerData.devices.length})
                    </Typography>

                    <Paper elevation={0} variant="outlined">
                      <Box p={1} style={{ overflowX: "auto" }}>
                        <table
                          style={{ width: "100%", borderCollapse: "collapse" }}
                        >
                          <thead style={{ backgroundColor: "#f5f5f5" }}>
                            <tr>
                              <th
                                style={{
                                  textAlign: "left",
                                  padding: "12px 16px",
                                  fontSize: 14,
                                  fontWeight: 600,
                                }}
                              >
                                Nombre
                              </th>
                              <th
                                style={{
                                  textAlign: "left",
                                  padding: "12px 16px",
                                  fontSize: 14,
                                  fontWeight: 600,
                                }}
                              >
                                IP
                              </th>
                              <th
                                style={{
                                  textAlign: "left",
                                  padding: "12px 16px",
                                  fontSize: 14,
                                  fontWeight: 600,
                                }}
                              >
                                MAC
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {routerData.devices.map((device, index) => (
                              <tr
                                key={index}
                                style={{
                                  borderTop: "1px solid #eeeeee",
                                  backgroundColor:
                                    index % 2 === 0 ? "white" : "#fafafa",
                                }}
                              >
                                <td
                                  style={{ padding: "12px 16px", fontSize: 14 }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <FaEthernet
                                      size={16}
                                      style={{
                                        marginRight: 8,
                                        color: "#2196F3",
                                        opacity: 0.9,
                                      }}
                                    />
                                    {device.name || "Desconocido"}
                                  </div>
                                </td>
                                <td
                                  style={{
                                    padding: "12px 16px",
                                    fontSize: 14,
                                    fontFamily: "monospace",
                                  }}
                                >
                                  {device.ip || "N/A"}
                                </td>
                                <td
                                  style={{
                                    padding: "12px 16px",
                                    fontSize: 14,
                                    fontFamily: "monospace",
                                  }}
                                >
                                  {device.mac || "N/A"}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </Box>
                    </Paper>
                  </div>
                )}
              </div>
            )
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRouterDialog} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

ProductTableRow.propTypes = {
  product: PropTypes.object.isRequired,
  onCellClick: PropTypes.func.isRequired,
  onConsoleClick: PropTypes.func.isRequired,
  onGrafClick: PropTypes.func.isRequired,
  onReboot: PropTypes.func.isRequired,
  onPowerOn: PropTypes.func.isRequired,
  onPowerOff: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default ProductTableRow;
