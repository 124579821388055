import React from "react";
import { Paper, Typography, Box, Grid, Button } from "@material-ui/core";
import { FiPlus, FiSettings, FiHelpCircle, FiInfo } from "react-icons/fi";
import { FiMonitor, FiPower, FiRefreshCw, FiMapPin } from "react-icons/fi";
import {
  colors,
  typography,
  spacing,
  borders,
  shadows,
} from "../../globalStyles";

const WelcomePanel = ({ onAddProduct }) => {
  const styles = {
    container: {
      padding: spacing.lg,
      backgroundColor: colors.neutral.white,
      borderRadius: borders.radius.lg,
      border: `1px solid ${colors.neutral.border}`,
      boxShadow: shadows.sm,
      marginBottom: spacing.xl,
    },
    title: {
      fontSize: typography.fontSize["2xl"],
      fontWeight: typography.fontWeights.bold,
      color: colors.neutral.dark,
      marginBottom: spacing.md,
    },
    subtitle: {
      fontSize: typography.fontSize.lg,
      fontWeight: typography.fontWeights.semibold,
      color: colors.neutral.main,
      marginBottom: spacing.md,
    },
    text: {
      fontSize: typography.fontSize.base,
      color: colors.neutral.main,
      marginBottom: spacing.md,
      lineHeight: 1.6,
    },
    button: {
      textTransform: "none",
      borderRadius: borders.radius.md,
      padding: `${spacing.sm} ${spacing.md}`,
      fontWeight: typography.fontWeights.medium,
      marginTop: spacing.md,
      fontSize: typography.fontSize.base,
    },
    primaryButton: {
      backgroundColor: colors.primary.main,
      color: "white",
      "&:hover": {
        backgroundColor: colors.primary.dark,
      },
    },
    featureContainer: {
      marginTop: spacing.lg,
      marginBottom: spacing.lg,
    },
    featureCard: {
      padding: spacing.md,
      backgroundColor: colors.neutral.light,
      borderRadius: borders.radius.md,
      height: "100%",
      border: `1px solid ${colors.neutral.border}`,
      transition: "all 0.2s ease-in-out",
      "&:hover": {
        transform: "translateY(-5px)",
        boxShadow: shadows.md,
      },
    },
    featureIcon: {
      fontSize: typography.fontSize["2xl"],
      color: colors.primary.main,
      marginBottom: spacing.sm,
    },
    featureTitle: {
      fontSize: typography.fontSize.lg,
      fontWeight: typography.fontWeights.semibold,
      color: colors.neutral.dark,
      marginBottom: spacing.sm,
    },
    featureDescription: {
      fontSize: typography.fontSize.sm,
      color: colors.neutral.main,
      lineHeight: 1.5,
    },
    stepsContainer: {
      marginTop: spacing.lg,
      marginBottom: spacing.lg,
    },
    stepCard: {
      display: "flex",
      padding: spacing.md,
      backgroundColor: colors.primary.light,
      borderRadius: borders.radius.md,
      marginBottom: spacing.md,
      border: `1px solid ${colors.primary.border}`,
    },
    stepNumber: {
      width: "32px",
      height: "32px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "50%",
      backgroundColor: colors.primary.main,
      color: "white",
      fontWeight: typography.fontWeights.bold,
      marginRight: spacing.md,
    },
    stepContent: {
      flex: 1,
    },
    stepTitle: {
      fontSize: typography.fontSize.lg,
      fontWeight: typography.fontWeights.semibold,
      color: colors.neutral.dark,
      marginBottom: spacing.xs,
    },
    stepDescription: {
      fontSize: typography.fontSize.sm,
      color: colors.neutral.text,
      lineHeight: 1.5,
    },
    infoBox: {
      display: "flex",
      alignItems: "flex-start",
      padding: spacing.md,
      backgroundColor: colors.primary.light,
      borderRadius: borders.radius.md,
      border: `1px solid ${colors.primary.border}`,
      marginTop: spacing.lg,
    },
    infoIcon: {
      fontSize: typography.fontSize.xl,
      color: colors.primary.main,
      marginRight: spacing.md,
      marginTop: "2px",
    },
    infoText: {
      fontSize: typography.fontSize.sm,
      color: colors.neutral.text,
      lineHeight: 1.6,
    },
  };

  const features = [
    {
      title: "Monitorización en tiempo real",
      description:
        "Consulta el estado de tus dispositivos en tiempo real, incluyendo batería, fuente de alimentación y comunicación.",
      icon: <FiMonitor style={styles.featureIcon} />,
    },
    {
      title: "Control remoto",
      description:
        "Controla tus dispositivos de forma remota, enciende/apaga relés y configura parámetros.",
      icon: <FiPower style={styles.featureIcon} />,
    },
    {
      title: "Actualización continua",
      description:
        "Los datos se refrescan automáticamente para mantener la información actualizada en todo momento.",
      icon: <FiRefreshCw style={styles.featureIcon} />,
    },
    {
      title: "Geolocalización",
      description:
        "Visualiza la ubicación exacta de tus dispositivos y cómo llegar a ellos.",
      icon: <FiMapPin style={styles.featureIcon} />,
    },
  ];

  const steps = [
    {
      number: 1,
      title: "Añadir un nuevo sistema",
      description:
        'Haz clic en el botón "AÑADIR SISTEMA" en la barra superior y completa la información requerida.',
    },
    {
      number: 2,
      title: "Configurar conexión",
      description:
        "Asegúrate de que el sistema esté encendido y conectado a la red para que pueda comunicarse con la plataforma.",
    },
    {
      number: 3,
      title: "Monitorizar el sistema",
      description:
        "Una vez conectado, podrás ver el estado del sistema en la tabla principal y acceder a detalles haciendo clic en él.",
    },
    {
      number: 4,
      title: "Control remoto",
      description:
        "Dentro de los detalles del sistema, podrás controlar relés, consultar gráficas y ajustar configuraciones.",
    },
  ];

  return (
    <Paper elevation={0} style={styles.container}>
      <Typography variant="h4" style={styles.title}>
        Bienvenido a la Plataforma JABABOX
      </Typography>

      <Typography variant="h6" style={styles.subtitle}>
        Tu sistema centralizado de monitorización y control
      </Typography>

      <Typography variant="body1" style={styles.text}>
        Esta plataforma te permite monitorizar y controlar todos tus sistemas
        JABABOX desde un solo lugar. Aquí podrás ver el estado de tus
        dispositivos, su ubicación, controlar relés y mucho más.
      </Typography>

      <Button
        variant="contained"
        color="primary"
        startIcon={<FiPlus />}
        style={{ ...styles.button, ...styles.primaryButton }}
        onClick={onAddProduct}
      >
        Añadir mi primer sistema
      </Button>

      {/* Características principales */}
      <Box style={styles.featureContainer}>
        <Typography variant="h6" style={styles.subtitle}>
          Características principales
        </Typography>

        <Grid container spacing={3}>
          {features.map((feature, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Box style={styles.featureCard}>
                {feature.icon}
                <Typography variant="h6" style={styles.featureTitle}>
                  {feature.title}
                </Typography>
                <Typography variant="body2" style={styles.featureDescription}>
                  {feature.description}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Pasos para comenzar */}
      <Box style={styles.stepsContainer}>
        <Typography variant="h6" style={styles.subtitle}>
          Comienza en 4 simples pasos
        </Typography>

        {steps.map((step, index) => (
          <Box key={index} style={styles.stepCard}>
            <Box style={styles.stepNumber}>{step.number}</Box>
            <Box style={styles.stepContent}>
              <Typography variant="subtitle1" style={styles.stepTitle}>
                {step.title}
              </Typography>
              <Typography variant="body2" style={styles.stepDescription}>
                {step.description}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>

      {/* Información adicional */}
      <Box style={styles.infoBox}>
        <FiInfo style={styles.infoIcon} />
        <Box>
          <Typography variant="body2" style={styles.infoText}>
            <strong>¿Necesitas ayuda?</strong> Si tienes dudas sobre cómo
            utilizar esta plataforma, haz clic en el botón "Ayuda" en la barra
            superior para acceder a una guía detallada. También puedes contactar
            con nuestro equipo de soporte técnico en cualquier momento.
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default WelcomePanel;
