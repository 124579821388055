// Función para formatear fechas
export const formatDate = (dateString) => {
  if (!dateString) return "No disponible";

  const date = new Date(dateString);
  return date.toLocaleString("es-ES", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
};

// Función para obtener el ícono de batería según el nivel
export const getBatteryIcon = (batteryLevel) => {
  const level = parseInt(batteryLevel, 10);

  if (isNaN(level)) return "empty";

  if (level >= 90) return "full";
  if (level >= 70) return "high";
  if (level >= 50) return "middle";
  if (level >= 15) return "low";
  return "empty";
};

// Función para obtener el color según el estado
export const getStatusColor = (status) => {
  return status === "true" ? "#4CAF50" : "#F44336";
};

// Función para abrir Google Maps con coordenadas
export const openGoogleMaps = (lat, long) => {
  if (!lat || !long) return;

  const url = `https://www.google.com/maps/dir/?api=1&destination=${lat},${long}`;
  window.open(url, "_blank");
};

// Función para generar un valor aleatorio entre min y max
export const getRandomValue = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

// Función para validar un número de serie
export const isValidSerialNumber = (sn) => {
  // Implementa la validación según tus requisitos
  return sn && sn.length > 0;
};

// Función para validar un código de verificación
export const isValidVerificationCode = (code) => {
  // Implementa la validación según tus requisitos
  return code && code.length > 0;
};
