import React, { Component } from "react";
import {
  LinearProgress,
  Paper,
  Typography,
  Box,
  Grid,
  Container,
  Snackbar,
  Backdrop,
  CircularProgress,
  Button,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { HelpOutline, InfoOutlined } from "@material-ui/icons";
import swal from "sweetalert";
import Tooltip from "@material-ui/core/Tooltip";

// Importar componentes
import DashboardHeader from "./Header/DashboardHeader";
import ProductTable from "./Table/ProductTable";
import AddProductModal from "./Modals/AddProductModal";
import EditProductModal from "./Modals/EditProductModal";
import ProductDetailsModal from "./Modals/ProductDetailsModal";
import GraphDialog from "./Modals/GraphDialog";
import WelcomePanel from "./Dashboard/WelcomePanel";
import NotificationSettingsModal from "./Modals/NotificationSettingsModal";
import HelpDialog from "./Common/HelpDialog";
import StatisticsPanel from "./Dashboard/StatisticsPanel";

// Importar servicios y utilidades
import * as api from "../services/api";
import { openGoogleMaps } from "../utils/helpers";

// Importar estilos
import "../dashboardStyles.css";
import {
  colors,
  typography,
  spacing,
  shadows,
  components,
  layout,
} from "../globalStyles";

// Función para el componente Alert
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      openProductModal: false,
      openProductModal1: false,
      openProductEditModal: false,
      openGraphDialog: false,
      id: "",
      name: "",
      sn: "",
      verification_code: "",
      battery: "",
      solar: "",
      power_supply: "",
      status: "",
      fileName: "",
      page: 1,
      search: "",
      products: [],
      pages: 0,
      loading: false,
      isFullScreenLoading: false,
      qrScannerOpen: false,
      location: "",
      mapCenter: [0, 0],
      isLive: false,
      history: [],
      auxr1: false,
      auxr2: false,
      ind8: true,
      ind9: true,
      mainload: false,
      lat: "",
      long: "",
      simOperator: "",
      lastEvent: "",
      selectedProductSerial: null,
      selectedProduct: null,
      lastEvent2: props.selectedProduct?.lastEvent,
      showHelp: false,
      snackbarOpen: false,
      snackbarMessage: "",
      snackbarSeverity: "info",
      openNotificationSettings: false,
      openHelpDialog: false,
      filtroFabricante: "",
      filtroStatus: "",
      filtroRouter: "",
      filtroPower: "",
      filtroSolar: "",
      totalResults: 0,
      displayedItems: [],
      page: 1,
      rowsPerPage: 10,
      alertModal: {
        open: false,
        title: "",
        errorMessage: "",
        warningMessage: "",
        previewMessage: "",
      },
    };
  }

  componentDidMount = () => {
    this.setState({ isFullScreenLoading: true });
    let token = localStorage.getItem("token");
    if (!token) {
      this.props.history.push("/");
    } else {
      this.setState({ token: token }, () => {
        this.getProduct();
      });
    }
  };

  // Métodos para manejar notificaciones y mensajes
  showSnackbar = (message, severity = "info") => {
    this.setState({
      snackbarOpen: true,
      snackbarMessage: message,
      snackbarSeverity: severity,
    });
  };

  handleSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };

  handleOpenHelpDialog = () => {
    this.setState({ openHelpDialog: true });
  };

  handleCloseHelpDialog = () => {
    this.setState({ openHelpDialog: false });
  };

  toggleHelpView = () => {
    this.handleOpenHelpDialog();
  };

  // Métodos para manejar productos
  getProduct = () => {
    this.setState({ loading: true });

    const { page, search } = this.state;

    api
      .getProducts(page, search)
      .then((data) => {
        console.log("Datos paginación:", {
          current_page: data.current_page,
          pages: data.pages,
          total: data.total,
        });

        this.setState({
          loading: false,
          isFullScreenLoading: false,
          products: data.products,
          pages: data.pages ? parseInt(data.pages) : 0,
          page: data.current_page ? parseInt(data.current_page) : 1,
        });

        if (data.products.length === 0) {
          this.showSnackbar(
            "No se encontraron dispositivos. ¡Añade tu primer sistema!",
            "info"
          );
        } else {
          this.showSnackbar(
            `Se encontraron ${data.products.length} dispositivos`,
            "success"
          );
        }
      })
      .catch((error) => {
        console.error("Error al obtener productos:", error);
        this.setState({ loading: false, isFullScreenLoading: false });
        this.showSnackbar(
          "Error al cargar los dispositivos. Intente de nuevo.",
          "error"
        );
      });
  };

  getProductHide = () => {
    api
      .getProducts(this.state.page, this.state.search)
      .then((res) => {
        this.setState({
          loading: false,
          products: res.products,
          pages: res.pages,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  addProduct = () => {
    // Datos del sistema JABABOX a registrar
    const productData = {
      name: this.state.name,
      sn: this.state.sn,
      verification_code: this.state.verification_code,
    };

    // Paso 1: Mostrar un mensaje de proceso para informar al usuario que se está registrando el sistema
    swal({
      title: "Registrando sistema JABABOX",
      text: "Por favor espere mientras se registra el sistema...",
      icon: "info",
      buttons: false,
      closeOnClickOutside: false,
      closeOnEsc: false,
    });

    this.setState({ loading: true });

    api
      .addProduct(productData)
      .then((res) => {
        this.setState({ loading: false });
        this.handleProductClose();
        this.setState({ name: "", sn: "", verification_code: "" });
        this.getProduct();

        // Paso 2: Mostrar mensaje de éxito con detalles y ofrecer continuar el proceso
        swal({
          title: "¡Sistema JABABOX registrado con éxito!",
          text: "El sistema ha sido registrado correctamente. Ahora puede agregar un router JABABOX para gestionar la conexión de red.",
          icon: "success",
          buttons: {
            finish: {
              text: "Finalizar",
              value: false,
            },
            continue: {
              text: "Agregar router JABABOX",
              value: true,
            },
          },
        }).then((willAddRouter) => {
          if (willAddRouter) {
            // Paso 3: Solicitar el ID del router con instrucciones claras
            swal({
              title: "Agregar router JABABOX",
              text: "Introduzca el ID del router que encontrará en la parte posterior del dispositivo (formato: 10 caracteres alfanuméricos)",
              content: {
                element: "input",
                attributes: {
                  placeholder: "Ejemplo: 74ed5e0765",
                  type: "text",
                },
              },
              buttons: {
                cancel: {
                  text: "Cancelar",
                  value: null,
                  visible: true,
                },
                confirm: {
                  text: "Continuar",
                  value: true,
                  className: "btn-success",
                },
              },
            }).then((routerId) => {
              if (routerId) {
                // Paso 4: Informar que se está consultando el ID en ZeroTier
                swal({
                  title: "Verificando router",
                  text: "Conectando con ZeroTier para verificar el ID del router...",
                  icon: "info",
                  buttons: false,
                  closeOnClickOutside: false,
                  closeOnEsc: false,
                });

                this.setState({ loading: true });

                // Consultar la API de ZeroTier para obtener la IP del router
                api
                  .getZeroTierDeviceIp(routerId)
                  .then((zerotierData) => {
                    if (zerotierData.success && zerotierData.ip) {
                      // Paso 5: Informar que se encontró el router y se intentará conectar
                      swal({
                        title: "Router encontrado",
                        text: `Se ha encontrado el router con ID ${routerId} en la red ZeroTier. Dirección IP: ${zerotierData.ip}\n\nIntentando conectar al router...`,
                        icon: "info",
                        buttons: false,
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                      });

                      // Configuración para conectar al router
                      const routerConfig = {
                        host: zerotierData.ip,
                        username: "admin",
                        password: "Jababox##2025@",
                      };

                      api
                        .getRouterCudyData(routerConfig)
                        .then((routerData) => {
                          // Datos del router para guardar en la base de datos
                          const routerDataToSave = {
                            id: routerId,
                            ip: zerotierData.ip,
                            zerotier_id: routerId,
                            zerotier_ip: zerotierData.ip,
                            username: "admin",
                            password: "Jababox##2025@",
                            connected: true,
                          };

                          // Paso 6: Informar que se estableció conexión y se guardará la información
                          swal({
                            title: "Conexión establecida",
                            text: "Se ha conectado correctamente al router. Guardando la información...",
                            icon: "info",
                            buttons: false,
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                          });

                          // Guardar la información del router en la base de datos
                          api
                            .updateProduct({
                              sn: productData.sn,
                              router: routerDataToSave,
                            })
                            .then(() => {
                              this.setState({ loading: false });

                              // Paso 7: Proceso completado con éxito
                              swal({
                                title: "¡Proceso completado!",
                                html: true,
                                text: `<div>
                                  <p><strong>Sistema JABABOX:</strong> Registrado correctamente</p>
                                  <p><strong>Router JABABOX:</strong> Conectado correctamente</p>
                                  <p><strong>ID Router:</strong> ${routerId}</p>
                                  <p><strong>IP Router:</strong> ${zerotierData.ip}</p>
                                </div>`,
                                icon: "success",
                              });

                              this.getProduct(); // Actualizar la lista de productos
                            })
                            .catch((error) => {
                              this.setState({ loading: false });

                              // Error en el guardado
                              swal({
                                title: "Advertencia",
                                text: "La conexión con el router fue exitosa, pero hubo un problema al guardar la información. El sistema está registrado pero deberá configurar el router más tarde.",
                                icon: "warning",
                              });
                            });
                        })
                        .catch((error) => {
                          this.setState({ loading: false });

                          // Error de conexión al router
                          swal({
                            title: "Error de conexión",
                            text: "No se pudo conectar al router. Posibles causas:\n- El router no está encendido\n- El router no está conectado a la red\n- Las credenciales del router son incorrectas\n\nEl sistema ha sido registrado correctamente. Podrá configurar el router más tarde.",
                            icon: "error",
                          });
                        });
                    } else {
                      this.setState({ loading: false });

                      // Error: Router no encontrado en ZeroTier
                      swal({
                        title: "Router no encontrado",
                        text: `No se ha encontrado el router con ID ${routerId} en la red ZeroTier. Verifique que:\n- El ID del router es correcto\n- El router está encendido y conectado a Internet\n- El router está registrado en la red ZeroTier\n\nEl sistema ha sido registrado correctamente. Podrá configurar el router más tarde.`,
                        icon: "error",
                      });
                    }
                  })
                  .catch((error) => {
                    this.setState({ loading: false });

                    // Error en la API de ZeroTier
                    swal({
                      title: "Error",
                      text: `Ha ocurrido un error al consultar la API de ZeroTier: ${
                        error.message || "Error desconocido"
                      }.\n\nEl sistema ha sido registrado correctamente. Podrá configurar el router más tarde.`,
                      icon: "error",
                    });
                  });
              } else {
                // El usuario canceló la adición del router
                swal({
                  title: "Proceso finalizado",
                  text: "El sistema JABABOX ha sido registrado correctamente. Puede configurar el router más tarde desde la sección de detalles del producto.",
                  icon: "success",
                });
              }
            });
          } else {
            // El usuario decidió no agregar un router
            swal({
              title: "Registro completado",
              text: "El sistema JABABOX ha sido registrado correctamente.",
              icon: "success",
            });
          }
        });
      })
      .catch((err) => {
        this.setState({ loading: false });

        // Error en el registro del sistema
        swal({
          title: "Error de registro",
          text: `No se ha podido registrar el sistema JABABOX. Error: ${
            err.message || "Error desconocido"
          }`,
          icon: "error",
        });
      });
  };

  updateProduct = () => {
    const productData = {
      id: this.state.id,
      name: this.state.name,
      sn: this.state.sn,
    };

    api
      .updateProduct(productData)
      .then((res) => {
        swal({
          text: res.data,
          icon: "success",
          type: "success",
        });
        this.handleProductEditClose();
        this.getProduct();
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  deleteProduct = (id, sn) => {
    swal({
      title: "¿Estás seguro?",
      text: "Una vez eliminado, no podrás recuperar este sistema",
      icon: "warning",
      buttons: ["Cancelar", "Eliminar"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        // Buscar el producto a eliminar
        const product = this.state.products.find((p) => p._id === id);

        // Verificar si el producto existe
        if (!product) {
          console.error("No se encontró el producto con ID:", id);
          swal({
            text: "Error: No se encontró el producto a eliminar",
            icon: "error",
          });
          return;
        }

        // Usar el número de serie (sn) como MAC
        // La MAC es el número de serie según la aclaración del usuario
        const macAddress = product.sn;

        if (!macAddress) {
          console.error(
            "No se encontró el número de serie en el producto:",
            product
          );
          swal({
            title: "Error: No se pudo obtener el número de serie",
            text: "El dispositivo no tiene un número de serie válido",
            icon: "error",
          });
          return;
        }

        console.log(`Eliminando producto: ID=${id}, SN/MAC=${macAddress}`);

        // Proceder con la eliminación
        api
          .deleteProduct(id, macAddress)
          .then((res) => {
            swal({
              text: res.data,
              icon: "success",
              type: "success",
            });
            this.getProduct();
          })
          .catch((err) => {
            this.setState({ loading: false });
          });
      }
    });
  };

  // Métodos para manejar eventos de la interfaz
  handleProductOpen = () => {
    this.setState({
      openProductModal: true,
      id: "",
      name: "",
      sn: "",
      verification_code: "",
    });
  };

  handleProductClose = () => {
    this.setState({ openProductModal: false });
  };

  handleProductEditOpen = (product) => {
    this.setState({
      openProductEditModal: true,
      id: product._id,
      name: product.name,
      sn: product.sn,
    });
  };

  handleProductEditClose = () => {
    this.setState({ openProductEditModal: false });
  };

  handleCellClick = (product) => {
    this.setState({
      openProductModal1: true,
      selectedProduct: product,
      selectedProductSerial: product.sn,
      lat: product.lat,
      long: product.long,
    });
  };

  handleProductClose1 = () => {
    this.setState({ openProductModal1: false });
  };

  handleOpenGraphDialog = () => {
    this.setState({ openGraphDialog: true });
  };

  handleCloseGraphDialog = () => {
    this.setState({ openGraphDialog: false });
  };

  toggleQRScanner = () => {
    this.setState((prevState) => ({
      qrScannerOpen: !prevState.qrScannerOpen,
    }));
  };

  handleQRScan = (data) => {
    if (data) {
      this.setState({ sn: data, qrScannerOpen: false });
    }
  };

  // Métodos para controlar dispositivos
  handleReboot = (sn) => {
    api
      .rebootDevice(sn)
      .then((res) => {
        swal({
          title: "¡Dispositivo reiniciado!",
          text: res.data || "El dispositivo ha sido reiniciado correctamente",
          icon: "success",
          type: "success",
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  handlePowerOn = (sn) => {
    api
      .powerOnDevice(sn)
      .then((res) => {
        swal({
          text: res.data,
          icon: "success",
          type: "success",
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  handlePowerOff = (sn) => {
    api
      .powerOffDevice(sn)
      .then((res) => {
        swal({
          text: res.data,
          icon: "success",
          type: "success",
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  handlePowerAuxr1 = (sn, currentState) => {
    api
      .controlRelay(sn, "auxr1", !currentState)
      .then((res) => {
        swal({
          text: res.data,
          icon: "success",
          type: "success",
        });
        this.getProductHide();
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  handlePowerAuxr2 = (sn, currentState) => {
    api
      .controlRelay(sn, "auxr2", !currentState)
      .then((res) => {
        swal({
          text: res.data,
          icon: "success",
          type: "success",
        });
        this.getProductHide();
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  handlePowerMainload = (sn, currentState) => {
    api
      .controlRelay(sn, "mainload", !currentState)
      .then((res) => {
        swal({
          text: res.data,
          icon: "success",
          type: "success",
        });
        this.getProductHide();
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  // Otros métodos
  onChange = (e) => {
    if (e.target.name === "search") {
      this.setState({ page: 1, [e.target.name]: e.target.value }, () => {
        this.getProduct();
      });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  pageChange = (e, page) => {
    this.setState({ page: page + 1 }, () => {
      this.getProduct();
    });
  };

  logOut = () => {
    localStorage.setItem("token", null);
    this.props.history.push("/");
  };

  reload = () => {
    this.getProduct();
  };

  dialogReload = () => {
    if (this.state.selectedProductSerial) {
      api
        .getProductBySN(this.state.selectedProductSerial)
        .then((res) => {
          this.setState({
            selectedProduct: res.product,
            lat: res.product.lat,
            long: res.product.long,
          });
        })
        .catch((err) => {
          this.setState({ loading: false });
        });
    }
  };

  locatemap = (lat, long) => {
    openGoogleMaps(lat, long);
  };

  // Método para abrir el modal de configuración de notificaciones
  settings = () => {
    this.setState({ openNotificationSettings: true });
  };

  // Método para cerrar el modal de configuración de notificaciones
  handleNotificationSettingsClose = () => {
    this.setState({ openNotificationSettings: false });
  };

  consoleClick = (product) => {
    console.log(product);
  };

  handlegrafClick = (product) => {
    this.setState({
      openGraphDialog: true,
      history: product.history,
    });
  };

  isOffline = (product) => {
    // Si es un objeto con propiedad estado
    if (
      product.status &&
      typeof product.status === "object" &&
      "estado" in product.status
    ) {
      const estadoValue = product.status.estado;
      if (
        estadoValue === "true" ||
        estadoValue === true ||
        estadoValue === "1" ||
        estadoValue === 1
      ) {
        return false; // El dispositivo está online
      }
      if (
        estadoValue === "false" ||
        estadoValue === false ||
        estadoValue === "0" ||
        estadoValue === 0
      ) {
        return true; // El dispositivo está offline
      }
    }

    // Si es un objeto con propiedad status
    if (
      product.status &&
      typeof product.status === "object" &&
      "status" in product.status
    ) {
      const statusValue = product.status.status;
      if (
        statusValue === "true" ||
        statusValue === true ||
        statusValue === "1" ||
        statusValue === 1
      ) {
        return false; // El dispositivo está online
      }
      if (
        statusValue === "false" ||
        statusValue === false ||
        statusValue === "0" ||
        statusValue === 0
      ) {
        return true; // El dispositivo está offline
      }
    }

    // Si es un valor directo
    if (
      product.status === "true" ||
      product.status === true ||
      product.status === "1" ||
      product.status === 1
    ) {
      return false; // El dispositivo está online
    }
    if (
      product.status === "false" ||
      product.status === false ||
      product.status === "0" ||
      product.status === 0
    ) {
      return true; // El dispositivo está offline
    }

    // Si es un string que tiene "online"
    if (typeof product.status === "string") {
      const lowerStatus = product.status.toLowerCase();
      if (lowerStatus.includes("online") || lowerStatus.includes("en línea")) {
        return false; // El dispositivo está online
      }
    }

    // Si es un string que tiene "Desconocido" o "desconocido"
    if (
      typeof product.status === "string" &&
      (product.status.includes("Desconocido") ||
        product.status.toLowerCase().includes("desconocido"))
    ) {
      return true;
    }

    // Si la batería indica Offline
    if (product.battery === "Offline%" || product.battery === "false") {
      return true;
    }

    // Si no podemos determinar, asumimos offline por seguridad
    return true;
  };

  render() {
    const {
      products,
      loading,
      isFullScreenLoading,
      openProductModal,
      openProductEditModal,
      name,
      sn,
      verification_code,
      openProductModal1,
      search,
      page,
      pages,
      history,
      selectedProduct,
      openGraphDialog,
      isLive,
      snackbarOpen,
      snackbarMessage,
      snackbarSeverity,
      openNotificationSettings,
      openHelpDialog,
    } = this.state;

    const dashboardContainerStyle = {
      marginTop: "12px",
      padding: "0",
    };

    const infoBoxStyle = {
      backgroundColor: colors.info.background,
      color: colors.info.text,
      border: `1px solid ${colors.neutral.border}`,
      borderRadius: "4px",
      padding: spacing.sm,
      marginBottom: spacing.md,
      fontSize: typography.fontSize.sm,
    };

    return (
      <div
        style={{
          backgroundColor: colors.neutral.background,
          minHeight: "100vh",
        }}
      >
        {/* Full Screen Loading */}
        <Backdrop
          style={{ zIndex: 9999, color: colors.primary.main }}
          open={isFullScreenLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        {/* Loading Bar para operaciones menos intensivas */}
        {loading && !isFullScreenLoading && (
          <LinearProgress
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              zIndex: 9000,
              backgroundColor: colors.primary.light,
              "& .MuiLinearProgress-bar": {
                backgroundColor: colors.primary.main,
              },
            }}
          />
        )}

        {/* Header */}
        <DashboardHeader
          isLive={isLive}
          onAddProduct={this.handleProductOpen}
          onSettings={this.settings}
          onRefresh={this.reload}
          onLogout={this.logOut}
          onHelpToggle={this.handleOpenHelpDialog}
        />

        {/* Contenido principal del Dashboard */}
        <Container maxWidth="xl" style={dashboardContainerStyle}>
          {/* Panel de Estadísticas - Mostrar solo si hay dispositivos */}
          {products.length > 0 && <StatisticsPanel />}

          {/* Panel de bienvenida - Mostrar solo cuando no hay dispositivos */}
          {products.length === 0 && (
            <WelcomePanel onAddProduct={this.handleProductOpen} />
          )}

          {/* Tabla de Productos - Mostrar si hay productos */}
          {products.length > 0 && (
            <ProductTable
              products={products}
              search={search}
              onSearchChange={this.onChange}
              page={page}
              pages={pages}
              onPageChange={this.pageChange}
              onCellClick={this.handleCellClick}
              onConsoleClick={this.consoleClick}
              onGrafClick={this.handlegrafClick}
              onReboot={this.handleReboot}
              onPowerOn={this.handlePowerOn}
              onPowerOff={this.handlePowerOff}
              onEdit={this.handleProductEditOpen}
              onDelete={this.deleteProduct}
            />
          )}
        </Container>

        {/* Modals */}
        <AddProductModal
          open={openProductModal}
          name={this.state.name}
          sn={this.state.sn}
          verification_code={this.state.verification_code}
          qrScannerOpen={this.state.qrScannerOpen}
          onChange={this.onChange}
          onClose={this.handleProductClose}
          onAdd={this.addProduct}
          onToggleQRScanner={this.toggleQRScanner}
          onQRScan={this.handleQRScan}
        />

        <EditProductModal
          open={openProductEditModal}
          name={this.state.name}
          sn={this.state.sn}
          onChange={this.onChange}
          onClose={this.handleProductEditClose}
          onUpdate={this.updateProduct}
        />

        <ProductDetailsModal
          open={openProductModal1}
          product={selectedProduct}
          isLive={isLive}
          onClose={this.handleProductClose1}
          onRefresh={this.dialogReload}
          onOpenGraph={this.handleOpenGraphDialog}
          onPowerAuxr1={this.handlePowerAuxr1}
          onPowerAuxr2={this.handlePowerAuxr2}
          onPowerMainload={this.handlePowerMainload}
        />

        <GraphDialog
          open={openGraphDialog}
          historyData={history}
          onClose={this.handleCloseGraphDialog}
        />

        {/* Snackbar para notificaciones */}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={this.handleSnackbarClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <Alert
            onClose={this.handleSnackbarClose}
            severity={snackbarSeverity}
            style={{
              backgroundColor:
                snackbarSeverity === "error"
                  ? colors.error.light
                  : snackbarSeverity === "success"
                  ? colors.success.light
                  : snackbarSeverity === "warning"
                  ? colors.warning.light
                  : colors.primary.light,
              color:
                snackbarSeverity === "error"
                  ? colors.error.text
                  : snackbarSeverity === "success"
                  ? colors.success.text
                  : snackbarSeverity === "warning"
                  ? colors.warning.text
                  : colors.primary.text,
              border: `1px solid ${
                snackbarSeverity === "error"
                  ? colors.error.border
                  : snackbarSeverity === "success"
                  ? colors.success.border
                  : snackbarSeverity === "warning"
                  ? colors.warning.border
                  : colors.primary.border
              }`,
            }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>

        {/* Modal de configuración de notificaciones */}
        <NotificationSettingsModal
          open={openNotificationSettings}
          onClose={this.handleNotificationSettingsClose}
        />

        {/* Nuevo modal de ayuda */}
        <HelpDialog
          open={openHelpDialog}
          onClose={this.handleCloseHelpDialog}
        />
      </div>
    );
  }
}
