import React from "react";
import PropTypes from "prop-types";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Paper,
  Typography,
  InputAdornment,
  Box,
  Chip,
} from "@material-ui/core";
import TablePagination from "@material-ui/core/TablePagination";
import SearchIcon from "@material-ui/icons/Search";
import { FiInfo } from "react-icons/fi";
import ProductTableRow from "./ProductTableRow";
import {
  colors,
  typography,
  spacing,
  shadows,
  borders,
} from "../../globalStyles";

const ProductTable = ({
  products,
  search,
  onSearchChange,
  page,
  pages,
  onPageChange,
  onCellClick,
  onConsoleClick,
  onGrafClick,
  onReboot,
  onPowerOn,
  onPowerOff,
  onEdit,
  onDelete,
}) => {
  // Estilos para la tabla
  const tableStyles = {
    container: {
      border: `1px solid ${colors.neutral.border}`,
      borderRadius: borders.radius.lg,
      overflow: "visible",
      boxShadow: shadows.sm,
      backgroundColor: colors.neutral.white,
    },
    headerRow: {
      backgroundColor: colors.neutral.light,
    },
    headerCell: {
      fontWeight: typography.fontWeights.semibold,
      color: colors.neutral.dark,
      padding: `${spacing.xs} ${spacing.sm}`,
      fontSize: typography.fontSize.sm,
      textTransform: "uppercase",
      letterSpacing: "0.5px",
    },
    searchBar: {
      padding: `${spacing.md} ${spacing.md} ${spacing.sm} ${spacing.md}`,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderBottom: `1px solid ${colors.neutral.border}`,
    },
    searchInput: {
      width: "400px",
      backgroundColor: colors.neutral.white,
      "& .MuiOutlinedInput-root": {
        borderRadius: borders.radius.md,
        "& fieldset": {
          borderColor: colors.neutral.border,
        },
        "&:hover fieldset": {
          borderColor: colors.primary.main,
        },
        "&.Mui-focused fieldset": {
          borderColor: colors.primary.main,
        },
      },
    },
    infoBox: {
      display: "flex",
      alignItems: "center",
      fontSize: typography.fontSize.sm,
      color: colors.neutral.main,
      backgroundColor: colors.neutral.light,
      padding: `${spacing.xs} ${spacing.sm}`,
      borderRadius: borders.radius.pill,
      border: `1px solid ${colors.neutral.border}`,
    },
    infoIcon: {
      marginRight: spacing.xs,
      fontSize: typography.fontSize.md,
      color: colors.primary.main,
    },
    pagination: {
      borderTop: `1px solid ${colors.neutral.border}`,
      padding: `${spacing.sm} ${spacing.md}`,
    },
    noDataMessage: {
      padding: `${spacing.xl} ${spacing.md}`,
      textAlign: "center",
      color: colors.neutral.main,
    },
  };

  return (
    <Paper elevation={0} style={tableStyles.container}>
      <div style={tableStyles.searchBar}>
        <TextField
          id="product-search"
          type="search"
          autoComplete="off"
          name="search"
          value={search}
          onChange={onSearchChange}
          placeholder="Buscar dispositivo por nombre o serial"
          variant="outlined"
          size="small"
          style={tableStyles.searchInput}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="action" />
              </InputAdornment>
            ),
          }}
        />
        <div style={tableStyles.infoBox}>
          <FiInfo style={tableStyles.infoIcon} />
          <Typography variant="body2">
            {products.length === 0
              ? "No hay dispositivos para mostrar"
              : `Mostrando ${products.length} dispositivo${
                  products.length !== 1 ? "s" : ""
                }`}
          </Typography>
        </div>
      </div>

      <TableContainer style={{ overflow: "visible" }}>
        <Table aria-label="tabla de dispositivos">
          <TableHead>
            <TableRow style={tableStyles.headerRow}>
              <TableCell
                align="center"
                style={{ ...tableStyles.headerCell, width: "100px" }}
              >
                DISPOSITIVO
              </TableCell>
              <TableCell
                align="center"
                style={{ ...tableStyles.headerCell, width: "100px" }}
              >
                ESTADO
              </TableCell>
              <TableCell
                align="left"
                style={{ ...tableStyles.headerCell, minWidth: "200px" }}
              >
                NOMBRE Y SERIAL
              </TableCell>
              <TableCell
                align="center"
                style={{ ...tableStyles.headerCell, width: "100px" }}
              >
                BATERÍA
              </TableCell>
              <TableCell
                align="center"
                style={{ ...tableStyles.headerCell, width: "100px" }}
              >
                SOLAR
              </TableCell>
              <TableCell
                align="center"
                style={{ ...tableStyles.headerCell, width: "100px" }}
              >
                FUENTE
              </TableCell>
              <TableCell
                align="center"
                style={{ ...tableStyles.headerCell, width: "120px" }}
              >
                TEMPERATURA
              </TableCell>
              <TableCell
                align="center"
                style={{ ...tableStyles.headerCell, width: "120px" }}
              >
                FUNCIONES
              </TableCell>
              <TableCell
                align="center"
                style={{
                  ...tableStyles.headerCell,
                  width: "220px",
                }}
              >
                ACCIONES
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {products.length > 0 ? (
              products.map((product) => (
                <ProductTableRow
                  key={product._id}
                  product={product}
                  onCellClick={onCellClick}
                  onConsoleClick={onConsoleClick}
                  onGrafClick={onGrafClick}
                  onReboot={onReboot}
                  onPowerOn={onPowerOn}
                  onPowerOff={onPowerOff}
                  onEdit={onEdit}
                  onDelete={onDelete}
                />
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={9} style={tableStyles.noDataMessage}>
                  <Box p={4}>
                    <Typography
                      variant="body1"
                      style={{
                        fontWeight: typography.fontWeights.medium,
                        marginBottom: spacing.sm,
                      }}
                    >
                      No hay dispositivos disponibles
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Utiliza el botón "AÑADIR SISTEMA" para registrar un nuevo
                      dispositivo
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={pages * 10}
        page={page - 1}
        onPageChange={onPageChange}
        rowsPerPage={10}
        rowsPerPageOptions={[10]}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} de ${count !== -1 ? count : `más de ${to}`}`
        }
        labelRowsPerPage="Filas por página:"
        style={tableStyles.pagination}
      />
    </Paper>
  );
};

ProductTable.propTypes = {
  products: PropTypes.array.isRequired,
  search: PropTypes.string.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onCellClick: PropTypes.func.isRequired,
  onConsoleClick: PropTypes.func.isRequired,
  onGrafClick: PropTypes.func.isRequired,
  onReboot: PropTypes.func.isRequired,
  onPowerOn: PropTypes.func.isRequired,
  onPowerOff: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default ProductTable;
