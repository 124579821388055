import React, { Component } from "react";
import swal from "sweetalert";
import {
  Button,
  TextField,
  Link,
  Paper,
  Container,
  Typography,
  Box,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import {
  Email,
  Person,
  Lock,
  Visibility,
  VisibilityOff,
} from "@material-ui/icons";
import Particles from "react-particles-js";
import "./Login.css"; // Using the same CSS file as Login

const axios = require("axios");

export default class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      username: "",
      password: "",
      confirm_password: "",
      showPassword: false,
      showConfirmPassword: false,
      isLoading: false,
    };
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  togglePasswordVisibility = (field) => {
    if (field === "password") {
      this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
    } else {
      this.setState((prevState) => ({
        showConfirmPassword: !prevState.showConfirmPassword,
      }));
    }
  };

  register = () => {
    this.setState({ isLoading: true });

    axios
      .post("https://api.jababox.com/register", {
        email: this.state.email,
        username: this.state.username,
        password: this.state.password,
      })
      .then((res) => {
        swal({
          text: res.data.title,
          icon: "success",
          type: "success",
        });
        this.props.history.push("/");
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        swal({
          text: err.response.data.errorMessage,
          icon: "error",
          type: "error",
        });
      });
  };

  render() {
    const {
      email,
      username,
      password,
      confirm_password,
      showPassword,
      showConfirmPassword,
      isLoading,
    } = this.state;

    // Form validation rules
    const isEmailValid = email.length > 0 && /\S+@\S+\.\S+/.test(email);
    const isUsernameValid = username.length > 0;
    const isPasswordMatch = password === confirm_password;
    const isPasswordValid = password.length >= 6;

    const enabled =
      isEmailValid &&
      isUsernameValid &&
      isPasswordMatch &&
      isPasswordValid &&
      password !== "" &&
      confirm_password !== "";

    return (
      <div className="login-container">
        <div className="particles-container">
          <Particles
            params={{
              particles: {
                number: {
                  value: 80,
                  density: {
                    enable: true,
                    value_area: 800,
                  },
                },
                color: {
                  value: "#ffffff",
                },
                shape: {
                  type: "circle",
                  stroke: {
                    width: 0,
                    color: "#000000",
                  },
                },
                opacity: {
                  value: 0.5,
                  random: true,
                },
                size: {
                  value: 3,
                  random: true,
                },
                line_linked: {
                  enable: true,
                  distance: 150,
                  color: "#ffffff",
                  opacity: 0.4,
                  width: 1,
                },
                move: {
                  enable: true,
                  speed: 2,
                  direction: "none",
                  random: true,
                  straight: false,
                  out_mode: "out",
                  bounce: false,
                },
              },
              interactivity: {
                detect_on: "canvas",
                events: {
                  onhover: {
                    enable: true,
                    mode: "grab",
                  },
                  onclick: {
                    enable: true,
                    mode: "push",
                  },
                  resize: true,
                },
              },
            }}
          />
        </div>

        <Container maxWidth="sm" className="content-container">
          <Paper elevation={10} className="login-paper">
            {/* Logo de la empresa */}
            <div className="company-logo-container">
              <img
                src={require("./assets/images/logo.png")}
                alt="JABABOX Logo"
                className="company-logo"
              />
            </div>

            <Box className="logo-container" mb={3}>
              <svg
                width="120"
                height="120"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#ffffff"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="logo"
              >
                <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                <circle cx="8.5" cy="7" r="4"></circle>
                <line x1="20" y1="8" x2="20" y2="14"></line>
                <line x1="23" y1="11" x2="17" y2="11"></line>
              </svg>
              <Typography variant="h4" className="title">
                Registro de usuario
              </Typography>
              <Typography variant="subtitle1" style={{ color: "#f0f0f0" }}>
                JABABOX CLOUD
              </Typography>
            </Box>

            <Box className="form-container">
              <TextField
                id="email"
                type="email"
                autoComplete="off"
                name="email"
                value={email}
                onChange={this.onChange}
                label="Email"
                variant="outlined"
                fullWidth
                margin="normal"
                required
                error={email !== "" && !isEmailValid}
                helperText={
                  email !== "" && !isEmailValid
                    ? "Introduce un email válido"
                    : ""
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Email color="primary" />
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                id="username"
                type="text"
                autoComplete="off"
                name="username"
                value={username}
                onChange={this.onChange}
                label="Nombre de usuario"
                variant="outlined"
                fullWidth
                margin="normal"
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Person color="primary" />
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                id="password"
                type={showPassword ? "text" : "password"}
                autoComplete="off"
                name="password"
                value={password}
                onChange={this.onChange}
                label="Contraseña"
                variant="outlined"
                fullWidth
                margin="normal"
                required
                error={password !== "" && !isPasswordValid}
                helperText={
                  password !== "" && !isPasswordValid
                    ? "La contraseña debe tener al menos 6 caracteres"
                    : ""
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock color="primary" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          this.togglePasswordVisibility("password")
                        }
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                id="confirm_password"
                type={showConfirmPassword ? "text" : "password"}
                autoComplete="off"
                name="confirm_password"
                value={confirm_password}
                onChange={this.onChange}
                label="Confirmar contraseña"
                variant="outlined"
                fullWidth
                margin="normal"
                required
                error={confirm_password !== "" && !isPasswordMatch}
                helperText={
                  confirm_password !== "" && !isPasswordMatch
                    ? "Las contraseñas no coinciden"
                    : ""
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock color="primary" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => this.togglePasswordVisibility("confirm")}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Box
                mt={3}
                mb={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Button
                  className="login-button"
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  disabled={!enabled || isLoading}
                  onClick={this.register}
                >
                  {isLoading ? "Registrando..." : "Registro"}
                </Button>
              </Box>

              <Box textAlign="center" mt={2}>
                <Link href="/" className="register-link">
                  ¿Ya tienes cuenta? Accede aquí
                </Link>
              </Box>
            </Box>
          </Paper>

          <Box className="footer" mt={3}>
            <Typography variant="body2">
              © 2023 JABADASH powered by JABABOX. Todos los derechos reservados.
            </Typography>
          </Box>
        </Container>
      </div>
    );
  }
}
