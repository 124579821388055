import React from "react";
import PropTypes from "prop-types";
import { Typography, Box } from "@material-ui/core";
import {
  FaBatteryFull,
  FaBatteryThreeQuarters,
  FaBatteryHalf,
  FaBatteryQuarter,
  FaBatteryEmpty,
} from "react-icons/fa";
import { RiFlashlightFill } from "react-icons/ri";
import { colors, typography } from "../../globalStyles";

const BatteryStatus = ({ value, size = 50, showText = false }) => {
  // Convertir el valor a un número
  const numericValue =
    typeof value === "string"
      ? value === "true"
        ? 100
        : value === "false"
        ? 0
        : parseInt(value, 10) || 0
      : value || 0;

  // Asegurarnos de que el valor está entre 0 y 100
  const safeValue = Math.max(0, Math.min(100, numericValue));

  // Determinar el color basado en el nivel de batería
  const getColor = () => {
    if (safeValue >= 75) return colors.success.text;
    if (safeValue >= 50) return colors.success.text;
    if (safeValue >= 25) return colors.warning.text;
    if (safeValue >= 10) return colors.warning.text;
    return colors.error.text;
  };

  // Determinar el icono a mostrar según el nivel
  const getBatteryIcon = () => {
    if (safeValue >= 75) {
      return <FaBatteryFull size={size} color={getColor()} />;
    } else if (safeValue >= 50) {
      return <FaBatteryThreeQuarters size={size} color={getColor()} />;
    } else if (safeValue >= 25) {
      return <FaBatteryHalf size={size} color={getColor()} />;
    } else if (safeValue >= 10) {
      return <FaBatteryQuarter size={size} color={getColor()} />;
    } else {
      return <FaBatteryEmpty size={size} color={getColor()} />;
    }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      {getBatteryIcon()}

      {showText && (
        <Typography
          variant="body2"
          style={{
            marginTop: "5px",
            color: getColor(),
            fontWeight: typography.fontWeights.medium,
          }}
        >
          {safeValue}%
        </Typography>
      )}
    </Box>
  );
};

BatteryStatus.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.number,
  showText: PropTypes.bool,
};

export default BatteryStatus;
