// Estilos globales para toda la aplicación

// Paleta de colores consistente
export const colors = {
  // Colores primarios
  primary: {
    main: "#3861fb",
    light: "#f0f7ff",
    dark: "#2a4cc9",
    border: "#d0e1fd",
    hover: "#e0efff",
    text: "#3861fb",
  },
  // Colores secundarios
  secondary: {
    main: "#f5a623",
    light: "#fff8e0",
    dark: "#e09410",
    border: "#ffeeba",
    hover: "#fff4d1",
    text: "#f5a623",
  },
  // Colores de éxito
  success: {
    main: "#10b981",
    light: "rgba(52, 211, 153, 0.1)",
    dark: "#0e9f6e",
    border: "rgba(52, 211, 153, 0.2)",
    hover: "rgba(52, 211, 153, 0.2)",
    text: "#10b981",
  },
  // Colores de error
  error: {
    main: "#f86a6a",
    light: "#fff1f0",
    dark: "#e54d4d",
    border: "#ffdcda",
    hover: "#ffe8e6",
    text: "#f86a6a",
  },
  // Colores de información
  info: {
    main: "#3b82f6",
    light: "#f0f7ff",
    dark: "#2563eb",
    border: "#d0e1fd",
    hover: "#e0efff",
    text: "#3b82f6",
  },
  // Colores neutrales
  neutral: {
    main: "#6b7280",
    light: "#f5f7fa",
    dark: "#4b5563",
    border: "#e5e7eb",
    hover: "#ebedf0",
    text: "#6b7280",
    lightText: "#9ca3af",
    background: "#f9fafb",
    white: "#ffffff",
    black: "#111827",
  },
  // Colores de advertencia
  warning: {
    main: "#f59e0b",
    light: "#fffbeb",
    dark: "#d97706",
    border: "#fef3c7",
    hover: "#fef3c7",
    text: "#f59e0b",
  },
  // Colores para estados
  states: {
    online: "#4caf50",
    offline: "#f44336",
    warning: "#ff9800",
    unknown: "#9e9e9e",
  },
};

// Tipografía
export const typography = {
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeights: {
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
  fontSize: {
    xs: "12px",
    sm: "14px",
    base: "16px",
    lg: "18px",
    xl: "20px",
    "2xl": "24px",
    "3xl": "30px",
  },
};

// Espaciado
export const spacing = {
  xs: "4px",
  sm: "8px",
  md: "16px",
  lg: "24px",
  xl: "32px",
  "2xl": "48px",
  "3xl": "64px",
};

// Bordes y sombras
export const borders = {
  radius: {
    sm: "4px",
    md: "6px",
    lg: "8px",
    xl: "12px",
    round: "50%",
    pill: "9999px",
  },
  width: {
    thin: "1px",
    medium: "2px",
    thick: "4px",
  },
};

export const shadows = {
  none: "none",
  sm: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
  md: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
  lg: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
  xl: "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
};

// Transiciones
export const transitions = {
  fast: "all 0.15s ease",
  medium: "all 0.3s ease",
  slow: "all 0.5s ease",
};

// Componentes comunes
export const components = {
  card: {
    container: {
      backgroundColor: colors.neutral.white,
      borderRadius: borders.radius.lg,
      boxShadow: shadows.sm,
      padding: spacing.md,
      transition: transitions.medium,
      border: `${borders.width.thin} solid ${colors.neutral.border}`,
    },
    header: {
      padding: `${spacing.sm} ${spacing.md}`,
      borderBottom: `${borders.width.thin} solid ${colors.neutral.border}`,
      marginBottom: spacing.md,
    },
    title: {
      fontSize: typography.fontSize.lg,
      fontWeight: typography.fontWeights.semibold,
      color: colors.neutral.black,
      margin: 0,
    },
  },
  button: {
    base: {
      padding: `${spacing.sm} ${spacing.md}`,
      borderRadius: borders.radius.md,
      fontWeight: typography.fontWeights.medium,
      fontSize: typography.fontSize.sm,
      textTransform: "none",
      cursor: "pointer",
      transition: transitions.fast,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: spacing.sm,
      boxShadow: "none",
      border: `${borders.width.thin} solid transparent`,
    },
    primary: {
      backgroundColor: colors.primary.light,
      color: colors.primary.text,
      border: `${borders.width.thin} solid ${colors.primary.border}`,
      "&:hover": {
        backgroundColor: colors.primary.hover,
      },
    },
    secondary: {
      backgroundColor: colors.secondary.light,
      color: colors.secondary.text,
      border: `${borders.width.thin} solid ${colors.secondary.border}`,
      "&:hover": {
        backgroundColor: colors.secondary.hover,
      },
    },
    success: {
      backgroundColor: colors.success.light,
      color: colors.success.text,
      border: `${borders.width.thin} solid ${colors.success.border}`,
      "&:hover": {
        backgroundColor: colors.success.hover,
      },
    },
    error: {
      backgroundColor: colors.error.light,
      color: colors.error.text,
      border: `${borders.width.thin} solid ${colors.error.border}`,
      "&:hover": {
        backgroundColor: colors.error.hover,
      },
    },
    neutral: {
      backgroundColor: colors.neutral.light,
      color: colors.neutral.text,
      border: `${borders.width.thin} solid ${colors.neutral.border}`,
      "&:hover": {
        backgroundColor: colors.neutral.hover,
      },
    },
  },
  input: {
    base: {
      padding: `${spacing.sm} ${spacing.md}`,
      borderRadius: borders.radius.md,
      border: `${borders.width.thin} solid ${colors.neutral.border}`,
      fontSize: typography.fontSize.sm,
      backgroundColor: colors.neutral.white,
      transition: transitions.fast,
      width: "100%",
      outline: "none",
      "&:focus": {
        borderColor: colors.primary.main,
        boxShadow: `0 0 0 2px ${colors.primary.light}`,
      },
    },
  },
  badge: {
    base: {
      padding: `${spacing.xs} ${spacing.sm}`,
      borderRadius: borders.radius.pill,
      fontSize: typography.fontSize.xs,
      fontWeight: typography.fontWeights.medium,
      display: "inline-flex",
      alignItems: "center",
    },
    success: {
      backgroundColor: colors.success.light,
      color: colors.success.text,
      border: `${borders.width.thin} solid ${colors.success.border}`,
    },
    error: {
      backgroundColor: colors.error.light,
      color: colors.error.text,
      border: `${borders.width.thin} solid ${colors.error.border}`,
    },
    warning: {
      backgroundColor: colors.warning.light,
      color: colors.warning.text,
      border: `${borders.width.thin} solid ${colors.warning.border}`,
    },
    info: {
      backgroundColor: colors.primary.light,
      color: colors.primary.text,
      border: `${borders.width.thin} solid ${colors.primary.border}`,
    },
  },
  helpText: {
    base: {
      fontSize: typography.fontSize.xs,
      color: colors.neutral.lightText,
      margin: `${spacing.xs} 0 0 0`,
    },
    info: {
      color: colors.primary.text,
    },
    success: {
      color: colors.success.text,
    },
    error: {
      color: colors.error.text,
    },
    warning: {
      color: colors.warning.text,
    },
  },
};

// Layout
export const layout = {
  container: {
    maxWidth: "1200px",
    marginLeft: "auto",
    marginRight: "auto",
    paddingLeft: spacing.lg,
    paddingRight: spacing.lg,
  },
  dashboard: {
    content: {
      padding: spacing.lg,
      backgroundColor: colors.neutral.background,
      minHeight: "calc(100vh - 100px)",
    },
  },
};
