import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Switch,
  FormControlLabel,
  Slider,
  Divider,
  Grid,
  Box,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import {
  FiX,
  FiBell,
  FiInfo,
  FiMail,
  FiPhone,
  FiBattery,
  FiCloud,
  FiWifi,
} from "react-icons/fi";
import { IoIosFlash } from "react-icons/io";
import { FaRegLightbulb } from "react-icons/fa";
import { colors, typography, spacing, borders } from "../../globalStyles";
import api from "../../services/api";
import swal from "sweetalert";

/**
 * Modal para configurar notificaciones de usuario
 */
const NotificationSettingsModal = ({ open, onClose }) => {
  // Estados para los diferentes campos de configuración
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [minBatteryLevel, setMinBatteryLevel] = useState(20);
  const [weatherAlerts, setWeatherAlerts] = useState(true);
  const [predictiveAlerts, setPredictiveAlerts] = useState(true);
  const [routerFailureAlerts, setRouterFailureAlerts] = useState(true);
  const [loading, setLoading] = useState(false);

  // Efecto para cargar la configuración actual del usuario al abrir el modal
  useEffect(() => {
    if (open) {
      // Cargar datos del servidor cuando se abre el modal
      fetchUserNotifications();
    }
  }, [open]);

  // Función para cargar la configuración de notificaciones del usuario
  const fetchUserNotifications = async () => {
    try {
      setLoading(true);
      const response = await api.getUserNotifications();

      if (response && response.success && response.data) {
        // Actualizar el estado con los datos del servidor
        setEmail(response.data.email || "");
        setPhone(response.data.phone || "");
        setMinBatteryLevel(response.data.minBatteryLevel || 20);
        setWeatherAlerts(
          response.data.weatherAlerts !== undefined
            ? response.data.weatherAlerts
            : true
        );
        setPredictiveAlerts(
          response.data.predictiveAlerts !== undefined
            ? response.data.predictiveAlerts
            : true
        );
        setRouterFailureAlerts(
          response.data.routerFailureAlerts !== undefined
            ? response.data.routerFailureAlerts
            : true
        );
      } else {
        // Si no hay datos en el servidor, usar los datos almacenados localmente
        setEmail(localStorage.getItem("notification_email") || "");
        setPhone(localStorage.getItem("notification_phone") || "");
        setMinBatteryLevel(
          parseInt(localStorage.getItem("min_battery_level") || "20")
        );
        setWeatherAlerts(localStorage.getItem("weather_alerts") === "true");
        setPredictiveAlerts(
          localStorage.getItem("predictive_alerts") === "true"
        );
        setRouterFailureAlerts(
          localStorage.getItem("router_failure_alerts") === "true"
        );
      }
    } catch (error) {
      console.error(
        "Error al cargar la configuración de notificaciones:",
        error
      );
      // En caso de error, usar los datos almacenados localmente
      setEmail(localStorage.getItem("notification_email") || "");
      setPhone(localStorage.getItem("notification_phone") || "");
      setMinBatteryLevel(
        parseInt(localStorage.getItem("min_battery_level") || "20")
      );
      setWeatherAlerts(localStorage.getItem("weather_alerts") === "true");
      setPredictiveAlerts(localStorage.getItem("predictive_alerts") === "true");
      setRouterFailureAlerts(
        localStorage.getItem("router_failure_alerts") === "true"
      );
    } finally {
      setLoading(false);
    }
  };

  // Función para guardar la configuración
  const handleSave = () => {
    setLoading(true);

    // Validar email
    if (email && !/\S+@\S+\.\S+/.test(email)) {
      swal({
        text: "Por favor, introduce un email válido",
        icon: "error",
      });
      setLoading(false);
      return;
    }

    // Validar teléfono
    if (phone && !/^\d{9,15}$/.test(phone)) {
      swal({
        text: "Por favor, introduce un número de teléfono válido",
        icon: "error",
      });
      setLoading(false);
      return;
    }

    // Preparar datos para enviar
    const notificationSettings = {
      email,
      phone,
      minBatteryLevel,
      weatherAlerts,
      predictiveAlerts,
      routerFailureAlerts,
    };

    // Guardar en localStorage para persistir entre sesiones
    localStorage.setItem("notification_email", email);
    localStorage.setItem("notification_phone", phone);
    localStorage.setItem("min_battery_level", minBatteryLevel.toString());
    localStorage.setItem("weather_alerts", weatherAlerts.toString());
    localStorage.setItem("predictive_alerts", predictiveAlerts.toString());
    localStorage.setItem(
      "router_failure_alerts",
      routerFailureAlerts.toString()
    );

    // Enviar datos al backend
    api
      .saveUserNotifications(notificationSettings)
      .then((res) => {
        if (res.success) {
          swal({
            text: "Configuración de notificaciones guardada correctamente",
            icon: "success",
          });
          onClose();
        } else {
          // Manejar error de respuesta
          swal({
            text:
              res.message ||
              "Error al guardar la configuración de notificaciones",
            icon: "error",
          });
        }
      })
      .catch((err) => {
        console.error("Error al guardar notificaciones:", err);
        swal({
          text: "Error al guardar la configuración de notificaciones",
          icon: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      aria-labelledby="notification-settings-dialog"
      PaperProps={{
        style: {
          borderRadius: borders.radius.lg,
          overflow: "hidden",
        },
      }}
    >
      <DialogTitle
        id="notification-settings-dialog"
        style={{
          backgroundColor: colors.neutral.light,
          borderBottom: `1px solid ${colors.neutral.border}`,
          padding: spacing.lg,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{ display: "flex", alignItems: "center", gap: spacing.sm }}
          >
            <FiBell size={24} style={{ color: colors.primary.main }} />
            <Typography
              variant="h6"
              style={{ fontWeight: typography.fontWeights.bold }}
            >
              Configuración de Notificaciones
            </Typography>
          </div>
          <IconButton
            aria-label="close"
            onClick={onClose}
            style={{ color: colors.neutral.main }}
          >
            <FiX />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent dividers style={{ padding: spacing.lg }}>
        <Grid container spacing={3}>
          {/* Sección de Contacto */}
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: typography.fontWeights.semibold,
                marginBottom: spacing.md,
                display: "flex",
                alignItems: "center",
                gap: spacing.xs,
                color: colors.neutral.dark,
              }}
            >
              Información de Contacto
            </Typography>

            <TextField
              fullWidth
              label="Email para notificaciones"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FiMail style={{ color: colors.primary.main }} />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              fullWidth
              label="Teléfono para notificaciones"
              variant="outlined"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FiPhone style={{ color: colors.primary.main }} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Divider style={{ margin: `${spacing.md} 0` }} />
          </Grid>

          {/* Configuración de nivel de batería */}
          <Grid item xs={12}>
            <Box
              style={{
                padding: spacing.md,
                backgroundColor: colors.neutral.light,
                borderRadius: borders.radius.md,
                border: `1px solid ${colors.neutral.border}`,
                marginBottom: spacing.md,
              }}
            >
              <Typography
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: spacing.xs,
                  fontWeight: typography.fontWeights.semibold,
                  marginBottom: spacing.sm,
                  color: colors.neutral.dark,
                }}
              >
                <FiBattery
                  style={{
                    color:
                      minBatteryLevel < 30
                        ? colors.error.main
                        : colors.success.main,
                  }}
                />
                Nivel mínimo de batería para alertas
              </Typography>

              <Grid container spacing={2} alignItems="center">
                <Grid item xs>
                  <Slider
                    value={minBatteryLevel}
                    onChange={(_, newValue) => setMinBatteryLevel(newValue)}
                    aria-labelledby="min-battery-level-slider"
                    valueLabelDisplay="auto"
                    step={5}
                    marks
                    min={5}
                    max={50}
                    style={{ color: colors.primary.main }}
                  />
                </Grid>
                <Grid item>
                  <Typography
                    variant="body2"
                    style={{
                      fontWeight: typography.fontWeights.semibold,
                      color:
                        minBatteryLevel < 30
                          ? colors.error.main
                          : colors.success.main,
                      minWidth: "40px",
                      textAlign: "center",
                    }}
                  >
                    {minBatteryLevel}%
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          {/* Tipos de alertas */}
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: typography.fontWeights.semibold,
                marginBottom: spacing.sm,
                color: colors.neutral.dark,
              }}
            >
              Tipos de alertas
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={weatherAlerts}
                      onChange={(e) => setWeatherAlerts(e.target.checked)}
                      color="primary"
                    />
                  }
                  label={
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: spacing.xs,
                      }}
                    >
                      <FiCloud style={{ color: colors.primary.main }} />
                      <span>
                        Alertas por condiciones meteorológicas adversas
                      </span>
                    </div>
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={predictiveAlerts}
                      onChange={(e) => setPredictiveAlerts(e.target.checked)}
                      color="primary"
                    />
                  }
                  label={
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: spacing.xs,
                      }}
                    >
                      <FaRegLightbulb style={{ color: colors.warning.main }} />
                      <span>Alertas predictivas inteligentes</span>
                    </div>
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={routerFailureAlerts}
                      onChange={(e) => setRouterFailureAlerts(e.target.checked)}
                      color="primary"
                    />
                  }
                  label={
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: spacing.xs,
                      }}
                    >
                      <FiWifi style={{ color: colors.error.main }} />
                      <span>Alertas por fallos en el router</span>
                    </div>
                  }
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Box
              style={{
                padding: spacing.md,
                backgroundColor: colors.info,
                borderRadius: borders.radius.md,
                border: `1px solid ${colors.primary.border}`,
                marginTop: spacing.sm,
                display: "flex",
                alignItems: "center",
                gap: spacing.md,
                backgroundColor: colors.primary.light,
              }}
            >
              <FiInfo
                size={20}
                style={{ color: colors.primary.main, flexShrink: 0 }}
              />
              <Typography
                variant="body2"
                style={{ color: colors.primary.dark }}
              >
                Las notificaciones se enviarán cuando se alcancen los umbrales
                configurados. Asegúrate de proporcionar al menos un medio de
                contacto para recibir las alertas.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions
        style={{
          padding: spacing.lg,
          borderTop: `1px solid ${colors.neutral.border}`,
        }}
      >
        <Button
          onClick={onClose}
          style={{
            textTransform: "none",
            color: colors.neutral.main,
          }}
        >
          Cancelar
        </Button>
        <Button
          onClick={handleSave}
          variant="contained"
          disabled={loading}
          style={{
            textTransform: "none",
            backgroundColor: colors.primary.main,
            color: colors.neutral.white,
          }}
        >
          {loading ? "Guardando..." : "Guardar configuración"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

NotificationSettingsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NotificationSettingsModal;
