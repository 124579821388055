import React, { useState, useEffect } from "react";
import {
  Paper,
  Typography,
  Box,
  Grid,
  CircularProgress,
  Tooltip,
  Chip,
  Button,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import { InfoOutlined, ErrorOutline, Refresh } from "@material-ui/icons";
import {
  BsSunFill,
  BsMoonFill,
  BsBarChart,
  BsPlugFill,
  BsSlashCircleFill,
} from "react-icons/bs";
import { FiActivity } from "react-icons/fi";

import * as api from "../../services/api";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    borderRadius: "8px",
    backgroundColor: "#fff",
    boxShadow: "0px 1px 4px rgba(0,0,0,0.05)",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1, 1.5),
    borderBottom: "1px solid #f0f0f0",
  },
  title: {
    fontSize: "1rem",
    fontWeight: 600,
    color: "#6a67ce",
    display: "flex",
    alignItems: "center",
  },
  refreshButton: {
    color: "#6a67ce",
    padding: 6,
    backgroundColor: "#f8f8fc",
  },
  statsContainer: {
    padding: theme.spacing(1.5),
  },
  cardContainer: {
    border: "1px solid #e0e0e0",
    borderRadius: "8px",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  cardHeader: {
    padding: theme.spacing(1),
    borderBottom: "1px solid #f0f0f0",
    backgroundColor: "#f9f9f9",
    display: "flex",
    alignItems: "center",
  },
  cardTitle: {
    fontSize: "0.8rem",
    fontWeight: 600,
    color: "#555",
    marginLeft: theme.spacing(0.5),
  },
  cardContent: {
    padding: theme.spacing(1),
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  statusChip: {
    height: 20,
    fontSize: "0.65rem",
    fontWeight: 600,
  },
  dataBox: {
    textAlign: "center",
    padding: theme.spacing(1),
    borderRadius: "4px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  dataValue: {
    fontSize: "1.5rem",
    fontWeight: 700,
    lineHeight: 1.2,
  },
  dataLabel: {
    fontSize: "0.7rem",
    color: "#666",
    marginTop: 2,
  },
  statusDot: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    display: "inline-block",
    marginRight: 4,
  },
  progressBar: {
    height: 6,
    borderRadius: 3,
    backgroundColor: "#EEEEEE",
    marginTop: 4,
    marginBottom: 8,
    position: "relative",
    overflow: "hidden",
  },
  progressFill: {
    height: "100%",
    position: "absolute",
    left: 0,
    top: 0,
    borderRadius: 3,
  },
  donutContainer: {
    position: "relative",
    width: 90,
    height: 90,
  },
  donutChart: {
    position: "absolute",
    width: "100%",
    height: "100%",
    borderRadius: "50%",
  },
  donutHole: {
    position: "absolute",
    width: "60%",
    height: "60%",
    top: "20%",
    left: "20%",
    borderRadius: "50%",
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  donutText: {
    fontSize: "1.1rem",
    fontWeight: "bold",
  },
  recentAlerts: {
    backgroundColor: "#FFF8E1",
    borderRadius: 4,
    padding: theme.spacing(1),
    marginTop: theme.spacing(1.5),
    border: "1px solid #FFECB3",
  },
  alertRow: {
    display: "flex",
    flexWrap: "wrap",
    gap: 4,
    marginTop: 4,
  },
  alertChip: {
    backgroundColor: "#FFE0B2",
    color: "#E65100",
    height: 22,
    fontSize: "0.65rem",
  },
  dataStatus: {
    display: "flex",
    alignItems: "center",
    fontSize: "0.75rem",
    color: "#2E7D32",
    backgroundColor: "#E8F5E9",
    padding: theme.spacing(0.25, 0.75),
    borderRadius: 12,
  },
  statusIcon: {
    fontSize: "0.8rem",
    marginRight: 4,
  },
  statusText: {
    fontSize: "0.75rem",
  },
  statValueText: {
    fontSize: "0.75rem",
    display: "flex",
    alignItems: "center",
    marginBottom: 2,
  },
  statLabel: {
    flex: 1,
    fontSize: "0.75rem",
    display: "flex",
    alignItems: "center",
  },
  statValue: {
    fontWeight: 600,
    marginLeft: 4,
  },
  batteryRow: {
    marginBottom: 6,
  },
  iconWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "1.2rem",
    marginBottom: 4,
  },
}));

// Componente simplificado de gráfico de dona
const SimpleDonutChart = ({ online, offline, total }) => {
  const classes = useStyles();
  const onlinePercent = (online / total) * 100;

  return (
    <Box className={classes.donutContainer}>
      <Box
        className={classes.donutChart}
        style={{
          background: `conic-gradient(
            #ADE8B4 0% ${onlinePercent}%,
            #FFCDD2 ${onlinePercent}% 100%
          )`,
        }}
      />
      <Box className={classes.donutHole}>
        <Typography className={classes.donutText}>{total}</Typography>
      </Box>
    </Box>
  );
};

const StatisticsPanel = () => {
  const classes = useStyles();
  const [statistics, setStatistics] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(null);

  const fetchStatistics = async () => {
    setLoading(true);
    try {
      const response = await api.getProductStatistics();
      if (response && response.status) {
        setStatistics(response.statistics);
        setLastUpdated(new Date());
      } else {
        setError("No se pudieron cargar las estadísticas");
      }
    } catch (err) {
      setError(
        "Error al cargar los datos: " + (err.message || "Error desconocido")
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStatistics();
  }, []);

  const handleRefresh = () => {
    fetchStatistics();
  };

  // Formato de la última actualización
  const formatLastUpdated = () => {
    if (!lastUpdated) return "Actualizado recientemente";

    const now = new Date();
    const diff = Math.floor((now - lastUpdated) / 1000); // diferencia en segundos

    if (diff < 60) return `Actualizado hace ${diff} segundos`;
    if (diff < 3600) return `Actualizado hace ${Math.floor(diff / 60)} minutos`;
    return `Actualizado hace ${Math.floor(diff / 3600)} horas`;
  };

  if (loading) {
    return (
      <Paper elevation={0} className={classes.root}>
        <Box display="flex" justifyContent="center" alignItems="center" p={2}>
          <CircularProgress size={24} style={{ color: "#6a67ce" }} />
          <Typography variant="body2" style={{ marginLeft: 12, color: "#666" }}>
            Cargando estadísticas...
          </Typography>
        </Box>
      </Paper>
    );
  }

  if (error) {
    return (
      <Paper elevation={0} className={classes.root}>
        <Box
          display="flex"
          alignItems="center"
          p={1.5}
          bgcolor="#FFEBEE"
          borderRadius={1}
        >
          <ErrorOutline
            style={{ color: "#D32F2F", marginRight: 8, fontSize: "1.2rem" }}
          />
          <Typography variant="body2" color="error">
            {error}
          </Typography>
          <Button
            size="small"
            style={{ marginLeft: "auto" }}
            onClick={handleRefresh}
          >
            Reintentar
          </Button>
        </Box>
      </Paper>
    );
  }

  if (!statistics || statistics.total === 0) {
    return null; // No muestra el panel si no hay estadísticas o dispositivos
  }

  return (
    <Paper elevation={0} className={classes.root}>
      {/* Header del panel */}
      <Box className={classes.header}>
        <Typography variant="h6" className={classes.title}>
          <BsBarChart style={{ marginRight: 8 }} />
          Estadísticas del Sistema
        </Typography>
        <Box display="flex" alignItems="center">
          <Tooltip title={formatLastUpdated()}>
            <Box className={classes.dataStatus}>
              <FiActivity className={classes.statusIcon} />
              <Typography className={classes.statusText}>
                Datos en tiempo real
              </Typography>
            </Box>
          </Tooltip>
          <Tooltip title="Actualizar estadísticas">
            <IconButton
              className={classes.refreshButton}
              size="small"
              onClick={handleRefresh}
              style={{ marginLeft: 8 }}
            >
              <Refresh fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      {/* Contenido principal */}
      <Box className={classes.statsContainer}>
        <Grid container spacing={2}>
          {/* ESTADO DE CONEXIÓN */}
          <Grid item xs={12} sm={6} md={3}>
            <Box className={classes.cardContainer}>
              <Box className={classes.cardHeader}>
                <InfoOutlined
                  fontSize="small"
                  style={{ color: "#6a67ce", fontSize: "1rem" }}
                />
                <Typography className={classes.cardTitle}>
                  ESTADO DE CONEXIÓN
                </Typography>
              </Box>

              <Box className={classes.cardContent}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box>
                    <Box display="flex" alignItems="center" mb={1}>
                      <span
                        className={classes.statusDot}
                        style={{ backgroundColor: "#4CAF50" }}
                      />
                      <Typography className={classes.statLabel}>
                        En línea:
                        <Typography
                          component="span"
                          className={classes.statValue}
                          style={{ color: "#4CAF50" }}
                        >
                          {statistics.online}
                        </Typography>
                      </Typography>
                      <Chip
                        size="small"
                        label={`${statistics.onlinePercentage}%`}
                        className={classes.statusChip}
                        style={{
                          backgroundColor: "#E8F5E9",
                          color: "#2E7D32",
                        }}
                      />
                    </Box>

                    <Box display="flex" alignItems="center">
                      <span
                        className={classes.statusDot}
                        style={{ backgroundColor: "#F44336" }}
                      />
                      <Typography className={classes.statLabel}>
                        Desconectados:
                        <Typography
                          component="span"
                          className={classes.statValue}
                          style={{ color: "#F44336" }}
                        >
                          {statistics.offline}
                        </Typography>
                      </Typography>
                      <Chip
                        size="small"
                        label={`${statistics.offlinePercentage}%`}
                        className={classes.statusChip}
                        style={{
                          backgroundColor: "#FFEBEE",
                          color: "#C62828",
                        }}
                      />
                    </Box>
                  </Box>
                  <SimpleDonutChart
                    online={statistics.online}
                    offline={statistics.offline}
                    total={statistics.total}
                  />
                </Box>
              </Box>
            </Box>
          </Grid>

          {/* NIVEL DE BATERÍA */}
          <Grid item xs={12} sm={6} md={3}>
            <Box className={classes.cardContainer}>
              <Box className={classes.cardHeader}>
                <InfoOutlined
                  fontSize="small"
                  style={{ color: "#FF9800", fontSize: "1rem" }}
                />
                <Typography className={classes.cardTitle}>
                  NIVEL DE BATERÍA
                </Typography>
              </Box>

              <Box className={classes.cardContent}>
                {/* Buen estado */}
                <Box className={classes.batteryRow}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography className={classes.statValueText}>
                      Buen estado ({">"}50%)
                    </Typography>
                    <Typography style={{ fontWeight: 600 }}>
                      {statistics.batteryLevels.excellent +
                        statistics.batteryLevels.good}
                    </Typography>
                  </Box>
                  <Box className={classes.progressBar}>
                    <Box
                      className={classes.progressFill}
                      style={{
                        width: `${
                          ((statistics.batteryLevels.excellent +
                            statistics.batteryLevels.good) /
                            statistics.total) *
                          100
                        }%`,
                        backgroundColor: "#4CAF50",
                      }}
                    />
                  </Box>
                </Box>

                {/* Media */}
                <Box className={classes.batteryRow}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography className={classes.statValueText}>
                      Media (21-50%)
                    </Typography>
                    <Typography style={{ fontWeight: 600 }}>
                      {statistics.batteryLevels.medium}
                    </Typography>
                  </Box>
                  <Box className={classes.progressBar}>
                    <Box
                      className={classes.progressFill}
                      style={{
                        width: `${
                          (statistics.batteryLevels.medium / statistics.total) *
                          100
                        }%`,
                        backgroundColor: "#FFC107",
                      }}
                    />
                  </Box>
                </Box>

                {/* Baja */}
                <Box className={classes.batteryRow}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography
                      className={classes.statValueText}
                      style={{
                        color:
                          statistics.lowBattery > 0 ? "#F44336" : "inherit",
                      }}
                    >
                      Baja (20% o menos)
                    </Typography>
                    <Typography
                      style={{
                        fontWeight: 600,
                        color:
                          statistics.lowBattery > 0 ? "#F44336" : "inherit",
                      }}
                    >
                      {statistics.lowBattery}
                    </Typography>
                  </Box>
                  <Box className={classes.progressBar}>
                    <Box
                      className={classes.progressFill}
                      style={{
                        width: `${
                          (statistics.lowBattery / statistics.total) * 100
                        }%`,
                        backgroundColor: "#F44336",
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>

          {/* FUENTES DE ENERGÍA */}
          <Grid item xs={12} sm={6} md={3}>
            <Box className={classes.cardContainer}>
              <Box className={classes.cardHeader}>
                <InfoOutlined
                  fontSize="small"
                  style={{ color: "#2196F3", fontSize: "1rem" }}
                />
                <Typography className={classes.cardTitle}>
                  FUENTES DE ENERGÍA
                </Typography>
              </Box>

              <Box className={classes.cardContent}>
                <Grid container spacing={1} style={{ height: "100%" }}>
                  <Grid item xs={6}>
                    <Box
                      className={classes.dataBox}
                      style={{ backgroundColor: "#E3F2FD" }}
                    >
                      <Box className={classes.iconWrapper}>
                        <BsPlugFill color="#2196F3" />
                      </Box>
                      <Typography
                        className={classes.dataValue}
                        style={{ color: "#2196F3" }}
                      >
                        {statistics.withPowerSupply}
                      </Typography>
                      <Typography className={classes.dataLabel}>
                        Con fuente
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      className={classes.dataBox}
                      style={{ backgroundColor: "#FFEBEE" }}
                    >
                      <Box
                        className={classes.iconWrapper}
                        style={{ position: "relative" }}
                      >
                        <BsPlugFill color="#F44336" />
                        <BsSlashCircleFill
                          size={14}
                          color="#F44336"
                          style={{
                            position: "absolute",
                            right: -5,
                            bottom: -3,
                          }}
                        />
                      </Box>
                      <Typography
                        className={classes.dataValue}
                        style={{ color: "#F44336" }}
                      >
                        {statistics.withoutPowerSupply}
                      </Typography>
                      <Typography className={classes.dataLabel}>
                        Sin fuente
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>

          {/* ENERGÍA SOLAR */}
          <Grid item xs={12} sm={6} md={3}>
            <Box className={classes.cardContainer}>
              <Box className={classes.cardHeader}>
                <InfoOutlined
                  fontSize="small"
                  style={{ color: "#FF9800", fontSize: "1rem" }}
                />
                <Typography className={classes.cardTitle}>
                  ENERGÍA SOLAR
                </Typography>
              </Box>

              <Box className={classes.cardContent}>
                <Grid container spacing={1} style={{ height: "100%" }}>
                  <Grid item xs={6}>
                    <Box
                      className={classes.dataBox}
                      style={{ backgroundColor: "#FFF8E1" }}
                    >
                      <Box className={classes.iconWrapper}>
                        <BsSunFill color="#FF9800" />
                      </Box>
                      <Typography
                        className={classes.dataValue}
                        style={{ color: "#FF9800" }}
                      >
                        {statistics.withSolar}
                      </Typography>
                      <Typography className={classes.dataLabel}>
                        Con sol
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      className={classes.dataBox}
                      style={{ backgroundColor: "#EEEEEE" }}
                    >
                      <Box className={classes.iconWrapper}>
                        <BsMoonFill color="#757575" />
                      </Box>
                      <Typography
                        className={classes.dataValue}
                        style={{ color: "#757575" }}
                      >
                        {statistics.withoutSolar}
                      </Typography>
                      <Typography className={classes.dataLabel}>
                        Sin sol
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>

        {/* Alertas recientes */}
        {statistics.recentlyDisconnected &&
          statistics.recentlyDisconnected.length > 0 && (
            <Box className={classes.recentAlerts}>
              <Box display="flex" alignItems="center">
                <ErrorOutline
                  style={{
                    color: "#E65100",
                    fontSize: "0.9rem",
                    marginRight: 4,
                  }}
                />
                <Typography
                  variant="body2"
                  style={{
                    color: "#E65100",
                    fontWeight: 600,
                    fontSize: "0.75rem",
                  }}
                >
                  Dispositivos desconectados recientemente:
                </Typography>
              </Box>
              <Box className={classes.alertRow}>
                {statistics.recentlyDisconnected.map((device, index) => (
                  <Chip
                    key={index}
                    size="small"
                    label={device.name}
                    className={classes.alertChip}
                  />
                ))}
              </Box>
            </Box>
          )}
      </Box>
    </Paper>
  );
};

export default StatisticsPanel;
