import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
} from "@material-ui/core";

const EditProductModal = ({ open, name, sn, onChange, onClose, onUpdate }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Editar Sistema</DialogTitle>
      <DialogContent>
        <TextField
          id="standard-basic"
          type="text"
          autoComplete="off"
          name="name"
          value={name}
          onChange={onChange}
          placeholder="Nombre sistema"
          required
        />
        <br />
        <TextField
          id="standard-basic"
          type="text"
          autoComplete="off"
          name="sn"
          disabled={true}
          value={sn}
          onChange={onChange}
          placeholder="Número de serie"
          required
        />
        <br />
        <br />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          disabled={name === "" || sn !== sn}
          onClick={onUpdate}
          color="primary"
          autoFocus
        >
          Editar Sistema
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EditProductModal.propTypes = {
  open: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  sn: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default EditProductModal;
