// src/services/api.js
import axios from "axios";
import swal from "sweetalert";

const API_URL = "https://api.jababox.com";

// Obtener token del localStorage
const getToken = () => localStorage.getItem("token");

// Función para manejar errores de forma consistente
const handleError = (error) => {
  if (
    error.response &&
    error.response.data &&
    error.response.data.errorMessage
  ) {
    swal({
      text: error.response.data.errorMessage,
      icon: "error",
      type: "error",
    });
  } else {
    swal({
      text: "Error de conexión con el servidor",
      icon: "error",
      type: "error",
    });
  }
  return Promise.reject(error);
};

/**
 * Obtener productos con paginación y búsqueda
 * @param {number} page - Número de página
 * @param {string} search - Término de búsqueda opcional
 * @returns {Promise} - Promesa con los datos de la respuesta
 */
const getProducts = async (page = 1, search = "") => {
  try {
    let queryString = `?page=${page}`;
    if (search) {
      queryString = `${queryString}&search=${search}`;
    }

    const response = await axios.get(`${API_URL}/get-product${queryString}`, {
      headers: {
        token: getToken(),
      },
    });

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

/**
 * Añadir un nuevo producto
 * @param {Object} productData - Datos del producto a añadir
 * @returns {Promise} - Promesa con los datos de la respuesta
 */
const addProduct = async (productData) => {
  try {
    const formData = new FormData();
    formData.append("name", productData.name);
    formData.append("sn", productData.sn);
    formData.append("verification_code", productData.verification_code);

    // Añadir imagen si está presente
    if (productData.image) {
      formData.append("image", productData.image);
    }

    const response = await axios.post(`${API_URL}/add-product`, formData, {
      headers: {
        "content-type": "multipart/form-data",
        token: getToken(),
      },
    });

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

/**
 * Actualizar un producto existente
 * @param {object} productData - Datos del producto a actualizar
 * @returns {Promise} - Promesa con los datos de la respuesta
 */
const updateProduct = async (productData) => {
  try {
    const formData = new FormData();

    // Añadir el número de serie (campo requerido)
    formData.append("sn", productData.sn);

    // Añadir todos los campos disponibles de forma dinámica
    if (productData.name) formData.append("name", productData.name);
    if (productData.battery) formData.append("battery", productData.battery);
    if (productData.solar) formData.append("solar", productData.solar);
    if (productData.power_supply)
      formData.append("power_supply", productData.power_supply);
    if (productData.lastEvent)
      formData.append("lastEvent", productData.lastEvent);
    if (productData.simOperator)
      formData.append("simOperator", productData.simOperator);
    if (productData.date) formData.append("date", productData.date);
    if (productData.lat) formData.append("lat", productData.lat);
    if (productData.long) formData.append("long", productData.long);

    // Manejar la información del cliente si está presente
    if (productData.costumer_info) {
      formData.append(
        "costumer_info",
        JSON.stringify(productData.costumer_info)
      );
    }

    // Manejar la información del router si está presente
    if (productData.router) {
      console.log("[API] Serializando datos del router:", productData.router);
      formData.append("router", JSON.stringify(productData.router));
    }

    // Imagen (si se proporciona)
    if (productData.image) {
      formData.append("image", productData.image);
    }

    console.log(
      "[API] Enviando datos al servidor para actualizar producto:",
      Object.fromEntries(formData.entries())
    );

    const response = await axios.post(`${API_URL}/update-product`, formData, {
      headers: {
        "content-type": "multipart/form-data",
        token: getToken(),
      },
    });

    console.log("[API] Respuesta del servidor:", response.data);

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

/**
 * Eliminar un producto
 * @param {string} id - ID del producto a eliminar
 * @param {string} mac - Dirección MAC del producto a eliminar
 * @returns {Promise} - Promesa con los datos de la respuesta
 */
const deleteProduct = async (id, mac) => {
  try {
    const response = await axios.post(
      `${API_URL}/delete-product`,
      { id, mac },
      {
        headers: {
          "Content-Type": "application/json",
          token: getToken(),
        },
      }
    );

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

/**
 * Control general de productos vía MQTT
 * @param {string} sn - Número de serie del producto
 * @param {string} action - Acción a realizar
 * @returns {Promise} - Promesa con los datos de la respuesta
 */
const controlProduct = async (sn, action) => {
  try {
    const response = await axios.post(
      `${API_URL}/product-control`,
      {
        sn,
        action,
      },
      {
        headers: {
          "Content-Type": "application/json",
          token: getToken(),
        },
      }
    );

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

/**
 * Obtener producto por número de serie
 * @param {string} sn - Número de serie del producto
 * @returns {Promise} - Promesa con los datos de la respuesta
 */
const getProductBySN = async (sn) => {
  try {
    const response = await axios.post(
      `${API_URL}/get-product-by-sn`,
      { sn },
      {
        headers: {
          "Content-Type": "application/json",
          token: getToken(),
        },
      }
    );

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

/**
 * Control de relé específico
 * @param {string} sn - Número de serie del producto
 * @param {string} relay - Identificador del relé (auxr1, auxr2, mainload)
 * @param {boolean} state - Estado del relé (true = encendido, false = apagado)
 * @returns {Promise} - Promesa con los datos de la respuesta
 */
const controlRelay = async (sn, relay, state) => {
  // Mapear el tipo de relé y su estado a la acción correspondiente
  let action;

  if (relay === "auxr1") {
    action = state ? "auxr1_1" : "auxr1_0";
  } else if (relay === "auxr2") {
    action = state ? "auxr2_1" : "auxr2_0";
  } else if (relay === "mainload") {
    action = state ? "mainload_1" : "mainload_0";
  } else {
    return Promise.reject(new Error("Tipo de relé no válido"));
  }

  return controlProduct(sn, action);
};

/**
 * Reiniciar un dispositivo
 * @param {string} sn - Número de serie del producto
 * @returns {Promise} - Promesa con los datos de la respuesta
 */
const rebootDevice = async (sn) => {
  return controlProduct(sn, "mainrload_reboot");
};

/**
 * Encender un dispositivo
 * @param {string} sn - Número de serie del producto
 * @returns {Promise} - Promesa con los datos de la respuesta
 */
const powerOnDevice = async (sn) => {
  return controlProduct(sn, "mainrload_0");
};

/**
 * Apagar un dispositivo
 * @param {string} sn - Número de serie del producto
 * @returns {Promise} - Promesa con los datos de la respuesta
 */
const powerOffDevice = async (sn) => {
  return controlProduct(sn, "mainrload_1");
};

/**
 * Obtener datos de precipitación
 * @returns {Promise} - Promesa con los datos de la respuesta
 */
const getPrecipitation = async () => {
  try {
    const response = await axios.get(`${API_URL}/get-precipitation`, {
      headers: {
        token: getToken(),
      },
    });

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

/**
 * Iniciar sesión en la plataforma
 * @param {string} username - Nombre de usuario
 * @param {string} password - Contraseña del usuario
 * @returns {Promise} - Promesa con los datos de la respuesta
 */
const login = async (username, password) => {
  try {
    const response = await axios.post(
      `${API_URL}/login`,
      { username, password },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

/**
 * Registrar un nuevo usuario
 * @param {string} username - Nombre de usuario
 * @param {string} password - Contraseña del usuario
 * @returns {Promise} - Promesa con los datos de la respuesta
 */
const register = async (username, password) => {
  try {
    const response = await axios.post(
      `${API_URL}/register`,
      { username, password },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

/**
 * Escanear la red para encontrar dispositivos (para el modal de ProductDetails)
 * @param {string} deviceId - ID del dispositivo para el que se realiza el escaneo
 * @returns {Promise} - Promesa con los datos de la respuesta
 */
const scanNetwork = async (deviceId) => {
  try {
    const response = await axios.post(
      `${API_URL}/scan-network`,
      { deviceId },
      {
        headers: {
          "Content-Type": "application/json",
          token: getToken(),
        },
      }
    );

    return response.data;
  } catch (error) {
    // Para esta función opcional, no mostramos error en UI
    console.error("Error al escanear la red:", error);
    return [];
  }
};

/**
 * Obtener dispositivos conectados a la red (para el modal de ProductDetails)
 * @param {string} deviceId - ID del dispositivo del que se quieren obtener los dispositivos conectados
 * @returns {Promise} - Promesa con los datos de la respuesta
 */
const getConnectedDevices = async (deviceId) => {
  try {
    const response = await axios.post(
      `${API_URL}/get-connected-devices`,
      { deviceId },
      {
        headers: {
          "Content-Type": "application/json",
          token: getToken(),
        },
      }
    );

    return response.data;
  } catch (error) {
    // Para esta función opcional, no mostramos error en UI
    console.error("Error al obtener dispositivos conectados:", error);
    return [];
  }
};

/**
 * Guarda la configuración de notificaciones del usuario
 * @param {Object} notificationSettings - Configuración de notificaciones del usuario
 * @returns {Promise} - Promesa con los datos de la respuesta
 */
const saveUserNotifications = async (notificationSettings) => {
  try {
    // No necesitamos enviar el userId desde aquí, el servidor lo extraerá del token
    const dataToSend = {
      email: notificationSettings.email,
      phone: notificationSettings.phone,
      minBatteryLevel: notificationSettings.minBatteryLevel,
      weatherAlerts: notificationSettings.weatherAlerts,
      predictiveAlerts: notificationSettings.predictiveAlerts,
      routerFailureAlerts: notificationSettings.routerFailureAlerts,
    };

    const response = await axios.post(
      `${API_URL}/user-notifications`,
      dataToSend,
      {
        headers: {
          "Content-Type": "application/json",
          token: getToken(),
        },
      }
    );

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

/**
 * Obtiene la configuración de notificaciones del usuario
 * @returns {Promise} - Promesa con los datos de la respuesta
 */
const getUserNotifications = async () => {
  try {
    const response = await axios.get(`${API_URL}/user-notifications`, {
      headers: {
        token: getToken(),
      },
    });

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

/**
 * Obtiene todos los datos del router Cudy
 * @param {Object} routerConfig - Configuración del router (host, username, password)
 * @returns {Promise} - Promesa con los datos de la respuesta
 */
const getRouterCudyData = async (routerConfig) => {
  try {
    const response = await axios.post(
      `${API_URL}/router-cudy/data`,
      routerConfig,
      {
        headers: {
          "Content-Type": "application/json",
          token: getToken(),
        },
      }
    );

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

/**
 * Obtiene solo la información del módem del router Cudy
 * @param {Object} routerConfig - Configuración del router (host, username, password)
 * @returns {Promise} - Promesa con los datos de la respuesta
 */
const getRouterCudyModem = async (routerConfig) => {
  try {
    const response = await axios.post(
      `${API_URL}/router-cudy/modem`,
      routerConfig,
      {
        headers: {
          "Content-Type": "application/json",
          token: getToken(),
        },
      }
    );

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

/**
 * Obtiene la lista de dispositivos conectados al router Cudy
 * @param {Object} routerConfig - Configuración del router (host, username, password)
 * @returns {Promise} - Promesa con los datos de la respuesta
 */
const getRouterCudyDevices = async (routerConfig) => {
  try {
    const response = await axios.post(
      `${API_URL}/router-cudy/devices`,
      routerConfig,
      {
        headers: {
          "Content-Type": "application/json",
          token: getToken(),
        },
      }
    );

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

/**
 * Obtiene la IP asignada a un dispositivo en la red ZeroTier
 * @param {string} deviceId - ID del dispositivo en ZeroTier
 * @returns {Promise} - Promesa con la información del dispositivo y su IP
 */
const getZeroTierDeviceIp = async (deviceId) => {
  try {
    const response = await axios.get(
      `${API_URL}/zerotier/device-ip/${deviceId}`,
      {
        headers: {
          token: getToken(),
        },
      }
    );
    return response.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
};

/**
 * Obtiene estadísticas globales de los productos del usuario
 * @returns {Promise} - Promesa con los datos de la respuesta
 */
const getProductStatistics = async () => {
  try {
    const response = await axios.get(`${API_URL}/product-statistics`, {
      headers: {
        token: getToken(),
      },
    });

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

/**
 * Obtiene la configuración de servicios del usuario
 * @returns {Promise} - Promesa con los datos de la respuesta
 */
const getUserServices = async () => {
  try {
    const response = await axios.get(`${API_URL}/user-services`, {
      headers: {
        token: getToken(),
      },
    });

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

/**
 * Actualiza la configuración de un servicio específico del usuario
 * @param {string} serviceName - Nombre del servicio a actualizar (jabatrack, timelapse, etc.)
 * @param {Object} data - Datos del servicio a actualizar (enabled, url)
 * @returns {Promise} - Promesa con los datos de la respuesta
 */
const updateUserService = async (serviceName, data) => {
  try {
    const response = await axios.post(
      `${API_URL}/user-services/${serviceName}`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          token: getToken(),
        },
      }
    );

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

// Crear un objeto con todas las funciones
const api = {
  getProducts,
  addProduct,
  updateProduct,
  deleteProduct,
  controlProduct,
  getProductBySN,
  controlRelay,
  rebootDevice,
  powerOnDevice,
  powerOffDevice,
  getPrecipitation,
  login,
  register,
  scanNetwork,
  getConnectedDevices,
  saveUserNotifications,
  getUserNotifications,
  getRouterCudyData,
  getRouterCudyModem,
  getRouterCudyDevices,
  getZeroTierDeviceIp,
  getProductStatistics,
  getUserServices,
  updateUserService,
};

// Exportar cada función individualmente para poder importarlas con destructuring
export {
  getProducts,
  addProduct,
  updateProduct,
  deleteProduct,
  controlProduct,
  getProductBySN,
  controlRelay,
  rebootDevice,
  powerOnDevice,
  powerOffDevice,
  getPrecipitation,
  login,
  register,
  scanNetwork,
  getConnectedDevices,
  saveUserNotifications,
  getUserNotifications,
  getRouterCudyData,
  getRouterCudyModem,
  getRouterCudyDevices,
  getZeroTierDeviceIp,
  getProductStatistics,
  getUserServices,
  updateUserService,
};

// Exportar todas las funciones como exportación por defecto
export default api;
