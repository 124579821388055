import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Typography,
  Box,
  Paper,
  Divider,
  Switch,
  IconButton,
  Tooltip,
  Chip,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { RiDirectionLine } from "react-icons/ri";
import { BsArrowsAngleExpand } from "react-icons/bs";
import { BiRefresh } from "react-icons/bi";
import {
  FiSettings,
  FiX,
  FiInfo,
  FiTrendingUp,
  FiWifi,
  FiWifiOff,
  FiGlobe,
  FiSmartphone,
  FiHardDrive,
} from "react-icons/fi";
import { IoIosFlash } from "react-icons/io";
import { AiOutlineAreaChart } from "react-icons/ai";
import { MdPower, MdPowerOff } from "react-icons/md";
import {
  FaBatteryThreeQuarters,
  FaSolarPanel,
  FaPlug,
  FaThermometerHalf,
  FaNetworkWired,
  FaInfoCircle,
  FaLocationArrow,
  FaChartLine,
  FaHistory,
  FaWifi,
  FaDesktop,
  FaSearch,
  FaQuestionCircle,
  FaEthernet,
  FaExclamationTriangle,
  FaBatteryFull,
  FaBatteryQuarter,
  FaChargingStation,
  FaAd,
  FaCalendarDay,
  FaSun,
  FaClock,
  FaBolt,
  FaAngleUp,
  FaAngleDown,
  FaHdd,
  FaMapMarkerAlt,
  FaExternalLinkAlt,
  FaCloudSun,
  FaCloudSunRain,
  FaServer,
  FaRegLightbulb,
  FaGlobe,
} from "react-icons/fa";
import ChangeHighlight from "react-change-highlight";
import LiveIndicator from "../../LiveIndicator";
import MapComponent from "../Map/MapComponent";
import BatteryStatus from "../Status/BatteryStatus";
import SolarStatus from "../Status/SolarStatus";
import PowerSupplyStatus from "../Status/PowerSupplyStatus";
import StatusIndicatorTrue from "../../StatusIndicatorTrue";
import StatusIndicatorFalse from "../../StatusIndicatorFalse";
import { openGoogleMaps } from "../../utils/helpers";
import * as api from "../../services/api";
import {
  colors,
  typography,
  spacing,
  shadows,
  borders,
  transitions,
} from "../../globalStyles";
import HistoryDataLog from "../../HistoryDataLog";

// Estilos definidos como objetos JavaScript para usar en style={{}}
const styles = {
  dialog: {
    "& .MuiDialog-paper": {
      maxWidth: "900px",
      width: "100%",
      borderRadius: borders.radius.lg,
      overflow: "hidden",
    },
  },
  header: {
    background: colors.neutral.white,
    color: colors.neutral.dark,
    padding: `${spacing.md} ${spacing.lg}`,
    position: "relative",
    borderBottom: `1px solid ${colors.neutral.border}`,
  },
  headerContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  headerTitle: {
    display: "flex",
    alignItems: "center",
    gap: spacing.sm,
  },
  titleText: {
    fontWeight: typography.fontWeights.bold,
    fontSize: typography.fontSize["xl"],
    margin: 0,
    color: colors.neutral.black,
  },
  closeButton: {
    color: colors.neutral.main,
    position: "absolute",
    right: spacing.md,
    top: spacing.md,
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
  content: {
    padding: `${spacing.lg} ${spacing.lg} ${spacing.md} ${spacing.lg}`,
    backgroundColor: colors.neutral.background,
  },
  card: {
    padding: spacing.md,
    borderRadius: borders.radius.md,
    boxShadow: shadows.sm,
    backgroundColor: colors.neutral.white,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    border: `1px solid ${colors.neutral.border}`,
  },
  cardHeader: {
    display: "flex",
    alignItems: "center",
    marginBottom: spacing.sm,
    padding: `0 0 ${spacing.sm} 0`,
    borderBottom: `1px solid ${colors.neutral.border}`,
  },
  cardIcon: {
    marginRight: spacing.sm,
    color: colors.primary.main,
    fontSize: typography.fontSize.xl,
  },
  cardTitle: {
    fontWeight: typography.fontWeights.semibold,
    fontSize: typography.fontSize.lg,
    color: colors.neutral.dark,
    flex: 1,
  },
  cardContent: {
    flex: 1,
  },
  sectionTitle: {
    fontWeight: typography.fontWeights.semibold,
    fontSize: typography.fontSize.lg,
    marginTop: spacing.sm,
    marginBottom: spacing.sm,
    color: colors.neutral.dark,
    paddingBottom: spacing.sm,
    borderBottom: "none",
  },
  sectionContainer: {
    marginBottom: spacing.lg,
    paddingTop: spacing.sm,
  },
  sectionDivider: {
    height: "1px",
    backgroundColor: colors.neutral.border,
    margin: `${spacing.md} 0`,
    opacity: 0.5,
    width: "100%",
  },
  infoLabel: {
    color: colors.neutral.main,
    fontSize: typography.fontSize.sm,
    fontWeight: typography.fontWeights.medium,
    marginBottom: spacing.xs,
  },
  infoValue: {
    color: colors.neutral.dark,
    fontSize: typography.fontSize.base,
    fontWeight: typography.fontWeights.semibold,
    marginBottom: spacing.sm,
  },
  statusBadge: {
    padding: `${spacing.xs} ${spacing.sm}`,
    borderRadius: borders.radius.pill,
    fontSize: typography.fontSize.xs,
    fontWeight: typography.fontWeights.medium,
    display: "inline-flex",
    alignItems: "center",
    gap: spacing.xs,
  },
  onlineStatus: {
    backgroundColor: colors.success.light,
    color: colors.success.text,
    border: `1px solid ${colors.success.border}`,
  },
  offlineStatus: {
    backgroundColor: colors.error.light,
    color: colors.error.text,
    border: `1px solid ${colors.error.border}`,
  },
  mapContainer: {
    height: "250px",
    borderRadius: borders.radius.md,
    overflow: "hidden",
    border: `1px solid ${colors.neutral.border}`,
  },
  helpText: {
    fontSize: typography.fontSize.xs,
    color: colors.neutral.lightText,
    margin: `${spacing.xs} 0 0 0`,
    display: "flex",
    alignItems: "center",
    gap: spacing.xs,
  },
  controlButton: {
    marginRight: spacing.sm,
    marginBottom: spacing.sm,
    textTransform: "none",
    fontWeight: typography.fontWeights.medium,
  },
  primaryButton: {
    backgroundColor: colors.primary.light,
    color: colors.primary.text,
    border: `1px solid ${colors.primary.border}`,
    "&:hover": {
      backgroundColor: colors.primary.hover,
    },
  },
  warningButton: {
    backgroundColor: colors.warning.light,
    color: colors.warning.text,
    border: `1px solid ${colors.warning.border}`,
    "&:hover": {
      backgroundColor: colors.warning.hover,
    },
  },
  errorButton: {
    backgroundColor: colors.error.light,
    color: colors.error.text,
    border: `1px solid ${colors.error.border}`,
    "&:hover": {
      backgroundColor: colors.error.hover,
    },
  },
  switchContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: `${spacing.sm} ${spacing.md}`,
    borderRadius: borders.radius.md,
    backgroundColor: colors.neutral.light,
    marginBottom: spacing.sm,
    border: `1px solid ${colors.neutral.border}`,
  },
  switchLabel: {
    fontSize: typography.fontSize.sm,
    fontWeight: typography.fontWeights.medium,
    color: colors.neutral.dark,
  },
  switchStatus: {
    display: "flex",
    alignItems: "center",
    gap: spacing.sm,
  },
  divider: {
    margin: `${spacing.md} 0`,
  },
  graphSection: {
    marginTop: spacing.xl,
    marginBottom: spacing.xl,
  },
  graphContainer: {
    padding: spacing.md,
    borderRadius: borders.radius.md,
    boxShadow: shadows.sm,
    backgroundColor: colors.neutral.white,
    height: "350px",
    border: `1px solid ${colors.neutral.border}`,
    overflow: "hidden",
  },
  graphHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: spacing.md,
  },
  graphTitle: {
    display: "flex",
    alignItems: "center",
    gap: spacing.xs,
    fontWeight: typography.fontWeights.semibold,
    fontSize: typography.fontSize.md,
    color: colors.neutral.dark,
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    padding: `${spacing.sm} ${spacing.lg} ${spacing.lg} ${spacing.lg}`,
    backgroundColor: colors.neutral.background,
  },
  expandButton: {
    color: colors.primary.main,
    fontSize: "18px",
  },
};

const ProductDetailsModal = ({
  open,
  product,
  isLive,
  onClose,
  onRefresh,
  onOpenGraph,
  onPowerAuxr1,
  onPowerAuxr2,
  onPowerMainload,
  onScanNetwork,
  onGetConnectedDevices,
}) => {
  // Estado para guardar los dispositivos conectados y resultados de escaneo
  const [connectedDevices, setConnectedDevices] = React.useState([]);
  const [isScanning, setIsScanning] = React.useState(false);
  const [scanResults, setScanResults] = React.useState([]);
  // Estado para controlar el diálogo de bienvenida
  const [welcomeDialogOpen, setWelcomeDialogOpen] = React.useState(false);

  // Estados para la integración del router Cudy
  const [routerHost, setRouterHost] = React.useState("172.26.246.95");
  const [routerUsername, setRouterUsername] = React.useState("admin");
  const [routerPassword, setRouterPassword] = React.useState("admin1604");
  const [isLoadingRouter, setIsLoadingRouter] = React.useState(false);
  const [routerData, setRouterData] = React.useState(null);
  const [routerDevices, setRouterDevices] = React.useState([]);
  const [routerError, setRouterError] = React.useState(null);
  const [showRouterSection, setShowRouterSection] = React.useState(false);
  const [showRouterDevices, setShowRouterDevices] = React.useState(false);
  const [showRawData, setShowRawData] = React.useState(false);
  const [showDebugSection, setShowDebugSection] = React.useState(false);

  // Actualizar los campos del formulario cuando el producto tenga información de router
  React.useEffect(() => {
    if (product && product.router) {
      // Si el producto tiene router configurado, cargar sus datos
      if (product.router.ip) setRouterHost(product.router.ip);
      if (product.router.username) setRouterUsername(product.router.username);
      if (product.router.password) setRouterPassword(product.router.password);

      // Si el router está conectado, cargar automáticamente los datos
      if (product.router.connected && product.router.ip) {
        // Solo cargar automáticamente si es la primera vez que se abre el modal
        if (!routerData) {
          console.log(
            "Cargando automáticamente datos del router vinculado:",
            product.router.ip
          );
          setTimeout(() => {
            handleGetRouterData();
          }, 500); // Pequeño retraso para asegurar que los estados se han actualizado
        }
      }
    }
  }, [product, routerData]);

  if (!product) {
    return null;
  }

  const handleLocationClick = () => {
    // Verificar diferentes formatos posibles para latitud y longitud
    let latitude = null;
    let longitude = null;

    // Intentar acceder a las propiedades directamente
    if (product.lat && product.long) {
      latitude = product.lat;
      longitude = product.long;
    }

    // Intentar acceder a coordenadas dentro de un objeto ubicacion
    else if (product.ubicacion && typeof product.ubicacion === "object") {
      if (product.ubicacion.lat && product.ubicacion.long) {
        latitude = product.ubicacion.lat;
        longitude = product.ubicacion.long;
      } else if (product.ubicacion.latitude && product.ubicacion.longitude) {
        latitude = product.ubicacion.latitude;
        longitude = product.ubicacion.longitude;
      }
    }

    console.log("Coordenadas para mapa:", {
      directas: { lat: product.lat, long: product.long },
      ubicacion: product.ubicacion,
      resultado: { latitude, longitude },
      product: JSON.stringify(product),
    });

    if (latitude && longitude) {
      openGoogleMaps(latitude, longitude);
    } else {
      alert("No hay coordenadas disponibles para este dispositivo.");
    }
  };

  const getStatusText = (status) => {
    // Si status es un objeto con propiedad estado
    if (status && typeof status === "object" && "estado" in status) {
      if (
        status.estado === "true" ||
        status.estado === true ||
        status.estado === "1" ||
        status.estado === 1
      ) {
        return "En línea";
      }
      if (
        status.estado === "false" ||
        status.estado === false ||
        status.estado === "0" ||
        status.estado === 0
      ) {
        return "Desconectado";
      }
    }

    // Si status es un objeto con propiedad status
    if (status && typeof status === "object" && "status" in status) {
      if (
        status.status === "true" ||
        status.status === true ||
        status.status === "1" ||
        status.status === 1
      ) {
        return "En línea";
      }
      if (
        status.status === "false" ||
        status.status === false ||
        status.status === "0" ||
        status.status === 0
      ) {
        return "Desconectado";
      }
    }

    // Manejar diferentes formatos de estado como valor directo
    if (status === "1" || status === 1 || status === "true" || status === true)
      return "En línea";
    if (
      status === "0" ||
      status === 0 ||
      status === "false" ||
      status === false
    )
      return "Desconectado";
    if (status === "Desconocido" || status === "desconocido")
      return "Desconectado";

    // Si es un string que podría tener "online" u "offline" dentro
    if (typeof status === "string") {
      const lowerStatus = status.toLowerCase();
      if (lowerStatus.includes("online") || lowerStatus.includes("en línea"))
        return "En línea";
      if (
        lowerStatus.includes("offline") ||
        lowerStatus.includes("desconectado")
      )
        return "Desconectado";
    }

    // Si llega aquí, el estado es realmente desconocido
    console.log("Estado no reconocido:", status);
    return "Desconectado";
  };

  const getStatusStyle = (status) => {
    // Si status es un objeto con propiedad estado
    if (status && typeof status === "object" && "estado" in status) {
      if (
        status.estado === "true" ||
        status.estado === true ||
        status.estado === "1" ||
        status.estado === 1
      ) {
        return styles.onlineStatus;
      }
    }

    // Si status es un objeto con propiedad status
    if (status && typeof status === "object" && "status" in status) {
      if (
        status.status === "true" ||
        status.status === true ||
        status.status === "1" ||
        status.status === 1
      ) {
        return styles.onlineStatus;
      }
    }

    // Usar la misma lógica que getStatusText para determinar si está en línea
    if (
      status === "1" ||
      status === 1 ||
      status === "true" ||
      status === true
    ) {
      return styles.onlineStatus;
    }

    // Si es un string que podría tener "online" dentro
    if (typeof status === "string") {
      const lowerStatus = status.toLowerCase();
      if (lowerStatus.includes("online") || lowerStatus.includes("en línea")) {
        return styles.onlineStatus;
      }
    }

    // Por defecto, consideramos que está desconectado
    return styles.offlineStatus;
  };

  const formatDate = (dateString) => {
    if (!dateString) return "No disponible";
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(dateString).toLocaleDateString("es-ES", options);
  };

  // Función para manejar el escaneo de red
  const handleNetworkScan = async () => {
    if (onScanNetwork) {
      setIsScanning(true);
      try {
        const results = await onScanNetwork(product.id);
        setScanResults(results || []);
      } catch (error) {
        console.error("Error al escanear la red:", error);
      } finally {
        setIsScanning(false);
      }
    }
  };

  // Función para obtener los dispositivos conectados
  const handleGetConnectedDevices = async () => {
    if (onGetConnectedDevices) {
      try {
        const devices = await onGetConnectedDevices(product.id);
        setConnectedDevices(devices || []);
      } catch (error) {
        console.error("Error al obtener dispositivos conectados:", error);
      }
    }
  };

  // Función para obtener todos los datos del router Cudy
  const handleGetRouterData = async () => {
    setIsLoadingRouter(true);
    setRouterError(null);

    try {
      const routerConfig = {
        host: routerHost,
        username: routerUsername,
        password: routerPassword,
      };

      console.log("Conectando al router con:", routerConfig);
      const response = await api.getRouterCudyData(routerConfig);

      // Siempre mostrar la respuesta completa en la consola para ayudar a depurar
      console.log("Respuesta completa del router:", response);

      if (response && response.success) {
        // Guardar toda la respuesta para poder mostrarla para depuración
        setRouterData(response.data || response);
        console.log(
          "Datos del router guardados en estado:",
          response.data || response
        );

        // Ya no forzamos mostrar los datos brutos por defecto
        // setShowRawData(true);
      } else {
        console.error("Respuesta sin éxito:", response);
        setRouterError(
          (response && response.errorMessage) ||
            "Error al obtener datos del router"
        );
      }
    } catch (error) {
      console.error("Error en try/catch:", error);
      setRouterError(
        "Error de conexión con el router: " +
          (error.message || "Error desconocido")
      );
    } finally {
      setIsLoadingRouter(false);
    }
  };

  // Función para conectar usando los datos guardados del router
  const handleConnectWithSavedData = () => {
    if (product && product.router && product.router.ip) {
      // Restaurar los valores guardados
      setRouterHost(product.router.ip);
      setRouterUsername(product.router.username || "admin");
      setRouterPassword(product.router.password || "admin1604");

      // Pequeño retraso para asegurar que los estados se han actualizado
      setTimeout(() => {
        handleGetRouterData();
      }, 100);
    } else {
      setRouterError("No hay datos de router guardados para este producto");
    }
  };

  // Función para obtener solo los dispositivos conectados al router Cudy
  const handleGetRouterDevices = async () => {
    setIsLoadingRouter(true);
    setRouterError(null);

    try {
      const routerConfig = {
        host: routerHost,
        username: routerUsername,
        password: routerPassword,
      };

      const response = await api.getRouterCudyDevices(routerConfig);

      if (response.success) {
        setRouterDevices(response.data || []);
        console.log("Dispositivos del router:", response.data);
      } else {
        setRouterError(
          response.errorMessage || "Error al obtener dispositivos del router"
        );
        console.error("Error en la respuesta:", response);
      }
    } catch (error) {
      setRouterError("Error de conexión con el router");
      console.error("Error al obtener dispositivos del router:", error);
    } finally {
      setIsLoadingRouter(false);
    }
  };

  // Función para mostrar/ocultar la sección del router
  const toggleRouterSection = () => {
    setShowRouterSection(!showRouterSection);
  };

  // Función para abrir el diálogo de bienvenida
  const handleOpenWelcomeDialog = () => {
    setWelcomeDialogOpen(true);
  };

  // Función para cerrar el diálogo de bienvenida
  const handleCloseWelcomeDialog = () => {
    setWelcomeDialogOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      style={styles.dialog}
    >
      {/* Encabezado del Modal */}
      <div style={styles.header}>
        <IconButton
          style={styles.closeButton}
          onClick={onClose}
          aria-label="cerrar"
        >
          <FiX />
        </IconButton>

        <div style={styles.headerContent}>
          <div style={styles.headerTitle}>
            <Typography variant="h4" style={styles.titleText}>
              {product.name || "Dispositivo"}
            </Typography>

            <Chip
              label={
                <Box display="flex" alignItems="center">
                  <span
                    style={{
                      display: "inline-block",
                      width: "8px",
                      height: "8px",
                      borderRadius: "50%",
                      backgroundColor:
                        getStatusText(product.status) === "En línea"
                          ? colors.states.online
                          : colors.states.offline,
                      marginRight: "6px",
                    }}
                  ></span>
                  {getStatusText(product.status)}
                </Box>
              }
              style={{
                ...styles.statusBadge,
                ...getStatusStyle(product.status),
                fontWeight: typography.fontWeights.medium,
              }}
            />

            {isLive && (
              <Box ml={1} display="flex" alignItems="center">
                <Typography
                  variant="body2"
                  style={{
                    color: colors.neutral.lightText,
                    marginRight: spacing.xs,
                  }}
                >
                  En vivo
                </Typography>
                <LiveIndicator />
              </Box>
            )}
          </div>

          <Box>
            {/* Botón de ayuda para abrir la welcome page */}
            <Tooltip title="Ayuda">
              <IconButton
                aria-label="help"
                onClick={handleOpenWelcomeDialog}
                style={{
                  color: colors.primary.main,
                }}
              >
                <FaQuestionCircle />
              </IconButton>
            </Tooltip>
            <Tooltip title="Actualizar datos">
              <IconButton
                color="default"
                onClick={onRefresh}
                style={{ color: colors.primary.main }}
              >
                <BiRefresh />
              </IconButton>
            </Tooltip>
            <Tooltip title="Cerrar">
              <IconButton
                color="default"
                onClick={onClose}
                style={{ color: colors.primary.main }}
              ></IconButton>
            </Tooltip>
          </Box>
        </div>

        <Typography
          variant="body2"
          style={{ color: colors.neutral.lightText, marginTop: spacing.xs }}
        >
          ID del sistema: {product.sn || "No disponible"}
        </Typography>
      </div>

      {/* Contenido principal */}
      <DialogContent
        style={{
          padding: `${spacing.lg} ${spacing.lg} ${spacing.md} ${spacing.lg}`,
          backgroundColor: colors.neutral.background,
        }}
      >
        {/* Información general y mapa */}
        <div style={styles.sectionContainer}>
          <Typography variant="h6" style={styles.sectionTitle}>
            Información general y mapa
          </Typography>

          <Grid container spacing={3}>
            {/* Información general */}
            <Grid item xs={12} md={6}>
              <Paper elevation={0} style={styles.card}>
                <div style={styles.cardHeader}>
                  <FaInfoCircle style={styles.cardIcon} />
                  <Typography variant="h6" style={styles.cardTitle}>
                    Información General
                  </Typography>
                </div>

                <div style={styles.cardContent}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="body2" style={styles.infoLabel}>
                        Nombre del sistema
                      </Typography>
                      <Typography variant="body1" style={styles.infoValue}>
                        {product.name || "No asignado"}
                      </Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography variant="body2" style={styles.infoLabel}>
                        Número de serie
                      </Typography>
                      <Typography variant="body1" style={styles.infoValue}>
                        {product.sn || "No disponible"}
                      </Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography variant="body2" style={styles.infoLabel}>
                        Estado actual
                      </Typography>
                      <div
                        style={{
                          ...styles.statusBadge,
                          ...getStatusStyle(product.status),
                          display: "inline-flex",
                          marginBottom: spacing.sm,
                          padding: "6px 12px",
                          fontSize: typography.fontSize.sm,
                        }}
                      >
                        {getStatusText(product.status) === "En línea" ? (
                          <>
                            <span
                              style={{
                                display: "inline-block",
                                width: "8px",
                                height: "8px",
                                borderRadius: "50%",
                                backgroundColor: colors.states.online,
                                marginRight: "6px",
                              }}
                            ></span>
                            En línea
                          </>
                        ) : (
                          <>
                            <span
                              style={{
                                display: "inline-block",
                                width: "8px",
                                height: "8px",
                                borderRadius: "50%",
                                backgroundColor: colors.states.offline,
                                marginRight: "6px",
                              }}
                            ></span>
                            Desconectado
                          </>
                        )}
                      </div>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography variant="body2" style={styles.infoLabel}>
                        Última conexión
                      </Typography>
                      <Typography variant="body1" style={styles.infoValue}>
                        {formatDate(product.createdAt)}
                      </Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography variant="body2" style={styles.infoLabel}>
                        Operador SIM
                      </Typography>
                      <Typography variant="body1" style={styles.infoValue}>
                        {product.simOperator || "No disponible"}
                      </Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography variant="body2" style={styles.infoLabel}>
                        Código de verificación
                      </Typography>
                      <Typography variant="body1" style={styles.infoValue}>
                        {product.verification_code || "No disponible"}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </Paper>
            </Grid>

            {/* Mapa de ubicación */}
            <Grid item xs={12} md={6}>
              <Paper elevation={0} style={styles.card}>
                <div style={styles.cardHeader}>
                  <FaLocationArrow style={styles.cardIcon} />
                  <Typography variant="h6" style={styles.cardTitle}>
                    Ubicación del dispositivo
                  </Typography>
                  <Tooltip title="Ver en Google Maps">
                    <span>
                      <IconButton
                        size="small"
                        color="primary"
                        onClick={handleLocationClick}
                        disabled={(() => {
                          // Verificar coordenadas para determinar si el botón debe estar deshabilitado
                          if (product.lat && product.long) return false;
                          if (
                            product.ubicacion &&
                            product.ubicacion.lat &&
                            product.ubicacion.long
                          )
                            return false;
                          if (
                            product.ubicacion &&
                            product.ubicacion.latitude &&
                            product.ubicacion.longitude
                          )
                            return false;
                          return true;
                        })()}
                      >
                        <RiDirectionLine />
                      </IconButton>
                    </span>
                  </Tooltip>
                </div>

                <div style={styles.cardContent}>
                  <div style={styles.mapContainer}>
                    {(() => {
                      // Verificar diferentes formatos posibles para latitud y longitud
                      let latitude = null;
                      let longitude = null;

                      // Intentar acceder a las propiedades directamente
                      if (product.lat && product.long) {
                        latitude = product.lat;
                        longitude = product.long;
                      }

                      // Intentar acceder a coordenadas dentro de un objeto ubicacion
                      else if (
                        product.ubicacion &&
                        typeof product.ubicacion === "object"
                      ) {
                        if (product.ubicacion.lat && product.ubicacion.long) {
                          latitude = product.ubicacion.lat;
                          longitude = product.ubicacion.long;
                        } else if (
                          product.ubicacion.latitude &&
                          product.ubicacion.longitude
                        ) {
                          latitude = product.ubicacion.latitude;
                          longitude = product.ubicacion.longitude;
                        }
                      }

                      console.log("Renderizando mapa con coordenadas:", {
                        latitude,
                        longitude,
                      });

                      if (latitude && longitude) {
                        return (
                          <MapComponent
                            lat={latitude}
                            lng={longitude}
                            name={product.name}
                          />
                        );
                      } else {
                        return (
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            height="100%"
                            bgcolor={colors.neutral.light}
                          >
                            <Typography variant="body2" color="textSecondary">
                              Ubicación no disponible
                            </Typography>
                          </Box>
                        );
                      }
                    })()}
                  </div>

                  <Box mt={1} display="flex" justifyContent="space-between">
                    <Typography variant="body2" style={styles.helpText}>
                      <FiInfo size={14} />
                      Haz clic en el ícono para abrir en Google Maps
                    </Typography>

                    <Typography
                      variant="body2"
                      style={{ ...styles.helpText, textAlign: "right" }}
                    >
                      {(() => {
                        // Reutilizar la lógica de detección de coordenadas
                        let latitude = null;
                        let longitude = null;

                        // Intentar acceder a las propiedades directamente
                        if (product.lat && product.long) {
                          latitude = product.lat;
                          longitude = product.long;
                        }
                        // Intentar acceder a coordenadas dentro de un objeto ubicacion
                        else if (
                          product.ubicacion &&
                          typeof product.ubicacion === "object"
                        ) {
                          if (product.ubicacion.lat && product.ubicacion.long) {
                            latitude = product.ubicacion.lat;
                            longitude = product.ubicacion.long;
                          } else if (
                            product.ubicacion.latitude &&
                            product.ubicacion.longitude
                          ) {
                            latitude = product.ubicacion.latitude;
                            longitude = product.ubicacion.longitude;
                          }
                        }

                        return `Lat: ${latitude || "N/A"}, Long: ${
                          longitude || "N/A"
                        }`;
                      })()}
                    </Typography>
                  </Box>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </div>

        {/* Divisor visual */}
        <div style={styles.sectionDivider}></div>

        {/* Estado y métricas */}
        <div style={styles.sectionContainer}>
          <Typography variant="h6" style={styles.sectionTitle}>
            Estado del sistema
          </Typography>

          <Grid container spacing={3}>
            {/* Batería */}
            <Grid item xs={12} sm={6} md={3}>
              <Paper elevation={0} style={styles.card}>
                <div style={styles.cardHeader}>
                  <FaBatteryThreeQuarters style={styles.cardIcon} />
                  <Typography variant="subtitle1" style={styles.cardTitle}>
                    Batería
                  </Typography>
                </div>

                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  style={styles.cardContent}
                >
                  <ChangeHighlight>
                    <BatteryStatus value={product.battery} size={80} />
                  </ChangeHighlight>

                  <Typography
                    variant="h5"
                    style={{
                      marginTop: spacing.md,
                      fontWeight: typography.fontWeights.bold,
                      color:
                        parseInt(product.battery) > 25
                          ? colors.success.text
                          : parseInt(product.battery) > 10
                          ? colors.warning.text
                          : colors.error.text,
                    }}
                  >
                    {typeof product.battery === "string" &&
                    product.battery === "false"
                      ? "0"
                      : typeof product.battery === "string" &&
                        product.battery === "true"
                      ? "100"
                      : product.battery || "0"}
                    %
                  </Typography>

                  <Typography variant="body2" style={styles.helpText}>
                    <FiInfo size={14} />
                    Nivel de carga de la batería
                  </Typography>
                </Box>
              </Paper>
            </Grid>

            {/* Solar */}
            <Grid item xs={12} sm={6} md={3}>
              <Paper elevation={0} style={styles.card}>
                <div style={styles.cardHeader}>
                  <FaSolarPanel style={styles.cardIcon} />
                  <Typography variant="subtitle1" style={styles.cardTitle}>
                    Panel Solar
                  </Typography>
                </div>

                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  style={styles.cardContent}
                >
                  <ChangeHighlight>
                    <SolarStatus value={product.solar} size={80} />
                  </ChangeHighlight>

                  <Typography
                    variant="h5"
                    style={{
                      marginTop: spacing.md,
                      fontWeight: typography.fontWeights.bold,
                      color:
                        product.solar === "true" || parseInt(product.solar) > 50
                          ? "#f59e0b" // Amarillo
                          : "#6b7280", // Gris
                    }}
                  >
                    {product.solar === "true"
                      ? "Activo"
                      : product.solar === "false"
                      ? "Inactivo"
                      : typeof product.solar === "string" &&
                        !isNaN(parseInt(product.solar))
                      ? `${product.solar}%`
                      : "Sin datos"}
                  </Typography>

                  <Typography variant="body2" style={styles.helpText}>
                    <FiInfo size={14} />
                    Estado del panel solar
                  </Typography>
                </Box>
              </Paper>
            </Grid>

            {/* Fuente de alimentación */}
            <Grid item xs={12} sm={6} md={3}>
              <Paper elevation={0} style={styles.card}>
                <div style={styles.cardHeader}>
                  <FaPlug style={styles.cardIcon} />
                  <Typography variant="subtitle1" style={styles.cardTitle}>
                    Fuente de alimentación
                  </Typography>
                </div>

                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  style={styles.cardContent}
                >
                  <ChangeHighlight>
                    <PowerSupplyStatus value={product.power_supply} size={80} />
                  </ChangeHighlight>

                  <Typography
                    variant="h5"
                    style={{
                      marginTop: spacing.md,
                      fontWeight: typography.fontWeights.bold,
                      color:
                        product.power_supply === "true" ||
                        parseInt(product.power_supply) > 50
                          ? colors.success.text
                          : colors.error.text,
                    }}
                  >
                    {product.power_supply === "true"
                      ? "Conectada"
                      : product.power_supply === "false"
                      ? "Desconectada"
                      : typeof product.power_supply === "string" &&
                        !isNaN(parseInt(product.power_supply))
                      ? `${product.power_supply}%`
                      : "Sin datos"}
                  </Typography>

                  <Typography variant="body2" style={styles.helpText}>
                    <FiInfo size={14} />
                    Estado de la alimentación externa
                  </Typography>
                </Box>
              </Paper>
            </Grid>

            {/* Temperatura */}
            <Grid item xs={12} sm={6} md={3}>
              <Paper elevation={0} style={styles.card}>
                <div style={styles.cardHeader}>
                  <FaThermometerHalf style={styles.cardIcon} />
                  <Typography variant="subtitle1" style={styles.cardTitle}>
                    Temperatura
                  </Typography>
                </div>

                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  style={styles.cardContent}
                >
                  <ChangeHighlight>
                    <Typography
                      variant="h3"
                      style={{
                        color:
                          product.temp &&
                          !isNaN(parseInt(product.temp)) &&
                          parseInt(product.temp) > 40
                            ? colors.error.text
                            : colors.success.text,
                        fontWeight: typography.fontWeights.bold,
                        marginTop: spacing.md,
                      }}
                    >
                      {product.temp && !isNaN(parseInt(product.temp))
                        ? `${product.temp}°C`
                        : "N/A"}
                    </Typography>
                  </ChangeHighlight>

                  <Typography
                    variant="body2"
                    style={{
                      ...styles.statusBadge,
                      ...(product.temp &&
                      !isNaN(parseInt(product.temp)) &&
                      parseInt(product.temp) > 40
                        ? styles.offlineStatus
                        : styles.onlineStatus),
                      marginTop: spacing.md,
                    }}
                  >
                    {product.temp &&
                    !isNaN(parseInt(product.temp)) &&
                    parseInt(product.temp) > 40
                      ? "¡Temperatura alta!"
                      : "Normal"}
                  </Typography>

                  <Typography variant="body2" style={styles.helpText}>
                    <FiInfo size={14} />
                    Temperatura actual del sistema
                  </Typography>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </div>

        {/* Divisor visual */}
        <div style={styles.sectionDivider}></div>

        {/* Controles del sistema */}
        <div style={styles.sectionContainer}>
          <Typography variant="h6" style={styles.sectionTitle}>
            Controles del sistema
          </Typography>

          <Paper elevation={0} style={styles.card}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="subtitle1"
                  style={{
                    fontWeight: typography.fontWeights.semibold,
                    marginBottom: spacing.sm,
                    color: colors.neutral.dark,
                  }}
                >
                  Relés de control
                </Typography>

                <div style={styles.switchContainer}>
                  <Typography variant="body2" style={styles.switchLabel}>
                    Auxiliar 1 (AUX1)
                  </Typography>
                  <div style={styles.switchStatus}>
                    <Switch
                      checked={product.auxr1 === "1"}
                      onChange={() => onPowerAuxr1(product)}
                      color="primary"
                    />
                    <Typography variant="body2">
                      {product.auxr1 === "1" ? "Encendido" : "Apagado"}
                    </Typography>
                  </div>
                </div>

                <div style={styles.switchContainer}>
                  <Typography variant="body2" style={styles.switchLabel}>
                    Auxiliar 2 (AUX2)
                  </Typography>
                  <div style={styles.switchStatus}>
                    <Switch
                      checked={product.auxr2 === "1"}
                      onChange={() => onPowerAuxr2(product)}
                      color="primary"
                    />
                    <Typography variant="body2">
                      {product.auxr2 === "1" ? "Encendido" : "Apagado"}
                    </Typography>
                  </div>
                </div>

                <div style={styles.switchContainer}>
                  <Typography variant="body2" style={styles.switchLabel}>
                    Carga principal
                  </Typography>
                  <div style={styles.switchStatus}>
                    <Switch
                      checked={product.mainload === "1"}
                      onChange={() => onPowerMainload(product)}
                      color="primary"
                    />
                    <Typography variant="body2">
                      {product.mainload === "1" ? "Encendido" : "Apagado"}
                    </Typography>
                  </div>
                </div>

                <Typography variant="body2" style={styles.helpText}>
                  <FiInfo size={14} />
                  Estos controles permiten encender/apagar los diferentes relés
                  del sistema
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography
                  variant="subtitle1"
                  style={{
                    fontWeight: typography.fontWeights.semibold,
                    marginBottom: spacing.sm,
                    color: colors.neutral.dark,
                  }}
                >
                  Acciones del sistema
                </Typography>

                <Box>
                  <Button
                    variant="outlined"
                    style={{ ...styles.controlButton, ...styles.primaryButton }}
                    startIcon={<BiRefresh />}
                    onClick={onRefresh}
                  >
                    Actualizar datos
                  </Button>

                  <Button
                    variant="outlined"
                    style={{ ...styles.controlButton, ...styles.primaryButton }}
                    startIcon={<AiOutlineAreaChart />}
                    onClick={() => onOpenGraph(product)}
                  >
                    Ver gráficos
                  </Button>

                  <Button
                    variant="outlined"
                    style={{ ...styles.controlButton, ...styles.warningButton }}
                    startIcon={<FiSettings />}
                    onClick={() =>
                      alert("Configuración avanzada no disponible")
                    }
                  >
                    Configuración avanzada
                  </Button>

                  <Button
                    variant="outlined"
                    style={{ ...styles.controlButton, ...styles.errorButton }}
                    startIcon={<MdPowerOff />}
                    onClick={() => alert("Reinicio del sistema no disponible")}
                  >
                    Reiniciar sistema
                  </Button>
                </Box>

                <Typography
                  variant="body2"
                  style={{ ...styles.helpText, marginTop: spacing.md }}
                >
                  <FiInfo size={14} />
                  Utiliza estos botones para realizar acciones sobre el sistema
                  completo
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </div>

        {/* Divisor visual */}
        <div style={styles.sectionDivider}></div>

        {/* Sección de gráficos históricos */}
        {product && product.history && product.history.length > 0 && (
          <div style={styles.sectionContainer}>
            <Typography variant="h6" style={styles.sectionTitle}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: spacing.xs,
                }}
              >
                <FaChartLine size={18} style={{ color: colors.primary.main }} />
                <span>Datos históricos del sistema</span>
              </div>
            </Typography>

            <Paper
              elevation={0}
              style={{
                ...styles.card,
                height: "auto",
                padding: `${spacing.md} ${spacing.md} ${spacing.xl} ${spacing.md}`,
              }}
            >
              <div
                style={{
                  height: "550px",
                  width: "100%",
                  overflow: "visible",
                  marginBottom: spacing.xl,
                  paddingBottom: spacing.xl,
                }}
              >
                <HistoryDataLog historyData={product.history} />
              </div>

              <Typography
                variant="body2"
                style={{ ...styles.helpText, marginTop: spacing.md }}
              >
                <FiInfo size={14} />
                Este gráfico muestra el histórico de datos de batería,
                suministro de energía y solar del sistema, permitiéndote
                analizar tendencias y comportamientos a lo largo del tiempo.
              </Typography>
            </Paper>
          </div>
        )}

        {/* Nueva sección para gestión de router */}
        <div style={styles.sectionContainer}>
          <Typography variant="h6" style={styles.sectionTitle}>
            <div
              style={{ display: "flex", alignItems: "center", gap: spacing.xs }}
            >
              <FaWifi size={18} style={{ color: colors.primary.main }} />
              <span>Gestión de Router</span>
            </div>
          </Typography>

          <Paper elevation={0} style={{ ...styles.card, height: "auto" }}>
            {/* Información general del router si existe */}
            {product.router && product.router.id && (
              <div style={{ marginBottom: spacing.md }}>
                <Box
                  bgcolor={colors.neutral.light}
                  padding={2}
                  borderRadius={borders.radius.md}
                  marginBottom={spacing.md}
                >
                  <Typography
                    variant="subtitle1"
                    style={{
                      fontWeight: typography.fontWeights.semibold,
                      marginBottom: spacing.sm,
                    }}
                  >
                    <FaInfoCircle
                      size={16}
                      style={{
                        marginRight: spacing.xs,
                        verticalAlign: "middle",
                      }}
                    />
                    Router JABABOX vinculado
                  </Typography>

                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography
                        variant="body2"
                        style={{ color: colors.neutral.dark }}
                      >
                        <strong>ID de ZeroTier:</strong>{" "}
                        {product.router.zerotier_id || product.router.id}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography
                        variant="body2"
                        style={{ color: colors.neutral.dark }}
                      >
                        <strong>Dirección IP:</strong>{" "}
                        {product.router.zerotier_ip || product.router.ip}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography
                        variant="body2"
                        style={{ color: colors.neutral.dark }}
                      >
                        <strong>Estado:</strong>{" "}
                        {product.router.connected ? (
                          <Chip
                            size="small"
                            label="Conectado"
                            style={{
                              backgroundColor: colors.success.light,
                              color: colors.success.main,
                            }}
                          />
                        ) : (
                          <Chip
                            size="small"
                            label="Desconectado"
                            style={{
                              backgroundColor: colors.error.light,
                              color: colors.error.main,
                            }}
                          />
                        )}
                      </Typography>
                    </Grid>
                    {product.router.lastCheck && (
                      <Grid item xs={12}>
                        <Typography
                          variant="body2"
                          style={{ color: colors.neutral.dark }}
                        >
                          <strong>Última verificación:</strong>{" "}
                          {formatDate(product.router.lastCheck)}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </div>
            )}

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: spacing.md,
              }}
            >
              {/* Sección Router Cudy */}
              <div style={{ width: "100%" }}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={toggleRouterSection}
                  style={{
                    textTransform: "none",
                    marginBottom: spacing.md,
                  }}
                >
                  {showRouterSection
                    ? "Ocultar Router Cudy"
                    : "Mostrar Router Cudy"}
                </Button>

                {product.router && product.router.ip && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleConnectWithSavedData}
                    disabled={isLoadingRouter}
                    style={{
                      textTransform: "none",
                      marginBottom: spacing.md,
                      marginLeft: spacing.md,
                    }}
                  >
                    {isLoadingRouter ? (
                      <React.Fragment>
                        <CircularProgress
                          size={20}
                          color="inherit"
                          style={{ marginRight: 8 }}
                        />
                        Conectando...
                      </React.Fragment>
                    ) : (
                      "Conectar con datos guardados"
                    )}
                  </Button>
                )}

                {showRouterSection && (
                  <Paper
                    elevation={0}
                    style={{
                      padding: spacing.md,
                      border: `1px solid ${colors.neutral.border}`,
                      borderRadius: borders.radius.md,
                      marginBottom: spacing.md,
                    }}
                  >
                    <Typography
                      variant="h6"
                      style={{
                        fontWeight: typography.fontWeights.semibold,
                        marginBottom: spacing.md,
                      }}
                    >
                      Configuración del Router Cudy
                    </Typography>

                    {product.router && product.router.ip && (
                      <Box
                        bgcolor={colors.info.light}
                        color={colors.info.main}
                        padding={1}
                        borderRadius={borders.radius.sm}
                        marginBottom={2}
                      >
                        <Typography variant="body2">
                          <FaInfoCircle size={14} style={{ marginRight: 8 }} />
                          Este producto ya tiene un router vinculado. Los campos
                          están prellenados con los datos guardados.
                        </Typography>
                      </Box>
                    )}

                    <Grid
                      container
                      spacing={2}
                      style={{ marginBottom: spacing.md }}
                    >
                      <Grid item xs={12} sm={4}>
                        <TextField
                          label="IP del Router"
                          variant="outlined"
                          fullWidth
                          size="small"
                          value={routerHost}
                          onChange={(e) => setRouterHost(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          label="Usuario"
                          variant="outlined"
                          fullWidth
                          size="small"
                          value={routerUsername}
                          onChange={(e) => setRouterUsername(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          label="Contraseña"
                          variant="outlined"
                          fullWidth
                          size="small"
                          type="password"
                          value={routerPassword}
                          onChange={(e) => setRouterPassword(e.target.value)}
                        />
                      </Grid>
                    </Grid>

                    <Box
                      display="flex"
                      justifyContent="space-between"
                      marginBottom={2}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleGetRouterData}
                        disabled={isLoadingRouter}
                        style={{
                          textTransform: "none",
                        }}
                      >
                        {isLoadingRouter ? (
                          <React.Fragment>
                            <CircularProgress
                              size={20}
                              color="inherit"
                              style={{ marginRight: 8 }}
                            />
                            Cargando...
                          </React.Fragment>
                        ) : (
                          "Obtener datos del router"
                        )}
                      </Button>

                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleGetRouterDevices}
                        disabled={isLoadingRouter}
                        style={{
                          textTransform: "none",
                        }}
                      >
                        Ver dispositivos del router
                      </Button>
                    </Box>

                    {routerError && (
                      <Box
                        bgcolor={colors.error.light}
                        color={colors.error.main}
                        padding={1}
                        borderRadius={borders.radius.sm}
                        marginBottom={2}
                      >
                        <Typography variant="body2">{routerError}</Typography>
                      </Box>
                    )}

                    {/* Resultados de la conexión al router */}
                    {routerData && (
                      <Box
                        bgcolor={colors.success.light}
                        padding={2}
                        borderRadius={borders.radius.md}
                        marginTop={2}
                      >
                        <Typography
                          variant="subtitle1"
                          style={{
                            fontWeight: typography.fontWeights.semibold,
                            color: colors.success.main,
                            marginBottom: spacing.sm,
                          }}
                        >
                          <FaWifi size={16} style={{ marginRight: 8 }} />
                          Conexión establecida con el router
                        </Typography>

                        {/* Log para depuración */}
                        <div style={{ display: "none" }}>
                          <pre>{JSON.stringify(routerData, null, 2)}</pre>
                        </div>

                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={4}>
                            <Typography
                              variant="body2"
                              style={{ color: colors.neutral.dark }}
                            >
                              <strong>Modelo:</strong>{" "}
                              {routerData.router?.model || "Cudy"}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <Typography
                              variant="body2"
                              style={{ color: colors.neutral.dark }}
                            >
                              <strong>Internet:</strong>{" "}
                              {routerData.internet?.connected ? (
                                <span style={{ color: colors.success.main }}>
                                  Conectado
                                </span>
                              ) : (
                                <span style={{ color: colors.error.main }}>
                                  Desconectado
                                </span>
                              )}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <Typography
                              variant="body2"
                              style={{ color: colors.neutral.dark }}
                            >
                              <strong>Dispositivos conectados:</strong>{" "}
                              {routerData.devices
                                ? routerData.devices.length
                                : "0"}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Button
                          variant="outlined"
                          size="small"
                          color="primary"
                          onClick={() => setShowRouterDevices((prev) => !prev)}
                          style={{
                            textTransform: "none",
                            marginTop: spacing.md,
                            marginRight: spacing.md,
                          }}
                        >
                          {showRouterDevices
                            ? "Ocultar detalles"
                            : "Ver más detalles"}
                        </Button>

                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => setShowRawData((prev) => !prev)}
                          style={{
                            textTransform: "none",
                            marginTop: spacing.md,
                          }}
                        >
                          {showRawData
                            ? "Ocultar datos brutos"
                            : "Mostrar datos brutos"}
                        </Button>

                        {/* Mostrar datos brutos para depuración */}
                        {showRawData && (
                          <Box
                            marginTop={2}
                            padding={2}
                            border={`1px solid ${colors.neutral.border}`}
                            borderRadius={borders.radius.sm}
                            bgcolor={colors.neutral.lightest}
                            maxHeight="300px"
                            overflow="auto"
                          >
                            <Typography
                              variant="caption"
                              style={{
                                whiteSpace: "pre-wrap",
                                fontFamily: "monospace",
                              }}
                            >
                              {JSON.stringify(routerData, null, 2)}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    )}

                    {/* Detalles adicionales del router */}
                    {routerData && showRouterDevices && (
                      <Box
                        marginTop={2}
                        padding={2}
                        borderRadius={borders.radius.md}
                        border={`1px solid ${colors.neutral.border}`}
                      >
                        {/* Información del módem */}
                        {routerData.modem && (
                          <div style={{ marginBottom: spacing.md }}>
                            <Typography
                              variant="subtitle2"
                              style={{
                                fontWeight: typography.fontWeights.semibold,
                                marginBottom: spacing.xs,
                              }}
                            >
                              <FaGlobe size={14} style={{ marginRight: 8 }} />
                              Información del Módem
                            </Typography>

                            <Grid container spacing={1}>
                              <Grid item xs={12} sm={6}>
                                <Typography variant="body2">
                                  <strong>Estado:</strong>{" "}
                                  {routerData.modem?.status || "No disponible"}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <Typography variant="body2">
                                  <strong>Tipo:</strong>{" "}
                                  {routerData.modem?.type || "No disponible"}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <Typography variant="body2">
                                  <strong>IP WAN:</strong>{" "}
                                  {routerData.modem?.wan_ip ||
                                    routerData.internet?.interface ||
                                    "No disponible"}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <Typography variant="body2">
                                  <strong>Señal:</strong>{" "}
                                  {routerData.modem?.signal || "No disponible"}
                                </Typography>
                              </Grid>
                            </Grid>
                          </div>
                        )}

                        {/* Lista de dispositivos */}
                        {routerData.devices &&
                          routerData.devices.length > 0 && (
                            <div style={{ marginBottom: spacing.md }}>
                              <Typography
                                variant="subtitle2"
                                style={{
                                  fontWeight: typography.fontWeights.semibold,
                                  marginBottom: spacing.xs,
                                }}
                              >
                                <FaDesktop
                                  size={14}
                                  style={{ marginRight: 8 }}
                                />
                                Dispositivos Conectados (
                                {routerData.devices.length})
                              </Typography>

                              <Box
                                maxHeight="200px"
                                overflow="auto"
                                border={`1px solid ${colors.neutral.border}`}
                                borderRadius={borders.radius.sm}
                              >
                                <table
                                  style={{
                                    width: "100%",
                                    borderCollapse: "collapse",
                                  }}
                                >
                                  <thead>
                                    <tr
                                      style={{
                                        backgroundColor: colors.neutral.light,
                                      }}
                                    >
                                      <th
                                        style={{
                                          padding: "8px",
                                          textAlign: "left",
                                        }}
                                      >
                                        Nombre
                                      </th>
                                      <th
                                        style={{
                                          padding: "8px",
                                          textAlign: "left",
                                        }}
                                      >
                                        IP
                                      </th>
                                      <th
                                        style={{
                                          padding: "8px",
                                          textAlign: "left",
                                        }}
                                      >
                                        MAC
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {routerData.devices.map((device, index) => (
                                      <tr
                                        key={index}
                                        style={{
                                          backgroundColor:
                                            index % 2 === 0
                                              ? "white"
                                              : colors.neutral.lightest,
                                        }}
                                      >
                                        <td style={{ padding: "8px" }}>
                                          {device.name ||
                                            "Dispositivo " + (index + 1)}
                                        </td>
                                        <td style={{ padding: "8px" }}>
                                          {device.ip || "N/A"}
                                        </td>
                                        <td style={{ padding: "8px" }}>
                                          {device.mac || "N/A"}
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </Box>
                            </div>
                          )}
                      </Box>
                    )}
                  </Paper>
                )}
              </div>
            </div>

            {/* Sección de depuración siempre visible si hay datos del router */}
            {routerData && (
              <div style={{ marginTop: spacing.md, marginBottom: spacing.md }}>
                {showDebugSection && (
                  <Paper
                    elevation={0}
                    style={{
                      padding: spacing.md,
                      border: `1px solid ${
                        colors.warning.border || colors.warning.main
                      }`,
                      borderRadius: borders.radius.md,
                      backgroundColor: colors.warning.light,
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{
                        color: colors.warning.main,
                        marginBottom: spacing.sm,
                        fontWeight: typography.fontWeights.semibold,
                      }}
                    >
                      <FaExclamationTriangle
                        size={16}
                        style={{ marginRight: 8 }}
                      />
                      Depuración de Datos del Router
                    </Typography>

                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="body2">
                          <strong>Datos recibidos:</strong>{" "}
                          {routerData ? "Sí" : "No"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body2">
                          <strong>Tipo de datos:</strong> {typeof routerData}
                        </Typography>
                      </Grid>
                      {routerData && typeof routerData === "object" && (
                        <Grid item xs={12}>
                          <Typography variant="body2">
                            <strong>Propiedades:</strong>{" "}
                            {Object.keys(routerData).join(", ")}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>

                    <Box
                      marginTop={2}
                      padding={2}
                      border={`1px solid ${colors.neutral.border}`}
                      borderRadius={borders.radius.sm}
                      bgcolor={colors.neutral.lightest}
                      maxHeight="200px"
                      overflow="auto"
                    >
                      <Typography
                        variant="caption"
                        style={{
                          whiteSpace: "pre-wrap",
                          fontFamily: "monospace",
                        }}
                      >
                        {JSON.stringify(routerData, null, 2)}
                      </Typography>
                    </Box>
                  </Paper>
                )}
              </div>
            )}

            {/* Resultados del escaneo de red */}
            {scanResults.length > 0 && (
              <div style={{ marginTop: spacing.md, marginBottom: spacing.md }}>
                <Typography
                  variant="subtitle1"
                  style={{
                    fontWeight: typography.fontWeights.semibold,
                    marginBottom: spacing.sm,
                  }}
                >
                  <FaNetworkWired
                    size={16}
                    style={{ marginRight: spacing.xs, verticalAlign: "middle" }}
                  />
                  Direcciones IP encontradas
                </Typography>

                <div
                  style={{
                    maxHeight: "200px",
                    overflowY: "auto",
                    border: `1px solid ${colors.neutral.border}`,
                    borderRadius: borders.radius.sm,
                    padding: spacing.sm,
                  }}
                >
                  <table style={{ width: "100%", borderCollapse: "collapse" }}>
                    <thead>
                      <tr
                        style={{
                          borderBottom: `1px solid ${colors.neutral.border}`,
                        }}
                      >
                        <th
                          style={{
                            textAlign: "left",
                            padding: spacing.sm,
                            fontSize: typography.fontSize.sm,
                          }}
                        >
                          Dirección IP
                        </th>
                        <th
                          style={{
                            textAlign: "left",
                            padding: spacing.sm,
                            fontSize: typography.fontSize.sm,
                          }}
                        >
                          Estado
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {scanResults.map((ip, index) => (
                        <tr
                          key={index}
                          style={{
                            borderBottom:
                              index < scanResults.length - 1
                                ? `1px solid ${colors.neutral.borderLight}`
                                : "none",
                          }}
                        >
                          <td
                            style={{
                              padding: spacing.sm,
                              fontSize: typography.fontSize.sm,
                            }}
                          >
                            {ip.address}
                          </td>
                          <td
                            style={{
                              padding: spacing.sm,
                              fontSize: typography.fontSize.sm,
                            }}
                          >
                            <Chip
                              size="small"
                              label={ip.active ? "Activo" : "Inactivo"}
                              style={{
                                backgroundColor: ip.active
                                  ? colors.success.light
                                  : colors.neutral.light,
                                color: ip.active
                                  ? colors.success.text
                                  : colors.neutral.dark,
                                fontSize: typography.fontSize.xs,
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}

            {/* Lista de dispositivos conectados */}
            {connectedDevices.length > 0 && (
              <div style={{ marginTop: spacing.md }}>
                <Typography
                  variant="subtitle1"
                  style={{
                    fontWeight: typography.fontWeights.semibold,
                    marginBottom: spacing.sm,
                  }}
                >
                  <FaDesktop
                    size={16}
                    style={{ marginRight: spacing.xs, verticalAlign: "middle" }}
                  />
                  Dispositivos conectados
                </Typography>

                <div
                  style={{
                    maxHeight: "200px",
                    overflowY: "auto",
                    border: `1px solid ${colors.neutral.border}`,
                    borderRadius: borders.radius.sm,
                    padding: spacing.sm,
                  }}
                >
                  <Box
                    border={`1px solid ${colors.neutral.border}`}
                    borderRadius={borders.radius.sm}
                    marginBottom={1.5}
                    overflow="hidden"
                    boxShadow={`0 2px 4px rgba(0,0,0,0.05)`}
                  >
                    <table
                      style={{ width: "100%", borderCollapse: "collapse" }}
                    >
                      <thead
                        style={{
                          backgroundColor: colors.neutral.background,
                          borderBottom: `2px solid ${colors.primary.light}`,
                        }}
                      >
                        <tr>
                          <th
                            style={{
                              textAlign: "left",
                              padding: "14px 16px",
                              fontSize: typography.fontSize.sm,
                              fontWeight: typography.fontWeights.semibold,
                              color: colors.neutral.text,
                            }}
                          >
                            Nombre
                          </th>
                          <th
                            style={{
                              textAlign: "left",
                              padding: "14px 16px",
                              fontSize: typography.fontSize.sm,
                              fontWeight: typography.fontWeights.semibold,
                              color: colors.neutral.text,
                            }}
                          >
                            IP
                          </th>
                          <th
                            style={{
                              textAlign: "left",
                              padding: "14px 16px",
                              fontSize: typography.fontSize.sm,
                              fontWeight: typography.fontWeights.semibold,
                              color: colors.neutral.text,
                            }}
                          >
                            MAC
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {connectedDevices.map((device, index) => (
                          <tr
                            key={index}
                            style={{
                              borderBottom:
                                index < connectedDevices.length - 1
                                  ? `1px solid ${colors.neutral.borderLight}`
                                  : "none",
                            }}
                          >
                            <td
                              style={{
                                padding: "14px 16px",
                                fontSize: typography.fontSize.sm,
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <FaEthernet
                                  size={16}
                                  style={{
                                    marginRight: 8,
                                    color:
                                      index === 0
                                        ? "#4287f5"
                                        : index === 1
                                        ? "#42b5f5"
                                        : "#428ef5",
                                    opacity: 0.9,
                                  }}
                                />
                                {device.name || "Dispositivo desconocido"}
                              </div>
                            </td>
                            <td
                              style={{
                                padding: "14px 16px",
                                fontSize: typography.fontSize.sm,
                                fontFamily: typography.fontFamily.mono,
                              }}
                            >
                              {device.ip}
                            </td>
                            <td
                              style={{
                                padding: "14px 16px",
                                fontSize: typography.fontSize.sm,
                                fontFamily: typography.fontFamily.mono,
                              }}
                            >
                              {device.mac}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Box>
                </div>
              </div>
            )}

            {/* Mostrar información del router */}
            {routerData && (
              <div style={{ marginTop: spacing.md, marginBottom: spacing.md }}>
                {/* Cabecera con información básica del router */}
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  bgcolor={colors.neutral.background}
                  padding={2}
                  borderRadius={borders.radius.sm}
                  marginBottom={1.5}
                >
                  <div>
                    <Typography
                      variant="subtitle1"
                      style={{ fontWeight: typography.fontWeights.bold }}
                    >
                      Router {routerData.router.model}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      IP: {routerData.router.ip}
                    </Typography>
                  </div>
                  <Box
                    display="flex"
                    alignItems="center"
                    bgcolor={
                      routerData.internet.connected
                        ? colors.success.light
                        : colors.error.light
                    }
                    color={
                      routerData.internet.connected
                        ? colors.success.main
                        : colors.error.main
                    }
                    padding="6px 12px"
                    borderRadius={borders.radius.pill}
                  >
                    {routerData.internet.connected ? (
                      <FiWifi size={18} style={{ marginRight: 6 }} />
                    ) : (
                      <FiWifiOff size={18} style={{ marginRight: 6 }} />
                    )}
                    <Typography
                      variant="body2"
                      style={{ fontWeight: typography.fontWeights.medium }}
                    >
                      {routerData.internet.connected
                        ? "Conectado"
                        : "Desconectado"}
                    </Typography>
                  </Box>
                </Box>

                {/* Sección dividida en dos columnas: Módem e Internet */}
                <Grid container spacing={2}>
                  {/* Columna de Internet */}
                  <Grid item xs={12} sm={6}>
                    <Box
                      border={`1px solid ${colors.neutral.border}`}
                      borderRadius={borders.radius.sm}
                      padding={2}
                      height="100%"
                    >
                      <Typography
                        variant="subtitle2"
                        style={{
                          fontWeight: typography.fontWeights.semibold,
                          marginBottom: spacing.sm,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <FiGlobe size={18} style={{ marginRight: 8 }} />
                        Conexión a Internet
                      </Typography>

                      <Box marginBottom={1}>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "4px 0",
                          }}
                        >
                          <span>Estado:</span>
                          <span
                            style={{
                              color: routerData.internet.connected
                                ? colors.success.main
                                : colors.error.main,
                              fontWeight: typography.fontWeights.medium,
                            }}
                          >
                            {routerData.internet.status}
                          </span>
                        </Typography>

                        <Typography
                          variant="body2"
                          color="textSecondary"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "4px 0",
                          }}
                        >
                          <span>Interfaz:</span>
                          <span
                            style={{
                              fontWeight: typography.fontWeights.medium,
                            }}
                          >
                            {routerData.internet.interface}
                          </span>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>

                  {/* Columna del Módem */}
                  <Grid item xs={12} sm={6}>
                    <Box
                      border={`1px solid ${colors.neutral.border}`}
                      borderRadius={borders.radius.sm}
                      padding={2}
                      height="100%"
                    >
                      <Typography
                        variant="subtitle2"
                        style={{
                          fontWeight: typography.fontWeights.semibold,
                          marginBottom: spacing.sm,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <FiSmartphone size={18} style={{ marginRight: 8 }} />
                        Información del Módem
                      </Typography>

                      <Box>
                        {routerData.modem && (
                          <>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "4px 0",
                              }}
                            >
                              <span>Operador:</span>
                              <span
                                style={{
                                  fontWeight: typography.fontWeights.medium,
                                }}
                              >
                                {routerData.modem.operator !== "N/A"
                                  ? routerData.modem.operator
                                  : "No disponible"}
                              </span>
                            </Typography>

                            <Typography
                              variant="body2"
                              color="textSecondary"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "4px 0",
                              }}
                            >
                              <span>Red:</span>
                              <span
                                style={{
                                  fontWeight: typography.fontWeights.medium,
                                }}
                              >
                                {routerData.modem.networkType ||
                                  "No disponible"}
                              </span>
                            </Typography>

                            <Typography
                              variant="body2"
                              color="textSecondary"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "4px 0",
                              }}
                            >
                              <span>Señal:</span>
                              <span
                                style={{
                                  fontWeight: typography.fontWeights.medium,
                                }}
                              >
                                {routerData.modem.signal !== "N/A"
                                  ? routerData.modem.signal
                                  : "No disponible"}
                              </span>
                            </Typography>

                            <Typography
                              variant="body2"
                              color="textSecondary"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "4px 0",
                              }}
                            >
                              <span>IMEI:</span>
                              <span
                                style={{
                                  fontWeight: typography.fontWeights.medium,
                                  fontSize: "11px",
                                }}
                              >
                                {routerData.modem.imei || "No disponible"}
                              </span>
                            </Typography>
                          </>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>

                {/* Sección de dispositivos conectados */}
                {routerData.devices && routerData.devices.length > 0 && (
                  <div
                    style={{
                      marginTop: spacing.xl * 3,
                      paddingTop: spacing.xl,
                    }}
                  >
                    {/* Separador visual */}
                    <Box
                      sx={{
                        height: "1px",
                        background: `linear-gradient(to right, transparent, ${colors.neutral.border}, transparent)`,
                        marginBottom: spacing.xl,
                        width: "100%",
                      }}
                    />

                    <Typography
                      variant="subtitle2"
                      style={{
                        fontWeight: typography.fontWeights.bold,
                        marginBottom: spacing.md,
                        display: "flex",
                        alignItems: "center",
                        paddingTop: spacing.lg,
                        fontSize: typography.fontSize.base,
                        color: colors.primary.main,
                      }}
                    >
                      <FaServer
                        size={22}
                        style={{ marginRight: 10, color: colors.primary.main }}
                      />
                      Dispositivos conectados ({routerData.devices.length})
                    </Typography>

                    <Box
                      border={`1px solid ${colors.neutral.border}`}
                      borderRadius={borders.radius.sm}
                      marginBottom={1.5}
                      overflow="hidden"
                      boxShadow={`0 2px 4px rgba(0,0,0,0.05)`}
                    >
                      <table
                        style={{ width: "100%", borderCollapse: "collapse" }}
                      >
                        <thead
                          style={{
                            backgroundColor: colors.neutral.background,
                            borderBottom: `2px solid ${colors.primary.light}`,
                          }}
                        >
                          <tr>
                            <th
                              style={{
                                textAlign: "left",
                                padding: "14px 16px",
                                fontSize: typography.fontSize.sm,
                                fontWeight: typography.fontWeights.semibold,
                                color: colors.neutral.text,
                              }}
                            >
                              Nombre
                            </th>
                            <th
                              style={{
                                textAlign: "left",
                                padding: "14px 16px",
                                fontSize: typography.fontSize.sm,
                                fontWeight: typography.fontWeights.semibold,
                                color: colors.neutral.text,
                              }}
                            >
                              IP
                            </th>
                            <th
                              style={{
                                textAlign: "left",
                                padding: "14px 16px",
                                fontSize: typography.fontSize.sm,
                                fontWeight: typography.fontWeights.semibold,
                                color: colors.neutral.text,
                              }}
                            >
                              MAC
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {routerData.devices.map((device, index) => (
                            <tr
                              key={index}
                              style={{
                                borderTop: `1px solid ${colors.neutral.borderLight}`,
                                backgroundColor:
                                  index % 2 === 0
                                    ? "white"
                                    : colors.neutral.backgroundLight,
                                transition: "background-color 0.2s",
                                "&:hover": {
                                  backgroundColor: colors.primary.lighter,
                                },
                              }}
                            >
                              <td
                                style={{
                                  padding: "14px 16px",
                                  fontSize: typography.fontSize.sm,
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <FaEthernet
                                    size={16}
                                    style={{
                                      marginRight: 8,
                                      color:
                                        index === 0
                                          ? "#4287f5"
                                          : index === 1
                                          ? "#42b5f5"
                                          : "#428ef5",
                                      opacity: 0.9,
                                    }}
                                  />
                                  {device.name || "Desconocido"}
                                </div>
                              </td>
                              <td
                                style={{
                                  padding: "14px 16px",
                                  fontSize: typography.fontSize.sm,
                                  fontFamily: typography.fontFamily.mono,
                                }}
                              >
                                {device.ip || "N/A"}
                              </td>
                              <td
                                style={{
                                  padding: "14px 16px",
                                  fontSize: typography.fontSize.sm,
                                  fontFamily: typography.fontFamily.mono,
                                }}
                              >
                                {device.mac || "N/A"}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Box>
                  </div>
                )}
              </div>
            )}

            {/* Mostrar dispositivos del router si se llama handleGetRouterDevices */}
            {!routerData && routerDevices && routerDevices.length > 0 && (
              <div style={{ marginTop: spacing.md }}>
                <Typography
                  variant="subtitle1"
                  style={{
                    fontWeight: typography.fontWeights.semibold,
                    marginBottom: spacing.sm,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <FaServer size={18} style={{ marginRight: 8 }} />
                  Dispositivos conectados al Router ({routerDevices.length})
                </Typography>

                <div
                  style={{
                    maxHeight: "200px",
                    overflowY: "auto",
                    border: `1px solid ${colors.neutral.border}`,
                    borderRadius: borders.radius.sm,
                    padding: spacing.sm,
                  }}
                >
                  <Box
                    border={`1px solid ${colors.neutral.border}`}
                    borderRadius={borders.radius.sm}
                    marginBottom={1.5}
                    overflow="hidden"
                    boxShadow={`0 2px 4px rgba(0,0,0,0.05)`}
                  >
                    <table
                      style={{ width: "100%", borderCollapse: "collapse" }}
                    >
                      <thead
                        style={{
                          backgroundColor: colors.neutral.background,
                          borderBottom: `2px solid ${colors.primary.light}`,
                        }}
                      >
                        <tr>
                          <th
                            style={{
                              textAlign: "left",
                              padding: "14px 16px",
                              fontSize: typography.fontSize.sm,
                              fontWeight: typography.fontWeights.semibold,
                              color: colors.neutral.text,
                            }}
                          >
                            Nombre
                          </th>
                          <th
                            style={{
                              textAlign: "left",
                              padding: "14px 16px",
                              fontSize: typography.fontSize.sm,
                              fontWeight: typography.fontWeights.semibold,
                              color: colors.neutral.text,
                            }}
                          >
                            IP
                          </th>
                          <th
                            style={{
                              textAlign: "left",
                              padding: "14px 16px",
                              fontSize: typography.fontSize.sm,
                              fontWeight: typography.fontWeights.semibold,
                              color: colors.neutral.text,
                            }}
                          >
                            MAC
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {routerDevices.map((device, index) => (
                          <tr
                            key={index}
                            style={{
                              borderTop: `1px solid ${colors.neutral.borderLight}`,
                              backgroundColor:
                                index % 2 === 0
                                  ? "white"
                                  : colors.neutral.backgroundLight,
                              transition: "background-color 0.2s",
                              "&:hover": {
                                backgroundColor: colors.primary.lighter,
                              },
                            }}
                          >
                            <td
                              style={{
                                padding: "14px 16px",
                                fontSize: typography.fontSize.sm,
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <FaEthernet
                                  size={16}
                                  style={{
                                    marginRight: 8,
                                    color:
                                      index === 0
                                        ? "#4287f5"
                                        : index === 1
                                        ? "#42b5f5"
                                        : "#428ef5",
                                    opacity: 0.9,
                                  }}
                                />
                                {device.name || "Desconocido"}
                              </div>
                            </td>
                            <td
                              style={{
                                padding: "14px 16px",
                                fontSize: typography.fontSize.sm,
                                fontFamily: typography.fontFamily.mono,
                              }}
                            >
                              {device.ip || "N/A"}
                            </td>
                            <td
                              style={{
                                padding: "14px 16px",
                                fontSize: typography.fontSize.sm,
                                fontFamily: typography.fontFamily.mono,
                              }}
                            >
                              {device.mac || "N/A"}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Box>
                </div>
              </div>
            )}
          </Paper>
        </div>
      </DialogContent>

      {/* Footer con botones de acción */}
      <DialogActions
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: `${spacing.sm} ${spacing.lg} ${spacing.lg} ${spacing.lg}`,
          backgroundColor: colors.neutral.white,
          borderTop: `1px solid ${colors.neutral.border}`,
        }}
      >
        <Button
          onClick={onClose}
          color="inherit"
          style={{
            textTransform: "none",
            color: colors.neutral.main,
          }}
        >
          Cerrar
        </Button>

        <Button
          variant="contained"
          onClick={onRefresh}
          startIcon={<BiRefresh />}
          style={{
            textTransform: "none",
            backgroundColor: colors.primary.main,
            color: "white",
          }}
        >
          Actualizar datos
        </Button>
      </DialogActions>

      {/* Diálogo de bienvenida */}
      <Dialog
        open={welcomeDialogOpen}
        onClose={handleCloseWelcomeDialog}
        maxWidth="md"
        fullWidth
        aria-labelledby="welcome-dialog-title"
        PaperProps={{
          style: {
            borderRadius: borders.radius.lg,
            overflow: "hidden",
          },
        }}
      >
        <DialogTitle
          id="welcome-dialog-title"
          style={{
            backgroundColor: colors.neutral.light,
            color: colors.neutral.dark,
            padding: spacing.lg,
            borderBottom: `1px solid ${colors.neutral.border}`,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{ display: "flex", alignItems: "center", gap: spacing.md }}
            >
              <FaInfoCircle size={24} style={{ color: colors.primary.main }} />
              <Typography
                variant="h5"
                style={{ fontWeight: typography.fontWeights.bold }}
              >
                Sistema de JABABOX
              </Typography>
            </div>
            <IconButton
              aria-label="close"
              onClick={handleCloseWelcomeDialog}
              style={{ color: colors.neutral.dark }}
            >
              <FiX />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent
          style={{ padding: 0, backgroundColor: colors.neutral.background }}
        >
          {/* Banner superior con imagen */}
          <div
            style={{
              background: `linear-gradient(to right, ${colors.neutral.white}, ${colors.primary.light})`,
              padding: `${spacing.xl} ${spacing.xl}`,
              color: colors.neutral.dark,
              borderBottom: `1px solid ${colors.neutral.border}`,
            }}
          >
            <Typography
              variant="h4"
              style={{
                fontWeight: typography.fontWeights.bold,
                marginBottom: spacing.md,
                color: colors.primary.dark,
              }}
            >
              Bienvenido al Panel de Control
            </Typography>
            <Typography variant="body1" style={{ color: colors.neutral.dark }}>
              Esta guía te ayudará a entender cómo utilizar y aprovechar al
              máximo las funcionalidades del sistema.
            </Typography>
          </div>

          {/* Contenido principal */}
          <div style={{ padding: `${spacing.lg} ${spacing.xl}` }}>
            <Typography
              variant="h6"
              style={{
                marginBottom: spacing.lg,
                color: colors.primary.main,
                display: "flex",
                alignItems: "center",
                gap: spacing.sm,
                paddingBottom: spacing.sm,
                borderBottom: `1px solid ${colors.neutral.border}`,
              }}
            >
              <FiSettings size={18} />
              Guía de uso del sistema
            </Typography>

            <Grid container spacing={3}>
              {/* Sección de Información General */}
              <Grid item xs={12} md={6}>
                <Paper
                  elevation={0}
                  style={{
                    padding: spacing.lg,
                    height: "100%",
                    backgroundColor: colors.neutral.white,
                    borderRadius: borders.radius.md,
                    border: `1px solid ${colors.neutral.border}`,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: spacing.sm,
                      marginBottom: spacing.md,
                      color: colors.primary.main,
                    }}
                  >
                    <FaInfoCircle size={20} />
                    <Typography
                      variant="subtitle1"
                      style={{ fontWeight: typography.fontWeights.semibold }}
                    >
                      Información general
                    </Typography>
                  </div>
                  <Typography
                    variant="body2"
                    style={{ color: colors.neutral.main }}
                  >
                    Esta interfaz te permite gestionar y monitorizar los
                    dispositivos de JABABOX. Podrás ver información de estado,
                    ubicación, control de energía y mucho más.
                  </Typography>
                  <div
                    style={{
                      marginTop: spacing.md,
                      padding: spacing.sm,
                      backgroundColor: colors.neutral.light,
                      borderRadius: borders.radius.sm,
                      border: `1px solid ${colors.neutral.border}`,
                    }}
                  >
                    <Typography
                      variant="body2"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: spacing.xs,
                        color: colors.neutral.dark,
                      }}
                    >
                      <FiInfo size={14} />
                      En la parte superior del panel podrás ver el estado en
                      tiempo real del dispositivo.
                    </Typography>
                  </div>
                </Paper>
              </Grid>

              {/* Sección de Controles del Sistema */}
              <Grid item xs={12} md={6}>
                <Paper
                  elevation={0}
                  style={{
                    padding: spacing.lg,
                    height: "100%",
                    backgroundColor: colors.neutral.white,
                    borderRadius: borders.radius.md,
                    border: `1px solid ${colors.neutral.border}`,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: spacing.sm,
                      marginBottom: spacing.md,
                      color: colors.primary.main,
                    }}
                  >
                    <FiSettings size={20} />
                    <Typography
                      variant="subtitle1"
                      style={{ fontWeight: typography.fontWeights.semibold }}
                    >
                      Controles del sistema
                    </Typography>
                  </div>
                  <Typography
                    variant="body2"
                    style={{ color: colors.neutral.main }}
                  >
                    La sección de controles te permite gestionar la alimentación
                    de los dispositivos. Podrás activar o desactivar componentes
                    específicos como auxiliares y cargas principales.
                  </Typography>
                  <div
                    style={{
                      marginTop: spacing.md,
                      padding: spacing.sm,
                      backgroundColor: colors.neutral.light,
                      borderRadius: borders.radius.sm,
                      border: `1px solid ${colors.neutral.border}`,
                    }}
                  >
                    <Typography
                      variant="body2"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: spacing.xs,
                        color: colors.neutral.dark,
                      }}
                    >
                      <IoIosFlash
                        size={14}
                        style={{ color: colors.warning.main }}
                      />
                      Recuerda activar primero los auxiliares antes de encender
                      la carga principal.
                    </Typography>
                  </div>
                </Paper>
              </Grid>

              {/* Sección de Gráficos Históricos */}
              <Grid item xs={12} md={6}>
                <Paper
                  elevation={0}
                  style={{
                    padding: spacing.lg,
                    height: "100%",
                    backgroundColor: colors.neutral.white,
                    borderRadius: borders.radius.md,
                    border: `1px solid ${colors.neutral.border}`,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: spacing.sm,
                      marginBottom: spacing.md,
                      color: colors.primary.main,
                    }}
                  >
                    <FaChartLine size={20} />
                    <Typography
                      variant="subtitle1"
                      style={{ fontWeight: typography.fontWeights.semibold }}
                    >
                      Gráficos históricos
                    </Typography>
                  </div>
                  <Typography
                    variant="body2"
                    style={{ color: colors.neutral.main }}
                  >
                    Los gráficos te muestran el histórico de datos de batería,
                    suministro de energía y solar del sistema, permitiéndote
                    analizar tendencias y comportamientos a lo largo del tiempo.
                  </Typography>
                  <div
                    style={{
                      marginTop: spacing.md,
                      padding: spacing.sm,
                      backgroundColor: colors.neutral.light,
                      borderRadius: borders.radius.sm,
                      border: `1px solid ${colors.neutral.border}`,
                    }}
                  >
                    <Typography
                      variant="body2"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: spacing.xs,
                        color: colors.neutral.dark,
                      }}
                    >
                      <FiTrendingUp
                        size={14}
                        style={{ color: colors.success.main }}
                      />
                      Puedes ver más detalles haciendo clic en "Ver gráficos" en
                      la barra superior.
                    </Typography>
                  </div>
                </Paper>
              </Grid>

              {/* Sección de Gestión de Router */}
              <Grid item xs={12} md={6}>
                <Paper
                  elevation={0}
                  style={{
                    padding: spacing.lg,
                    height: "100%",
                    backgroundColor: colors.neutral.white,
                    borderRadius: borders.radius.md,
                    border: `1px solid ${colors.neutral.border}`,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: spacing.sm,
                      marginBottom: spacing.md,
                      color: colors.primary.main,
                    }}
                  >
                    <FaWifi size={20} />
                    <Typography
                      variant="subtitle1"
                      style={{ fontWeight: typography.fontWeights.semibold }}
                    >
                      Gestión de Router
                    </Typography>
                  </div>
                  <Typography
                    variant="body2"
                    style={{ color: colors.neutral.main }}
                  >
                    Esta sección te permite escanear direcciones IP y ver los
                    dispositivos conectados al router del sistema. Monitoriza y
                    gestiona la red para mantener una conexión estable.
                  </Typography>
                  <div
                    style={{
                      marginTop: spacing.md,
                      padding: spacing.sm,
                      backgroundColor: colors.neutral.light,
                      borderRadius: borders.radius.sm,
                      border: `1px solid ${colors.neutral.border}`,
                    }}
                  >
                    <Typography
                      variant="body2"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: spacing.xs,
                        color: colors.neutral.dark,
                      }}
                    >
                      <FaSearch
                        size={14}
                        style={{ color: colors.primary.main }}
                      />
                      Utiliza "Escanear direcciones IP" para detectar
                      automáticamente dispositivos en la red.
                    </Typography>
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions
          style={{
            padding: `${spacing.md} ${spacing.xl} ${spacing.xl} ${spacing.xl}`,
            borderTop: `1px solid ${colors.neutral.border}`,
            backgroundColor: colors.neutral.white,
          }}
        >
          <Button
            onClick={handleCloseWelcomeDialog}
            color="primary"
            variant="outlined"
            style={{
              textTransform: "none",
              borderColor: colors.primary.main,
              color: colors.primary.main,
              marginRight: spacing.md,
            }}
          >
            Cerrar
          </Button>
          <Button
            onClick={handleCloseWelcomeDialog}
            color="primary"
            variant="contained"
            startIcon={<FiSettings />}
            style={{
              textTransform: "none",
              backgroundColor: colors.primary.main,
              color: "white",
            }}
          >
            Comenzar a usar el sistema
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

ProductDetailsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  product: PropTypes.object,
  isLive: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onOpenGraph: PropTypes.func.isRequired,
  onPowerAuxr1: PropTypes.func.isRequired,
  onPowerAuxr2: PropTypes.func.isRequired,
  onPowerMainload: PropTypes.func.isRequired,
  onScanNetwork: PropTypes.func,
  onGetConnectedDevices: PropTypes.func,
};

export default ProductDetailsModal;
