import React, { Component } from "react";

class StatusIndicatorFalse extends Component {
  componentDidMount() {
    // Verificar si el script ya está cargado
    if (!document.querySelector('script[src*="lottie-player"]')) {
      // Cargar el script dinámicamente cuando el componente se monta
      const script = document.createElement("script");
      script.src =
        "https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js";
      script.async = true;
      document.head.appendChild(script);
    }
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "20px",
            height: "20px",
            borderRadius: "50%",
            backgroundColor: "#F44336", // rojo
            boxShadow: "0 0 10px #F44336",
            animation: "pulse 1.5s infinite",
          }}
        />
        <style>
          {`
            @keyframes pulse {
              0% { opacity: 0.7; transform: scale(0.8); }
              50% { opacity: 1; transform: scale(1.1); }
              100% { opacity: 0.7; transform: scale(0.8); }
            }
          `}
        </style>
      </div>
    );
  }
}

export default StatusIndicatorFalse;
