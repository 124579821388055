import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Tooltip,
  makeStyles,
  Box,
  Divider,
  Badge,
} from "@material-ui/core";
import {
  Add as AddIcon,
  Refresh as RefreshIcon,
  Settings as SettingsIcon,
  Help as HelpIcon,
  ExitToApp as LogoutIcon,
  Notifications as NotificationIcon,
  Apps as AppsIcon,
  Star as StarIcon,
} from "@material-ui/icons";
import ServicesDialog from "../Modals/ServicesDialog";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "#f8f9fa",
    color: "#4a4a4a",
    boxShadow: "0 2px 10px rgba(0,0,0,0.05)",
    borderBottom: "1px solid #e9ecef",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    minHeight: "60px",
    padding: "0 16px",
  },
  title: {
    fontWeight: 700,
    fontSize: "1.25rem",
    color: "#6a67ce",
    letterSpacing: "0.5px",
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
  },
  liveIndicator: {
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(2),
    backgroundColor: "#e6f7ee",
    border: "1px solid #d1f2e1",
    borderRadius: "4px",
    padding: "4px 8px",
    fontSize: "0.75rem",
    color: "#28a745",
    "& span": {
      marginLeft: "4px",
      fontWeight: 500,
    },
  },
  liveIcon: {
    width: "8px",
    height: "8px",
    borderRadius: "50%",
    backgroundColor: "#28a745",
    marginRight: "6px",
    animation: "$pulse 1.5s infinite ease-in-out",
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
  },
  button: {
    textTransform: "none",
    borderRadius: "6px",
    padding: "6px 12px",
    margin: "0 3px",
    fontSize: "0.8125rem",
    fontWeight: 500,
    whiteSpace: "nowrap",
    boxShadow: "0 2px 4px rgba(0,0,0,0.06)",
    transition: "all 0.2s ease",
    "&:hover": {
      transform: "translateY(-1px)",
      boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
    },
  },
  addButton: {
    backgroundColor: "#a893ff",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#8a7aff",
    },
  },
  configButton: {
    backgroundColor: "#ffbe76",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#f6a94f",
    },
  },
  refreshButton: {
    backgroundColor: "#74c0fc",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#4da5f5",
    },
  },
  helpButton: {
    backgroundColor: "#69db7c",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#4bc46d",
    },
  },
  servicesButton: {
    backgroundColor: "#9775fa",
    color: "#fff",
    position: "relative",
    "&:hover": {
      backgroundColor: "#845ef7",
    },
  },
  servicesButtonText: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
  },
  starDecoration: {
    position: "absolute",
    color: "#FFD700",
  },
  starLeft: {
    left: -8,
    top: -8,
    animation: "$twinkle 1.5s infinite ease-in-out 0.2s",
  },
  starRight: {
    right: -8,
    top: -8,
    animation: "$twinkle 1.5s infinite ease-in-out 0.5s",
  },
  starBottom: {
    bottom: -8,
    left: "calc(50% - 8px)",
    animation: "$twinkle 1.5s infinite ease-in-out",
  },
  logoutButton: {
    backgroundColor: "#ff8787",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#fa6868",
    },
  },
  buttonIcon: {
    marginRight: "4px",
    fontSize: "1rem",
  },
  divider: {
    margin: "0 10px",
    height: "24px",
    backgroundColor: "#e9ecef",
  },
  mobileButtonContainer: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      gap: "8px",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  desktopButtonContainer: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
      alignItems: "center",
    },
  },
  mobileIcon: {
    color: "#6a67ce",
  },
  "@keyframes pulse": {
    "0%": {
      opacity: 0.6,
      transform: "scale(0.9)",
    },
    "50%": {
      opacity: 1,
      transform: "scale(1.1)",
    },
    "100%": {
      opacity: 0.6,
      transform: "scale(0.9)",
    },
  },
  "@keyframes twinkle": {
    "0%": {
      opacity: 0.5,
      transform: "scale(0.8) rotate(0deg)",
    },
    "50%": {
      opacity: 1,
      transform: "scale(1.2) rotate(10deg)",
    },
    "100%": {
      opacity: 0.5,
      transform: "scale(0.8) rotate(0deg)",
    },
  },
}));

const DashboardHeader = ({
  isLive,
  onAddProduct,
  onSettings,
  onRefresh,
  onLogout,
  onHelpToggle,
}) => {
  const classes = useStyles();
  const [servicesDialogOpen, setServicesDialogOpen] = useState(false);

  const handleOpenServicesDialog = () => {
    setServicesDialogOpen(true);
  };

  const handleCloseServicesDialog = () => {
    setServicesDialogOpen(false);
  };

  return (
    <AppBar position="static" className={classes.appBar} elevation={0}>
      <Toolbar className={classes.toolbar}>
        <Box className={classes.logoContainer}>
          <Typography variant="h6" className={classes.title}>
            JABABOX PLATFORM
          </Typography>
          {isLive && (
            <Box className={classes.liveIndicator}>
              <div className={classes.liveIcon} />
              <span>EN VIVO</span>
            </Box>
          )}
        </Box>

        {/* Desktop View */}
        <Box className={classes.desktopButtonContainer}>
          <Button
            variant="contained"
            size="small"
            className={`${classes.button} ${classes.addButton}`}
            startIcon={<AddIcon className={classes.buttonIcon} />}
            onClick={onAddProduct}
          >
            Añadir Sistema
          </Button>

          <Button
            variant="contained"
            size="small"
            className={`${classes.button} ${classes.configButton}`}
            startIcon={<SettingsIcon className={classes.buttonIcon} />}
            onClick={onSettings}
          >
            Configuración
          </Button>

          <Button
            variant="contained"
            size="small"
            className={`${classes.button} ${classes.servicesButton}`}
            onClick={handleOpenServicesDialog}
          >
            <StarIcon
              className={`${classes.starDecoration} ${classes.starLeft}`}
              fontSize="small"
            />
            <StarIcon
              className={`${classes.starDecoration} ${classes.starRight}`}
              fontSize="small"
            />
            <StarIcon
              className={`${classes.starDecoration} ${classes.starBottom}`}
              fontSize="small"
            />
            <span className={classes.servicesButtonText}>
              <AppsIcon className={classes.buttonIcon} />
              Servicios
            </span>
          </Button>

          <Button
            variant="contained"
            size="small"
            className={`${classes.button} ${classes.refreshButton}`}
            startIcon={<RefreshIcon className={classes.buttonIcon} />}
            onClick={onRefresh}
          >
            Actualizar
          </Button>

          <Button
            variant="contained"
            size="small"
            className={`${classes.button} ${classes.helpButton}`}
            startIcon={<HelpIcon className={classes.buttonIcon} />}
            onClick={onHelpToggle}
          >
            Ayuda
          </Button>

          <Divider
            orientation="vertical"
            flexItem
            className={classes.divider}
          />

          <Button
            variant="contained"
            size="small"
            className={`${classes.button} ${classes.logoutButton}`}
            startIcon={<LogoutIcon className={classes.buttonIcon} />}
            onClick={onLogout}
          >
            Cerrar Sesión
          </Button>
        </Box>

        {/* Mobile View */}
        <Box className={classes.mobileButtonContainer}>
          <IconButton size="small" onClick={onAddProduct}>
            <AddIcon className={classes.mobileIcon} />
          </IconButton>

          <IconButton size="small" onClick={onSettings}>
            <SettingsIcon className={classes.mobileIcon} />
          </IconButton>

          <IconButton size="small" onClick={handleOpenServicesDialog}>
            <AppsIcon className={classes.mobileIcon} />
          </IconButton>

          <IconButton size="small" onClick={onRefresh}>
            <RefreshIcon className={classes.mobileIcon} />
          </IconButton>

          <IconButton size="small" onClick={onHelpToggle}>
            <HelpIcon className={classes.mobileIcon} />
          </IconButton>

          <IconButton size="small" onClick={onLogout}>
            <LogoutIcon className={classes.mobileIcon} />
          </IconButton>
        </Box>
      </Toolbar>

      {/* Services Dialog */}
      <ServicesDialog
        open={servicesDialogOpen}
        onClose={handleCloseServicesDialog}
      />
    </AppBar>
  );
};

export default DashboardHeader;
