import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  IconButton,
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import QRScannerComponent from "../QRScanner/QRScannerComponent";

const AddProductModal = ({
  open,
  name,
  sn,
  verification_code,
  qrScannerOpen,
  onChange,
  onClose,
  onAdd,
  onToggleQRScanner,
  onQRScan,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Añadir Sistema JABABOX</DialogTitle>
      <div className="logo-container">
        <img
          src="https://cloud.jababox.com/images/jababox.png"
          alt="jababox add"
          className="logo"
        />
      </div>
      <DialogContent>
        <TextField
          id="standard-basic"
          type="text"
          autoComplete="off"
          name="name"
          value={name}
          onChange={onChange}
          placeholder="Nombre sistema"
          required
          InputProps={{
            endAdornment: name && (
              <CheckCircleIcon style={{ color: "green" }} />
            ),
          }}
        />
        <br />
        <TextField
          id="standard-basic"
          type="text"
          autoComplete="off"
          name="sn"
          value={sn}
          onChange={onChange}
          placeholder="Número de serie"
          required
          style={{ width: "60%" }}
          InputProps={{
            endAdornment: sn && <CheckCircleIcon style={{ color: "green" }} />,
          }}
        />

        <QRScannerComponent
          isOpen={qrScannerOpen}
          onScan={onQRScan}
          onToggle={onToggleQRScanner}
        />

        <br />
        <TextField
          id="standard-basic"
          type="text"
          autoComplete="off"
          name="verification_code"
          value={verification_code}
          onChange={onChange}
          placeholder="Código de verificación"
          required
          InputProps={{
            endAdornment: verification_code ? (
              <CheckCircleIcon style={{ color: "green" }} />
            ) : (
              <ErrorOutlineIcon style={{ color: "red" }} />
            ),
          }}
        />
        <br />
        <br />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          disabled={name === "" || sn === "" || verification_code === ""}
          onClick={onAdd}
          color="primary"
          autoFocus
        >
          Añadir sistema
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddProductModal.propTypes = {
  open: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  sn: PropTypes.string.isRequired,
  verification_code: PropTypes.string.isRequired,
  qrScannerOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onToggleQRScanner: PropTypes.func.isRequired,
  onQRScan: PropTypes.func.isRequired,
};

export default AddProductModal;
